.callout-box{
    display:block;
    background:rgba(255,255,255,.90);
    padding:em(30,$fs);
    transition:all $ms ease-in-out;
    -webkit-transform-style: preserve-3d;
    position: relative;
    z-index:10;

    *{
        color:$black;
    }

    h2{
        font-family:$font_body;
        font-size:em(24,$fs);
        text-transform:uppercase;
        padding-bottom:em(15,24);
        margin:0 0 em(10,24);
        letter-spacing:em(.75,24);
        line-height:1;
        border-bottom:1px $greydark solid;
    }

    h3{
        font-family:$font_body;
        text-transform:none;
        font-size:em(16,$fs);
        letter-spacing:em(.75,16);
        font-weight:600;
        margin:0;

        + p{
            margin-top:em(15,$fs);
        }
    }

    p{
        font-weight:normal;
        font-size:em(18,$fs);
    }

    span{
        display:block;
        font-size:em(24,$fs);
        margin:em(50,24) 0 0;
        font-family:$font_script;
        line-height:1;
        font-style:italic;
        font-weight:normal;
    }

    &[href]:hover{
        transform:translateY(-10px);
        background:rgba(255,255,255,1);
        box-shadow: 0 0 50px 0 rgba(255,255,255,.35);
    }
}

.callout-image{
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    right:0;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
}

.callout-inside{
    width:100%;
    max-width:$inside_width;
    height:100%;
    margin:0 auto;
}

.callout-row{
    @include afterclear;

    &.break-out{
        position: relative;
        left:50%;
        transform:translateX(-50%);
        width:$hwidth;
        max-width:calc(100vw - .9375em - .9375em);

        .callout{
            overflow:visible;
            padding:em(30,$fs);

            .callout-box{
                max-width:550px;
                position: relative;
                top:auto;
                left:auto;
                right:auto;
                bottom:auto;

                p{
                    font-size:em(14,$fs);
                    letter-spacing:.75px;
                }
            }
        }
    }

    .callout{
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center center;
        position: relative;
        overflow:hidden;
        padding:em(200,$fs) em(30,$fs) em(30,$fs);

        .mobile-background{
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-size:cover;
            background-repeat:no-repeat;
            background-position:center center;
        }
    }

    &.cols-1{
        .callout{
            padding:em(300,$fs) em(30,$fs) em(30,$fs);

            h2{
                width:375px;
                max-width:100%;
                font-family:$font_heading;
                font-size:em(48,$fs);
                padding-bottom:em(15,60);
                margin:0 0 em(13,60);
            }

            h3{
                font-family:$font_body;
                font-size:em(18,$fs);
                text-transform:uppercase;
            }
        }
    }
    &.cols-2{
        .callout{
            &:nth-child(even){
                margin:percentage(20 / $hwidth_num) 0 0 0;
            }
        }
    }
    &.cols-3{
        .callout{
            + .callout{
                margin:percentage(20 / $hwidth_num) 0 0 0;
            }
        }
    }
    &.category-callout{
        margin-top:percentage(80/$width_num);
    }
}

.home-feature-block{
    h2{
        width:375px;
        max-width:100%;
        font-family:$font_heading;
        font-size:em(48,$fs);
        padding-bottom:em(15,60);
        margin:0 0 em(13,60);
    }
}