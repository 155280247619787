.page-layout-home{
    .main-wrap{
        > *{
            max-width:$hwidth;
        }
    }
}

.statement{
    position: relative;
    text-align:center;

    &:before{
        content:"";
        width:100%;
        height:1px;
        background:$greydark;
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        z-index:1;
    }

    h2{
        font-family:$font_body;
        padding:em(35,$fs) em(20,$fs);
        font-size:em(24,$fs);
        text-align:center;
        display:inline-block;
        margin:0 auto;
        background:$white;
        position: relative;
        z-index:1;
    }
}

.page-layout-home{
    .block-products-list{
        max-width:$width;
        margin:em(70,$fs) auto 0 !important;

        .block-title{
            font-family:$font_body;
            text-align:center;
            text-transform:uppercase;
            font-size:em(24,$fs);
            letter-spacing:em(.80,24);
            margin:0 0 em(19,24);

            span{
                font-family:$icon;
                font-size:em(20,24);
                position: relative;
                top:em(-2,20);
                display:inline-block;
                margin:0 em(2,20);
            }

            *{
                font-weight:normal;
            }
        }
    }
}

.home-feature-block{
    @include afterclear;
    max-width:$width;
    margin:em(80,$fs) auto 0;

    > div{
        position: relative;
    }

    .left,
    .faded-bg{
        background-size:cover;
        background-position:center center;
        background-repeat:no-repeat;
    }

    .left{
        height:150px;
    }

    .right{
        padding:em(30,$fs);
        background:$black;
    }

    .callout-box{
        background:rgba($white,.10);

        h2{
            border-bottom-color:rgba($white,.25);
        }

        *{
            color:$white;
        }

        &:hover{
            background:rgba($white,.15);
            box-shadow:none;
        }
    }

    .faded-bg{
        opacity:.10;
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:1;
    }
}

.home-triple-box-block{
    @include afterclear;
    margin:em(80,$fs) auto 0;
    padding:em(30,$fs) 0 0;
    border-top:1px $greydark solid;
    position: relative;

    .triple-box{
        position: relative;
        list-style:none;
        margin:0;
        padding:0;
        padding-bottom:percentage(1100 / $hwidth_num);

        li{
            position: absolute;
            width:percentage(423 / 1069);
            will-change:transform;
            transition:transform $ms ease-in-out;

            &:nth-child(1){
                top:0;
                left:0;
                z-index:1;
            }

            &:nth-child(2){
                top:percentage(147 / 578);
                left:percentage(287 / 1069);
                z-index:2;
            }

            &:nth-child(3){
                top:percentage(81 / 578);
                right:0;
                z-index:1;
            }
        }

        img{
            border:5px $white solid;
            display:block;
            width:100%;
            height:auto;
        }
    }

    .triple-box-content{
        h2{
            font-family:$font_heading;
            text-transform:uppercase;
            font-size:em(48,$fs);
            line-height:1;
            margin:0 0 em(4,48);
        }

        h3{
            font-family:$font_body;
            font-size:em(18,$fs);
            text-transform:uppercase;
            font-weight:600;
        }

        a{
            display:block;
            font-size:em(24,$fs);
            margin:em(60,24) 0 0;
            font-family:$font_script;
            line-height:1;
            font-style:italic;
            color:$black;
            padding-bottom:em(15,24);
            transition:all $ms ease-in-out;
            font-weight:normal;

            &:before,
            &:after{
                content:"";
                width:100%;
                height:1px;
                display:block;
                background:$greydark;
                position: absolute;
                bottom:0;
                left:0;
                z-index:1;
            }

            &:after{
                background:$black;
                z-index:2;
                width:0;
                transition:all $ms ease-in-out;
            }

            &:hover{
                color:$black;
                text-indent:10px;

                &:after{
                    width:100%;
                }
            }
        }
    }
}

.home-instagram-block{
    $ig_em: em(30,$fs);
    margin:em(83,$fs) 0 0;
    width:$width;
    max-width:calc(100vw - #{$ig_em});
    background:$grey;
    border-top:1px $greydark solid;
    padding:em(30,$fs);
    text-align:center;
    position: relative;
    left:50%;
    transform:translateX(-50%);

    h5{
        font-family:$font_body;
        font-size:em(24,$fs);
        text-transform:uppercase;
        margin:0 0 em(9,24);
    }

    h6{
        font-family:$font_body;
        font-size:em(14,$fs);
        text-transform:uppercase;
        font-weight:bold;
        margin:0 0 em(22,14);
        letter-spacing:em(.75,14);
        position: relative;
        padding-left:em(30,14);
        display:inline-block;

        &:before{
            content:"\f16d";
            font-family:$icon;
            position: absolute;
            top:50%;
            left:0;
            transform:translateY(-50%);
            font-size:1.5em;
            line-height:1;
            font-weight:normal;
        }
    }

    ul{
        @include afterclear;
        list-style:none;
        margin:0;
        padding:0;

        li{
            float:left;
            width:percentage(420.16/1296);

            &:nth-child(3n+1){
                clear:both;
            }

            &:not(:nth-child(3n+1)){
                margin-left:percentage(17.75/1296);
            }

            &:nth-child(n+4){
                margin-top:percentage(17.75/1296);
            }

            &:nth-child(n+7){
                display:none;
            }
        }

        a{
            display:block;
            position: relative;
            width:100%;
            height:100%;
            padding-bottom:100%;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;

            &:before{
                content:"";
                width:102%;
                height:102%;
                display:block;
                background:url(../images/instagram-mask.svg) no-repeat center center;
                position: absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
                opacity:0;
                transition:all $ms ease-in-out;
            }

            &:hover{
                &:before{
                    opacity:.50;
                }
            }
        }

        img{
            display:block;
            width:100%;
            height:auto;
        }
    }
}