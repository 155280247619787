body:not(.page-layout-home){
    .main-wrap{
        .login-container{
            @include afterclear;

            .block{
                float:left;
                width:47%;

                &:last-child{
                    margin:0 0 0 6%;
                }
            }
        }
        .products-grid{
            &.wishlist{
                ol{
                    li{
                        &:before{
                            top:0;
                        }
                    }
                }
            }
        }
    }
}

.customer-account-index, .customer-account-edit {
    .block-dashboard-info {
        .block-title {
            margin-bottom: 10px !important;
            padding-bottom: 0px !important;

            strong {
                font-size: 0.8em !important;
            }
        }

        .box-title {
            font-size: 1.5em;
        }
    }

    .block-dashboard-addresses {
        margin-top: 30px;

        .block-title {
            margin-bottom: 10px !important;
            padding-bottom: 0px !important;

            strong {
                font-size: 0.8em !important;
            }
        }

        a.action {
            span {
                font-size: 0.5em !important;
            }
        }

        .box-billing-address, .box-shipping-address {
            a.action {
                span {
                    font-size: 1em !important;
                }
            }
        }

        .box-title {
            font-size: 1.5em;
        }
    }
}