.no-display{
    display:none;
}

.main-wrap{
    padding-bottom:em(33,$fs);
}

.page-main{
    @include afterclear;
    position: relative;
}

.breadcrumbs{
    position: relative;
    z-index:10;

    ul{
        @include afterclear;
        list-style:none;
        margin:0;
        padding:0;

        li{
            font-size:em(14,$fs);
            float:left;
            margin:0;
            padding:0;

            &:before{
                display:none;
            }

            &:not(:last-child){
                *{
                    color:$greydark;
                }
            }

            + li{
                margin-left:em(15,$fs);
                padding-left:em(15,$fs);
                position: relative;

                &:before{
                    content:"";
                    width:2px;
                    height:2px;
                    display:block;
                    background:$greydark;
                    position: absolute;
                    left:0;
                    top:50%;
                    transform:translateY(-50%);
                }
            }
        }
    }
    a,
    strong{
        font-weight:600;
    }
}

body:not(.page-layout-home) .main-wrap{
    padding-top:em(15,$fs);

    .columns{
        margin:em(15,$fs) 0 0;
    }
}

.column{
    @include afterclear;
}

h1,h2,h3,h4,h5,h6,
.block-title,
.box-title {
    font-family:$font_heading;
    color:$primary;
    margin-bottom:.5em;
}

h1 {
    font-size:em(42,$fs);
}

h2,
.block-title {
    font-size:em(36,$fs);
}

h3,
.box-title {
    font-size:em(30,$fs);
}

h4,
.sidebar .block-title,
legend {
    font-size:em(26,$fs);
}

h5 {
    font-size:em(22,$fs);
}

h6 {
    font-size:em(18,$fs);
}

.page-title-wrapper{
    margin:em(25,$fs) 0 0;
}

#page-title-heading,
.page-title,
.upsell .block-title {
    font-size:em(24,$fs);
    line-height:1em;
    font-family:$font_heading;
    text-transform:uppercase;
    letter-spacing:1px;
    margin:0;

    .counts{
        font-size:em(14,24);
    }
}

blockquote {
    margin:1em 0;
    padding:15px;
    background:$gray;
    border-left:5px solid $secondary;
    font-size:1.5em;

    p {
        &:last-child {
            margin-bottom:0;
        }
    }
}

hr {
    margin:em(30,$fs) 0;
    border:none;
    outline:none;
    width:100%;
    height:1px;
    background:$greydark;
}

.clear{
    clear:both;
}

.wp-caption,
img {
    max-width:100%;
    height:auto;
}

.section + .section {
    border-top:1px solid $gray;
    padding-top:1.5em;
    margin-top:1.5em;
}

p {
    &:empty {
        //display:none;
    }

    + p {
        margin-top:1em;
    }

    &.wp-caption-text {
        margin:0;
        padding-top:5px;
        font-size:em(14,$fs);
        font-style:italic;
        color:darken($gray,30);
    }
}

.main-wrap{
    form{
        button{
            background:$black;
            color:$white;
            border:2px $black solid;
            outline:none;
            transition:all $ms ease-in-out;
            font-family:$font_body;
            font-size:em(16,$fs);
            text-transform:uppercase;
            font-weight:600;
            padding:em(12,16) em(15,16);

            &:hover{
                background:transparent;
                color:$black;
            }
        }
    }
}

a {
    color:$primary;
    font-weight:600;
    text-decoration:none;
    transition:color $ms ease-in-out;

    &:hover{
        color:$secondary;
    }
}

body:not(.page-layout-home) .main-wrap.cancelthis{
    ul {
        padding-left:10px;
        margin-top:0.5em;

        // ul {
        //     margin-bottom:0;
        // }

        li {
            list-style: none;
            padding-left:15px;
            position:relative;
            margin-bottom:8px;
            font-size:em(16,$fs);
            color:#222;
            font-weight:400;

            &:before {
                position:absolute;
                left:0;
                top:9px;
                line-height: 1;
                background:$primary;
                font-weight:100;
                content: '';
                width:6px;
                height:6px;
                border-radius:50%;
            }
        }
    }

    ol {
        margin:1em 0;
        padding-left: 10px;
        list-style-type: none;
        counter-reset: step-counter;

        li {
            counter-increment: step-counter;
            position:relative;
            padding-left:30px;
            font-weight:400;

            + li {
                margin-top:0.5em;
            }

            &:before {
                content:counter(step-counter);
                position:absolute;
                left:-2px;
                top:3px;
                font-size: 80%;
                background-color:$secondary;
                color:white;
                font-weight: bold;
                height:20px;
                width:20px;
                line-height:20px;
                text-align: center;
            }

            ol,ul {
                > li {
                    font-size:em(16,$fs);
                }
            }

            ol {
                counter-reset: step-counter;

                ol {
                    counter-reset: step-counter;
                }
            }

            ul {
                counter-reset: step-counter;

                > li {
                    list-style: none;
                    padding-left:15px;
                    position:relative;
                    margin-bottom:8px;
                    font-weight:400;

                    &:before {
                        position:absolute;
                        left:0;
                        top:10px;
                        line-height: 1;
                        background:$secondary;
                        font-weight:100;
                        content: '';
                        width:6px;
                        height:6px;
                        border-radius:50%;
                    }
                }
            }
        }
    }
}

.video-wrap {
    clear:both;
    width:auto;
    width:100%;
    display:block;
    max-width:850px;

    .video {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
    }

    h6 {
        margin-top:10px;
        margin-bottom:0;
    }

    .caption {
        font-size:em(15,$fs);
        font-style:italic;
        color:#999;
        padding-top:5px;
    }

    &.float-center {
        margin:2em auto;
    }

    &.float-left {
        float:left;
        margin:17px 2.25em 1em 0;
    }

    &.float-right {
        float:right;
        margin:17px 0 1em 2.25em;
    }
}

// - - - - - - - - - - - - - EXPANDS

.ham-expand-wrapper {
    position:relative;
    overflow:hidden;
    display:block;
    margin-bottom:20px;
    transition:max-height $ms ease-in-out;

    span {
        display:block;
    }

    .ham-expand-btn {
        display:inline-block;
        text-decoration: none;
        cursor:pointer;
        color:white;
        font-weight:600;
        font-size:em(18,$fs);
        position:relative;
        padding:10px 8px 10px 40px;
        background:$primary;
        width:100%;
        line-height:1.3;

        &::before {
            color:white;
            display: block;
            font-style:normal;
            font-weight:normal;
            content: '\e820';
            font-size:em(24,$fs);
            position:absolute;
            left:8px;
            top:8px;
            line-height:1;
            will-change:transform;
            transition:all $ms linear;
            @extend %icon;
        }

        &:hover::before {
            color:$secondary;
        }
    }

    .ham-expand-content {
        padding:10px 0 0 40px;

        br {
            display: none;
        }
    }

    &.open {
        .ham-expand-btn::before {
            transform:rotate(45deg);
            color:$secondary;
        }
    }
}

.section .ham-expand-wrapper:last-child {
    margin-bottom:0;
}

.page-main{
    .sidebar{
        background:$greylight;
        border:1px $grey solid;
        padding:em(15,$fs);
        margin:em(30,$fs) 0 0;
    }
    .nav.items{
        list-style:none;
        margin:0;
        padding:0;

        li{
            padding:0;
            margin:0;

            &:before{
                display:none;
            }

            &.current{
                padding:em(15,$fs) 0;

                a{
                    padding:0;
                }

                strong{
                    font-weight:900;
                }
            }

            + li{
                border-top:1px $grey solid;
            }
        }

        a{
            display:block;
            padding:em(15,$fs) 0;
        }
    }
}

// .page.messages{
//     background:$grey;
//     padding:em(15,$fs);
//     margin:0 0 em(30,$fs);
// }

.sidebar{
    .block{
        + .block{
            margin:em(30,$fs) 0 0;
        }
    }
}

.block-content{
    @include afterclear;

    .box{
        float:left;
        width:49%;

        &:nth-child(2n+1){
            clear:both;
        }

        &:not(:nth-child(2n+1)){
            margin-left:2%;
        }
    }
}

.box-actions{
    a{
        font-size:em(16,$fs);

        + a{
            margin-left:em(10,16);
            padding-left:em(10,16);
            border-left:1px $grey solid;
        }
    }
}

.actions-toolbar{
    @include afterclear;
    margin:em(30,$fs) 0 0;

    .primary{
        float:left;
    }

    .secondary{
        float:right;
    }
}

.more-finishes {
    display: none;
    position: absolute;
    top: 35px;
    left: -6px;
    background: $grey;
    font-size: 12px;
    font-weight: normal;
    padding: 10px 8px 2px;
    width: 260px;
    border-radius: 7px;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.15);
    z-index:100;

    &:before{
        content:"";
        display:block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 7px 7.5px;
        border-color: transparent transparent $grey transparent;
        position: absolute;
        top: 0;
        left: 10px;
        transform:translateY(-100%);
    }

    .product-list-item-finishes &{
        width:auto;

        ul.product-list-item-finishes{
            li {
                float: none;
                display: inline-block;
                margin: 3px;
            }
        }
    }

    .product-list-item-finishes{
        margin:0 !important;
    }
}



.tooltip{
    @include afterclear;
    position: absolute;
    bottom:-10px;
    left:-99999px;
    transform:translateY(100%);
    background:$grey;
    font-size:12px;
    padding:em(10,12);
    width:260px;
    border-radius:7px;
    transform:translateY(calc(100% + 15px));
    opacity:0;
    transition:opacity $ms ease-in-out, transform $ms ease-in-out, left 1ms ease-in-out $ms;
    z-index:100;
    -webkit-box-shadow: 0 3px 3px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.15);
    font-weight:normal;
    pointer-events:none;

    *:hover > &{
        transform:translateY(100%);
        opacity:1;
        left:-7px;
        transition:opacity $ms ease-in-out, transform $ms ease-in-out;
    }

    &:before{
        content:"";
        display:block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 7px 7.5px;
        border-color: transparent transparent $grey transparent;
        position: absolute;
        top:0;
        left:10px;
        transform:translateY(-100%);
    }

    .product-list-item-finishes &{
        width:auto;

        .product-list-item-finishes{
            width:86px;
        }
    }

    .product-list-item-finishes{
        margin:0 !important;
    }
}

$hero_top_pos: em(-15,$fs);
$hero_padding_percent: percentage(469/$inside_width_num);
.page-hero-block{
    position: absolute;
    top:$hero_top_pos;
    left:50%;
    transform:translateX(-50%);
    width:$hwidth;
    max-width:calc(100vw - .9375em - .9375em);
    padding-bottom:$hero_padding_percent;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
}

.page-layout-flexible-inside-page-hero.page-layout-flexible-inside-page-hero{
    .page-main > *:first-child{
        $main_padding_ems: em(15,$fs);
        margin-top:0;
        border-top:0;
        padding-top:calc(#{$hero_padding_percent} + #{$hero_top_pos} + #{$main_padding_ems});
    }
}

.content-columns{
    &.cols-4{
        .col{
            background:$greymedium;
            padding:em(15,$fs);
            text-align:center;
        }
    }
    .col{
        @include afterclear;
        position: relative;
        clear:both;

        + .col{
            margin-top:em(15,$fs);

            @media(max-width: 439px) { margin: 3em 0 2em; }
        }

        img{
            display:block;
            margin:0 auto;

            + strong{
                position: absolute;
                bottom:em(15,$fs);
                right:em(15,$fs);
                background:rgba($white,.90);
                display:inline-block;
                font-weight:600;
                text-transform:uppercase;
                padding:em(10,$fs) em(15,$fs);
                max-width:calc(100% - .9375em - .9375em);
            }
        }

        > a{
            display:block;
            margin:0 auto;
            position: relative;

            > img{
                display:block;
                margin:0;
            }
        }
    }
}

// Pagination
.pages {
    border-top: 1px solid #ccc;
    margin: 45px 0 5px;

    #paging-label {
        display: inline-block;
        font-weight: normal;
        font-size: 0.9rem;
    }

    ul.pages-items {
        display: inline-block;
        padding-left: 10px;
        margin-top: -1px;

        li.item {
            display: inline-block;
            width: 25px;
            text-align: center;
            transition:all $ms ease-in;

            &.pages-item-previous, &.pages-item-next {
                width: auto;
            }

            &.current {
                border-top: 2px solid #000;
            }

            &:hover {
                border-top: 2px solid #999;
            }

            &.current {
                a {
                    font-weight: 700;
                }
            }
        }
    }

    .view-all {
        float: right;
        font-weight: normal;
        font-size: 0.8rem;
    }
}

.page-layout-style-collection{
    .column.main{
        .block{
            + .block{
                margin:em(60,$fs) 0 0;
            }
        }
    }
    .callout-row.cols-1{
        .callout{
            h2{
                width:auto;
            }
            .callout-box{
                p{
                    font-size:em(16,$fs);
                }
            }
        }
    }
}

[class*="page-layout-flexible-inside-page"],
[class*="aw-blog-post-view"],
[class*="aw_blog-post-view"]
{
    .page-title-wrapper{
        margin-top:em(35,$fs);
    }
    .page-title{
        font-size:em(55,$fs);
        font-weight:normal;
        border-bottom:1px $greydark solid;
        padding-bottom:em(15,55);
        margin-bottom:em(25,55);
    }

    .columns{
        h2,h3{
            font-size:em(18,$fs);
            margin:0 0 em(15,18);
        }

        h4,h5,h6{
            font-size:em(14,$fs);
            margin:0 0 em(20,14);
        }

        h2,h3,h4,h5,h6{
            font-weight:600;
            text-transform:uppercase;
            font-family:$font_body;

            + .content-columns{
                margin-top:0;
            }

            &.center-italic{
                font-size:em(32,$fs);
                font-family:$font_script;
                font-style:italic;
                text-align:center;
                text-transform:none;
                font-weight:normal;
            }
        }
    }

    .content-columns{
        margin:em(30,$fs) 0;

        &.no-bottom-margin{
            margin-bottom:0;
        }
    }

    .style-carousel{
        margin:em(30,$fs) 0;
    }

    table{
        margin:0 auto;

        td{
            border:1px $greydark solid;
            padding:em(5,$fs) em(10,$fs);
        }
    }
}

.page-layout-flexible-inside-page-hero-alt.page-layout-flexible-inside-page-hero-alt{
    .page-main > *:first-child{
        padding-top:0;
    }
    .columns{
        clear:both;
    }
    .page-mini-hero-block{
        width:100%;
        padding-bottom:34.77%;
        margin-bottom:em(15,$fs);
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
    }
}

.helpful-links.helpful-links{
    background:$grey;
    border-left:1px $greydarkest solid;
    padding:em(20,$fs) 0;
    margin:0 0 em(15,$fs) 0;

    @include headings{
        font-size:em(24,$fs);
        font-family:$font_script;
        font-style:italic;
        display:inline-block;
        padding:0 em(34,24);
        position: relative;
        z-index:2;
        margin:0 0 em(10,24);
        text-transform:none;
        font-weight:normal;
    }

    ul{
        list-style:none;
        margin:0;
        padding:0;

        a{
            display:block;
            font-size:em(16,$fs);
            text-transform:uppercase;
            font-weight:normal;
            padding:em(3,16) em(34,16);
            border-left:1px $grey solid;
            transition:all $ms ease-in-out;

            &:hover{
                color:$black;
                border-left-color:$greydarkest;
                background:rgba($white,.50);
            }
        }
    }
}

.page-quote-block.page-quote-block{
    text-align:center;
    margin:em(65,$fs) 0;

    h5{
        font-size:em(48,$fs);
        margin:0 0 em(10,48);
        font-family:$font_heading;
        font-weight:normal;
        text-transform:uppercase;
    }

    h6{
        font-size:em(16,$fs);
        margin:0;
        font-family:$font_body;
        text-transform:uppercase;
        font-weight:600;
    }
}

.page-need-help-block.page-need-help-block{
    margin:em(30,$fs) 0 0;
    background:$grey;
    border-top:1px $greydarkest solid;
    padding:em(20,$fs) em(34,$fs);
    text-align:center;

    @include headings{
        font-size:em(24,$fs);
        font-family:$font_script;
        font-style:italic;
        display:inline-block;
        margin:0 0 em(15,24);
        text-transform:none;
        font-weight:normal;
    }

    p{
        line-height:2em;
    }

    a{
        position: relative;

        &:before{
            content:"";
            width:100%;
            height:2px;
            background:rgba($black,.25);
            position: absolute;
            bottom:-2px;
            left:0;
        }

        &:after{
            content:"";
            width:0;
            height:2px;
            background:$black;
            position: absolute;
            bottom:-2px;
            left:0;
            transition:all $ms ease-in-out;
        }

        &:hover{
            color:$black;

            &:after{
                width:100%;
            }
        }
    }
}

.post-list.post-list{
    list-style:none;
    margin:0;
    padding:0;

    a{
        display:block;
        border:1px $greymedium solid;
        overflow:hidden;

        &:hover{
            @include headings{
                padding-left:em(20,26);
                padding-right:em(10,26);
            }
        }
    }

    strong{
        display:inline-block;
        background:$black;
        color:$white;
        font-size:em(12,$fs);
        padding:em(3,12) em(10,12);
        font-weight:600;
        text-transform:uppercase;
        transform:translateX(-1px) translateY(-2px);
    }

    @include headings{
        margin:0;
        font-family:$font_heading;
        text-transform:none;
        font-weight:normal;
        font-size:em(26,$fs);
        padding:em(15,26);
        will-change:padding;
        transition:padding $ms ease-in-out;
    }

    li{
        + li{
            margin:em(30,$fs) 0 0;
        }
    }
}

.picture-list-item{
    @include afterclear;
    margin:em(45,$fs) 0 0;

    img{
        float:left;
    }
    p{
        float:right;
        width:calc(100% - 325px);
    }
}

.search-autocomplete{
    z-index:100;

    &:not(:empty){
        background:$white;
        left:0;
        -webkit-box-shadow: 0 5px 5px 0 rgba(0,0,0,.05);
        box-shadow: 0 5px 5px 0 rgba(0,0,0,.05);
        min-width:300px;
    }

    .autocomplete-list-title{
        display:none;
    }

    .autocomplete-list{
        dd{
            @include afterclear;
            cursor:pointer;
            padding:em(10,14) em(30,14);
            transition:background $ms ease-in-out;

            + dd{
                border-top:1px $gray solid;
            }

            &:hover{
                background:$gray;
            }

            .product-primary{
                .product-sku{
                    font-weight:600;
                }
                .product-name {
                    font-size:.9em;
                    line-height: 1.2em;
                }
                .family-name {
                    font-size:.9em;
                    color: #222;
                }
            }

            .product-secondary{
                font-size:.8em;
            }
        }
    }

    .product-image-box{
        float:left;
        width:45px;
    }

    .product-item{
        float:right;
        width:calc(100% - 45px - 15px);
    }
}

#search_autocomplete {
    dl.autocomplete-list {
        overflow-x: scroll;
        max-height: 80vh;
    }
}

// Homepage - Featured Products list
.cms-index-index {
    .products.wrapper.grid.products-grid {
        max-width: 1245px;
        margin: 0 auto;
    }
}

.cms-prop-65 {
    h1.page-title {
        font-size: 2.5em;
    }
}

.warning-information {
    .title {
        font-size: .9375em;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    a{
        position: relative;

        &:before{
            content:"";
            width:100%;
            height:2px;
            background:rgba($black,.25);
            position: absolute;
            bottom:-2px;
            left:0;
        }

        &:after{
            content:"";
            width:0;
            height:2px;
            background:$black;
            position: absolute;
            bottom:-2px;
            left:0;
            transition:all $ms ease-in-out;
        }

        &:hover{
            color:$black;

            &:after{
                width:100%;
            }
        }
    }
}

.webforms-calendar {
    .ui-datepicker-trigger {
        top: 0.1rem !important;
        right: 0.2rem !important;
    }
}
