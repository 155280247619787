.footer-wrap{
    footer{
        padding-bottom:em(15,$fs);

        ul{
            list-style:none;
            margin:0;
            padding:0;
        }

        .footer.content{
            .links{
                @include afterclear;
                margin:em(80,$fs) 0 0;
            }
        }
    }
}

.block.newsletter{
    position: relative;
    width:675px;
    max-width:100%;
    margin:0 auto;

    label{
        font-size:em(18,$fs);
        display:block;
        width:100%;
        text-align:center;
    }

    input#newsletter{
        font-size:em(18,$fs);
        height:48px;
        line-height:48px;
        padding:0 em(15,18);
        color:$black;
        font-family:$font_body;
        border:1px $grey solid;
        width:calc(100% - 111px - 9px);
        max-width:100%;
        margin:em(11,18) 0 0;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            opacity:1;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            opacity:1;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            opacity:1;
        }
        &:-moz-placeholder { /* Firefox 18- */
            opacity:1;
        }
    }

    .actions{
        position: absolute;
        bottom:0;
        right:0;
        width:111px;

        .action{
            width:100%;
            height:48px;
            line-height:44px;
            background:$black;
            color:$white;
            border:2px $black solid;
            outline:none;
            font-family:$font_body;
            font-size:em(16,$fs);
            text-transform:uppercase;
            font-weight:600;
            transition:all $ms ease-in-out;

            &:hover{
                background:transparent;
                color:$black;
            }
        }
    }

    #newsletter-error{
        position: absolute;
        bottom:0;
        left:0;
        transform:translateY(100%);
        color:$red;
        font-size:em(12,$fs);
    }
}

.footer-links-block{
    @include afterclear;

    a{
        color:$black;
        font-size:em(14,$fs);
        font-weight:600;
        transition:all $ms ease-in-out;

        &:hover{
            color:$greydark;
        }
    }

    ul{
        float:left;
        width:32%;

        + ul{
            margin-left:2%;
        }
    }
}

.footer-follow-block{
    @include afterclear;
    margin:em(15,$fs) 0 0;
    background:$grey;
    border-top:1px $greydark solid;
    padding:em(22,$fs);

    h6{
        font-family:$font_body;
        font-size:em(24,$fs);
        margin:0 0 em(14,24);
    }
}

.social-media-links{
    @include afterclear;
    float:left;
    list-style:none;
    margin:0;
    padding:0;

    li{
        float:left;

        + li{
            margin:0 0 0 em(10,$fs);
        }
    }
    a{
        display:block;
        width:35px;
        height:35px;
        border-radius:50%;
        text-indent:-99999px;
        overflow:hidden;
        background:$black;
        position: relative;
        border:1px $black solid;
        transition:all $ms ease-in-out;
        -webkit-backface-visibility: hidden;
        transform:translate3d(0,0,0);

        &:before{
            position: absolute;
            top:50%;
            left:50%;
            text-indent:0;
            color:$white;
            font-size:1.25em;
            line-height:1em;
            transition:all $ms ease-in-out;
            -webkit-backface-visibility: hidden;
            transform:translate3d(-50%,-50%,0);
        }

        &:hover{
            background:transparent;

            &:before{
                color:$black;
            }
        }
    }
}

.social-hashtags.social-hashtags{
    float:right;
    margin:em(15,$fs) 0 0;

    li{
        float:left;
        font-size:em(20,$fs);
        letter-spacing:em(.75,20);

        + li{
            margin:0 0 0 em(15,$fs);
        }
    }
}

.footer-contact-block{
    @include afterclear;
    clear:both;
    float:left;
    width:100%;
    margin:em(40,$fs) 0 0;
    font-size:em(14,$fs);

    address{
        float:left;
    }

    .tollfree{
        font-size:em(24,14);
        margin:em(12,24) 0 0;
    }

    h6{
        font-family:$font_body;
        text-transform:uppercase;
        font-size:em(16,14);
        margin:0 0 em(5,16);
    }

    span{
        display:block;
    }

    a{
        color:$black;
        font-weight:600;
        transition:all $ms ease-in-out;

        &:hover{
            color:$greydark;
        }
    }
}

.footer-copyright-block{
    clear:both;
    float:left;
    width:100%;
    font-size:em(12,$fs);
    margin:em(20,12) 0 0;

    ul{
        @include afterclear;

        li{
            float:left;
            line-height:1em;

            + li{
                margin-left:em(10,12);
                padding-left:em(10,12);
                border-left:1px $black solid;
            }

            &:last-child{
                border:none;
                color:$greydark;
            }
        }
    }

    a{
        color:$black;
        font-weight:600;
        transition:all $ms ease-in-out;

        &:hover{
            color:$greydark;
        }
    }
}