html {
    overflow-y: scroll;
    overflow-x: hidden;
}
body{
    margin:0;
    padding:0 0 0;
    color:$black;
    font:normal 14px/1.65 $font_body;
}
* {
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin:0;
    padding:0;
    line-height:1.2;
}
p {
    margin: 0 0 1em;
    &:last-child{
        margin-bottom:0;
    }
}
ul,ol{
    margin:0 0 1em;
}
strong {
    font-weight:bold;
}
a,
a:visited,
a:hover {
    text-decoration:none;
}
iframe {
    max-width: 100%;
}
%btn {
    border:0;
    background:$primary;
    color:$white;
    padding:0.5em 1.5em;
    font-weight:600;
    line-height:1em;
    display:inline-block;
    outline:0;
    border-radius:0;
    border:2px $primary solid;
    text-decoration:none;
    transition:all 350ms ease-in-out;
    &:hover {
        background:transparent;
        color:$primary;
    }
    &.light{
        border:1px $grey solid;
        background:$white;
        color:$black;
        &:hover{
            background:$black;
            border-color:$black;
            color:$white;
        }
    }
    &.reverse{
        background:transparent;
        color:$primary;
        &:hover{
            background:$primary;
            color:$white;
        }
    }
    &.white{
        border-color:$white;
        &:hover{
            background:$white;
            color:$black;
        }
    }
}
.btn {
    @extend %btn;
}
.btn-list{
    @include afterclear;
    list-style:none;
    margin:0;
    padding:0;
    li{
        float:left;
        + li{
            margin-left:em(10,$fs);
        }
    }
}
%flicker {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
}
%icon {
    font-family:$icon;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.wrap{
    width:100%;
    padding:0 em(15,$fs);
    > *{
        @include afterclear;
        max-width:$width;
        margin:0 auto;
    }
    > header{
        max-width:$hwidth;
    }
    > footer{
        max-width:$width;
    }
    > .page-bottom{
        max-width:$width;
    }
}
body:not(.page-layout-home){
    .wrap{
        > *{
            max-width:$inside_width;
        }
        > header{
            max-width:$hwidth;
        }
        > footer{
            max-width:$width;
        }
        > .page-bottom{
            max-width:$width;
        }
    }
}
.page-bottom{
    padding:em(7,$fs) 0 em(33,$fs);
}
.modals-wrapper{
    display:none;
}
.expandable-content{
    max-height:0;
    overflow:hidden;
    transition:max-height $ms ease-in-out;
    &.open{
        max-height:500px;
    }
}
.mfp-bg{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&0.9+0,1+25,1+50,1+75,0.9+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0.9) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 75%, rgba(255,255,255,0.9) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,1) 25%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 75%,rgba(255,255,255,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,1) 25%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 75%,rgba(255,255,255,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffff', endColorstr='#e6ffffff',GradientType=0 ); /* IE6-9 */
    &:before{
        content:"";
        width:100vw;
        height:60vh;
        display:block;
        background:url(../images/logo-mark.svg) no-repeat left top;
        background-size:contain;
        opacity:.15;
    }
}
.mfp-fade.mfp-bg {
    opacity:0;
    transition:opacity $ms ease-in-out;
    z-index:9999;
}
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 1;
}
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}
.mfp-fade.mfp-wrap{
    z-index:9999;
}
.mfp-fade.mfp-wrap .mfp-content {
    opacity:0;
    transform:translateY(-100px);
    transition:opacity $ms ease-in-out,transform $ms ease-in-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity:1;
    transform:translateY(0);
    margin-top:em(30,$fs);
    padding:0 em(30,$fs) em(30,$fs);
    &:before{
        content:"";
        width:calc(100% - 1.875em);
        height:em(30,$fs);
        display:block;
        position: absolute;
        top:0;
        left:0;
        transform:translateY(-100%);
        background:$white;
    }
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity:0;
    transform:translateY(-100px);
}
.mfp-fade .mfp-figure{
    &:after{
        display:none;
    }
}
.mfp-fade .mfp-img{
    padding:0;
    max-height:80vh !important;
}
.mfp-fade .mfp-close, .mfp-image-holder .mfp-close{
    position: absolute;
    top:-5px;
    right:-5px!important;
    display:block;
    padding:0;
    font-size:1em;
    line-height:1em;
    opacity:1;
    color:$white!important;
    background:$black;
    text-indent:-99999px;
    overflow:hidden;
    width:em(30,$fs)!important;
    height:em(30,$fs)!important;
    text-align:left!important;
    transform:translateY(-100%)!important;
    cursor:pointer !important;
    transition:all $ms ease-in-out;
    &:before{
        content:"\e80a";
        font-size:em(20,$fs);
        font-family:$icon;
        color:$white;
        position: absolute;
        top:50%;
        left:50%;
        text-indent:0;
        transform:translateX(-50%) translateY(-50%);
        z-index:1;
    }
    &:hover{
        background:lighten($black,25%);
    }
}
.mfp-content{
    background:$white;
    max-width:$tablet;
    padding:em(30,$fs) em(60,$fs);
    -webkit-box-shadow: 0 0 25px 0 rgba(0,0,0,.10);
    box-shadow: 0 0 25px 0 rgba(0,0,0,.10);
    .mfp-form &{
        padding:em(30,$fs) em(50,$fs) 0 em(50,$fs);
    }
}
.mfp-container{
    padding:em(30,$fs);
}
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}
.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
#notice-cookie-block{
    position: fixed;
    bottom:0;
    left:0;
    z-index:999;
    .content{
        background:$black;
        position:fixed;
        bottom:0;
        left:0;
        width:100%;
        padding:em(15,$fs);
        p,
        a{
            color:$white;
        }
    }
}
.cookie-content-inside{
    max-width:$inside_width;
    margin:0 auto;
    .actions{
        text-align:right;
    }
}
#speedbump{
    width:100%;
    .speedbump-flex{
        display:flex;
        justify-content:space-between;
        align-items:stretch;
        width:100%;
    }
    strong{
        background:url(../images/logo.svg) no-repeat center center;
        background-size:contain;
        text-indent:-99999px;
        overflow:hidden;
        display:block;
        width:175px;
        height:44px;
        margin:0 0 em(25,$fs);
    }
    @include headings{
        margin:0;
        font-family:$font_body;
        text-transform:uppercase;
        font-size:em(18,$fs);
        font-weight:600;
    }
    .messages{
        display:none;
    }
    .speedbump-content{
        width:100%;
        margin-top: 20px;
        .webforms .field {
            padding-left: 0;
        }
        .actions-toolbar {
            padding-left: 0;
        }
    }
    .speedbump-image{
        display:none;
    }
    fieldset{
        border:none;
        padding:0;
    }
    .type-email{
        position: relative;
        label{
            position: absolute;
            padding:0;
            text-indent:-99999px;
            overflow:hidden;
            width:em(48,$fs);
            height:100%;
            background:$black;
            &:before{
                content:"\e80b";
                text-indent:0;
                color:$white;
                font-family:$icon;
                font-size:em(20,$fs);
                position: absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
            }
        }
        .control{
            width:calc(100% - 3em);
        }
    }
    .webforms:last-child{
        margin:0;
    }
    .type-select-checkbox{
        .control{
            width:100%;
        }
    }
}
.lb-arrow{
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    outline:none;
    border:none;
    background:none;
    font-size:em(50,$fs);
    padding:em(30,50);
    &:before{
        font-family:$icon;
        color:$greydark;
        transition:all $ms ease-in-out;
    }
    &:hover{
        &:before{
            color:$black;
        }
    }
}
.lb-arrow-left{
    left:0;
    &:before{
        content:"\e806";
    }
}
.lb-arrow-right{
    right:0;
    &:before{
        content:"\e807";
    }
}
.mfp-bottom-bar{
    display:none;
}
