//
// Product Registration / Hello Hinkley
// ---------------------------------------------------------------------------------------------------------------------

// @media (max-width: 1279px) { width: 58%; }
// @media (max-width: 767px) { width: 100%; float: none; clear: both; }

// Product Registration
.hello-register-item {
    .hh-product-registration {
        margin-bottom: 40px;
        margin-bottom: 40px;
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
        // All
        .top-intro {
            margin: 20px 0;
        }
        form {
            text-align: center;
            label {
                font-weight: 600;
                margin-bottom: 10px;
            }
            input {
                font-size: 17px;
                font-weight: normal;
                color: #000;
                height: 46px;
                padding: 6px 8px 6px 8px;
                border: 1px solid #000;
                width: 100%;
                max-width:480px;
                display: block;
                margin: 0 auto;
            }
            button.btn-continue {
                height: 50px;
                width: 100%;
                max-width:480px;
                display: block;
                margin: 15px auto;
            }
            .invalid-code-msg {
                .msg-alert {
                    color: red;
                    font-weight: bold;
                }
                .msg-alert:first-child {
                    margin: 35px 0 0;
                }
                .msg-info {
                    margin: 0;
                }
            }
        }
        .bottom-hints {
            margin-top: 35px;
            p:last-child {
                margin-top: 10px;
            }
            #code-location {
                background: transparent url(../images/eye.svg) center left no-repeat;
                padding-left: 40px;
                background-size: 27px 27px;
            }
        }

        // Mobile
        @media (max-width: 979px) {
            text-align: center;
            form {
                display: inline-block;
                label {
                    float: left;
                }
            }
        }
    }
}

// Items
.hh-registered-products, .hh-personal-info, .hh-purchase-info, .hh-registration-complete {
    margin: 30px 0 60px;
    .product-items {
        margin-bottom: 20px;
    }
    .item-container {
        display: table;
        width: 100%;
        margin: 0 auto 20px;
        max-width: 480px;
        clear: both;
        position: relative;
        &.single-item {
            .remove {
                position: absolute;
                right: 0;
                cursor: pointer;
                background-image: url(../images/close.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 12px;
                height: 12px;
            }
        }
        .remove {
            position: absolute;
            right: 0;
            cursor: pointer;
            background-image: url(../images/close.svg);
            background-repeat: no-repeat;
            background-position: center;
            width: 12px;
            height: 12px;
        }
        .item-thumb {
            float: left;
            img {
                display: block;
                border: 1px solid #000;
                width: 120px;
                height: auto;
            }
         }
         .item-content {
            display: table-cell;
            vertical-align: middle;
            padding-left: 20px;
            width: 74%;
            .title {
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 5px;
                text-align: left;
            }
            .subtitle {
                text-align: left;
            }
        }
        &.single-item {
            .item-thumb {
                float: none;
                img {
                    display: block;
                    border: 1px solid #000;
                    width: 100%;
                    max-width: 320px;
                    height: 320px;
                    margin: 0 auto;
                }
            }
            .item-content {
                display: block;
                vertical-align: middle;
                padding-left: 20px;
                width: 100%;
                .title {
                    font-weight: normal;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    text-align: center;
                    font-size: 1.5em;
                }
                .subtitle {
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .item-container:last-child {
        margin: 0 auto 35px;
    }
    // Mobile
    @media (max-width: 979px) {
        text-align: center;
        form {
            //display: inline-block;
            label:not(.checkbox):not(.radio) {
                display: block;
                text-align: left;
                width: 100%;
                max-width: 480px;
                margin: 0 auto;
                margin-bottom: 10px;
            }
        }
    }
}

.hh-purchase-info, .hh-registration-complete {
    form {
        label.top-label {
            text-align: center;
        }
        .form-control {
            width: 100%;
            max-width: 480px;
            margin: 0 auto 20px;
            text-align: left;
            p.required {
                color: red;
            }
        }
        .select {
            background: #fff;
            font-size: 17px;
            font-weight: normal;
            color: #444;
            height: 44px;
            padding: 6px 8px;
            border: 1px solid #eee;
            width: 100%;
        }
        textarea {
            width: 100%;
            border: 1px solid #000;
        }
        label:not(.checkbox):not(.radio-custom-label) {
            font-weight: 600;
            margin-bottom: 10px;
            display: block;
            &.required {
                color: red;
            }
        }
        input:not([type=checkbox]):not([type=radio]) {
            font-size: 17px;
            font-weight: normal;
            color: #000;
            height: 46px;
            padding: 6px 8px 6px 8px;
            border: 1px solid #000;
            width: 100%;
            max-width:480px;
            display: block;
            margin: 0 auto;
        }
        .control {
            margin-bottom: 20px;
        }
        .radio {
            position: relative;
            width: 100%;
            max-width: 480px;
            display: block;
            margin: 0 auto;
            text-align: left;
        }
        .radio-custom {
            opacity: 0;
            position: absolute;
            z-index: 1;
            left: 0;
            height: 20px;
            width: 20px;
        }
        .radio-custom, .radio-custom-label {
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            cursor: pointer;
        }
        .radio-custom-label {
            position: relative;
        }
        .radio-custom + .radio-custom-label:before {
            content: '';
            background: #eee;
            border: 0px solid #ddd;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            padding: 2px;
            margin-right: 10px;
            text-align: center;
        }
        .radio-custom + .radio-custom-label:before {
            border-radius: 0;
        }

        .radio-custom:checked + .radio-custom-label:before {
            content: "\2713";
            color: black;
            font-weight: bold;
            line-height: 20px;
        }
        .radio-custom:focus + .radio-custom-label {
            outline: 0px solid #ddd; /* focus style */
        }
        #purchase_point_other-error {
            display: block!important;
            color: red;
        }
        button.btn-continue {
            height: 50px;
            width: 100%;
            max-width:480px;
            display: block;
            margin: 15px auto;
        }
    }
}

// Products Details
.hh-registered-products {
    form {
        text-align: center;
        .form-control {
            margin-bottom: 20px;
        }
        label {
            font-weight: 600;
            margin-bottom: 7px;
        }
        input {
            font-size: 17px;
            font-weight: normal;
            color: #000;
            height: 46px;
            padding: 6px 8px 6px 8px;
            border: 1px solid #000;
            width: 100%;
            max-width:480px;
            display: block;
            margin: 0 auto;
        }
        a.add-field {
            margin: 0 0 20px;
            display: block;
        }
        .control {
            width: 100%;
            max-width: 480px;
            margin: 0 auto 20px;
            position: relative;
            .remove-field {
                position: absolute;
                right: 0;
                cursor: pointer;
                background-image: url(../images/substract.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 12px;
                height: 12px;
            }
        }

        button.btn-continue {
            height: 50px;
            width: 100%;
            max-width:480px;
            display: block;
            margin: 15px auto;
        }
        .invalid-code-msg {
            .msg-alert {
                color: red;
                font-weight: bold;
            }
            .msg-success {
                color: #5ac65a;
                font-weight: bold;
            }
            .msg-alert:first-child {
                margin: 35px 0 0;
            }
            .msg-info {
                margin: 0;
            }
        }
        .confirmation {
            .confirm-text {
                font-weight: 600;
            }
            .buttons-set {
                display: flex;
                max-width: 480px;
                margin: 0 auto;
                .btn-continue {
                    margin: 15px 2px;
                }
                .btn-continue.btn-back {
                    background-color: #999999;
                    color: white;
                    border: 2px #999999 solid;
                    &:hover, &:focus {
                        background-color: #999999;
                        color: white;
                        border: 2px #999999 solid;
                    }
                }
            }
        }
    }
    .bottom-hints {
        margin-top: 20px;
        text-align: center;
    }
}

// Purchase Info
.hh-purchase-info {
    form {
        margin-bottom: 20px;
        display: block;
        padding: 20px 0;
        border-bottom: 1px solid #000;
        width: 100%;
        max-width: 480px;
        margin: 15px auto;
        .form-item {

        }
        .form-item:last-child {
            //border-bottom: 0px solid #000;
        }
        .form-item:last-of-type {
            border-bottom: 0px solid #000;
        }
        .radio-custom-other{
            border-color: red!important;
        }
    }
    .invalid-code-msg {
        width: 100%;
        max-width: 480px;
        margin: 15px auto;
        text-align: center;
        .msg-alert {
            margin: 35px 0 0;
            color: red;
            font-weight: bold;
        }
        .msg-info {
            margin: 0;
        }
    }
    form:last-child {
        border-bottom: 0px solid #000;
    }
    form:last-of-type {
        border-bottom: 0px solid #000;
    }
    .bottom-hints {
        margin-top: 20px;
        text-align: center;
    }
    button.btn-continue {
        height: 50px;
        width: 100%;
        max-width:480px;
        display: block;
        margin: 15px auto;
        line-height: 44px;
        background: #000;
        color: #fff;
        border: 2px #000 solid;
        outline: none;
        font-weight: 600;
        font-family: "HL-Raleway",sans-serif;
        text-transform: uppercase;
        -webkit-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
        font-size:em(16,$fs);
        &:hover, &:focus {
            background: transparent;
            color: #000;
        }
    }
}

// Personal Info
.hh-personal-info {
    .product-items {
        display: grid;
    }
    form {
        .invalid-code-msg {
            width: 100%;
            max-width: 480px;
            margin: 15px auto;
            text-align: center;
            .msg-alert {
                margin: 35px 0 0;
                color: red;
                font-weight: bold;
            }
            .msg-info {
                margin: 0;
            }
        }
        label.top-label {
            text-align: center;
        }
        .form-control {
            width: 100%;
            max-width: 480px;
            margin: 0 auto 20px;
            text-align: left;
            p.required {
                color: red;
            }
        }
        .select {
            background: #fff;
            font-size: 17px;
            font-weight: normal;
            color: #444;
            height: 44px;
            padding: 6px 8px;
            border: 1px solid #eee;
            width: 100%;
        }
        textarea {
            width: 100%;
            border: 1px solid #000;
        }
        label:not(.checkbox):not(.radio-custom-label) {
            font-weight: 600;
            margin-bottom: 10px;
            display: block;
            &.required {
                color: red;
            }
        }
        input:not([type=checkbox]):not([type=radio]) {
            font-size: 17px;
            font-weight: normal;
            color: #000;
            height: 46px;
            padding: 6px 8px 6px 8px;
            border: 1px solid #000;
            width: 100%;
            max-width:480px;
            display: block;
            margin: 0 auto;
        }
        .control {
            margin-bottom: 20px;
        }
        .radio {
            position: relative;
            width: 100%;
            max-width: 480px;
            display: block;
            margin: 0 auto;
            text-align: left;
        }
        .radio-custom {
            opacity: 0;
            position: absolute;
            z-index: 1;
            left: 0;
            height: 20px;
            width: 20px;
        }
        .radio-custom, .radio-custom-label {
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            cursor: pointer;
        }
        .radio-custom-label {
            position: relative;
        }
        .radio-custom + .radio-custom-label:before {
            content: '';
            background: #eee;
            border: 0px solid #ddd;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            padding: 2px;
            margin-right: 10px;
            text-align: center;
        }
        .radio-custom + .radio-custom-label:before {
            border-radius: 0;
        }

        .radio-custom:checked + .radio-custom-label:before {
            content: "\2713";
            color: black;
            font-weight: bold;
            line-height: 20px;
        }
        .radio-custom:focus + .radio-custom-label {
            outline: 0px solid #ddd; /* focus style */
        }
        button.btn-continue {
            height: 50px;
            width: 100%;
            max-width:480px;
            display: block;
            margin: 15px auto;
            text-transform: initial;
        }
    }
    .bottom-hints {
        margin-top: 20px;
        text-align: center;
    }
}

// Registration Complete (extra fields)
.hh-registration-complete {
    .page-title-wrapper {
        text-align: center;
        margin-bottom: 30px;
        h1.page-title {
            font-weight: normal;
            margin-bottom: 10px;
            &.bold {
                font-weight: 600;
            }
        }
    }
    .top-text {
        text-align: center;
        max-width: 480px;
        width: 100%;
        margin: 0 auto 30px;
        padding-bottom: 40px;
        border-bottom: 1px solid #000;
    }
    form {
        .invalid-code-msg {
            .msg-alert {
                margin: 35px 0 0;
                color: red;
                font-weight: bold;
            }
            .msg-info {
                margin: 0;
            }
        }
    }
    .bottom-hints {
        margin-top: 20px;
        text-align: center;
    }
}

// Registration Success
.hh-registration-success {
    margin-bottom: 60px;
    .page-title-wrapper {
        text-align: center;
        margin-bottom: 20px;
        h1.page-title {
            font-weight: normal;
            margin-bottom: 10px;
            &.bold {
                font-weight: 600;
              }
        }
    }
    .top-text {
        text-align: center;
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
    }
}

