// Cart page
.checkout-cart-index .page-main {
    padding-left: 15px;
    padding-right: 15px;
}
.cart-container .form-cart .actions.main {
    text-align: right;
}
.cart-container .widget {
    float: left;
}
.cart-summary {
    width: 25%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    padding: 1px 20px 25px;
    position: relative;
}
.cart-summary > .title {
    display: block;
}
.cart-summary .fieldset .actions-toolbar {
    margin-left: 0;
}
.cart-summary .fieldset .actions-toolbar > .secondary {
    float: none;
}
.cart-summary .block > .title {
    padding-left: 0;
}
.cart-summary .block > .title:after {
    right: 3px;
}
.cart-summary .block .fieldset .field {
    margin: 0 0 20px;
    margin: 0 0 10px;
}
.cart-summary .block .fieldset .field:not(.choice) > .label {
    box-sizing: content-box;
    float: none;
    width: auto;
    text-align: left;
    padding: 0;
}
.cart-summary .block .fieldset .field:not(.choice) > .control {
    float: none;
    width: auto;
}
.cart-summary .block .fieldset .field > .label {
    margin: 0 0 8px;
    display: inline-block;
}
.cart-summary .block .fieldset .field.choice:before,
.cart-summary .block .fieldset .field.no-label:before {
    display: none;
}
.cart-summary .checkout-methods-items {
    padding: 0;
}
.cart.table-wrapper {
    margin-bottom: 20px;
}
.cart.table-wrapper .items {
    min-width: 100%;
    width: auto;
}
.cart.table-wrapper tbody td {
    padding-top: 20px;
}
.cart.table-wrapper .item .col.item {
    padding: 20px 8px 20px 0;
}
.cart.table-wrapper .item-actions td {
    padding: 0;
}
.cart.table-wrapper .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding-right: 20px;
    position: static;
    vertical-align: top;
    width: 1%;
}
.cart.table-wrapper .product-item-details {
    padding-bottom: 35px;
}
.cart.table-wrapper .product-item-details {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    width: 99%;
}
.cart-discount {
    width: 75%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    border: 0;
    box-sizing: border-box;
    padding-right: 4%;
}
.cart-discount .block .title:after {
    display: inline;
    margin-left: 10px;
    position: static;
}
.block.crosssell {
    width: 75%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding: 0 4% 0 0;
}
.block.crosssell .products-grid .product-item {
    width: 25%;
}





.multicheckout .action.primary,
.order-review-form .action.primary,
.bundle-actions .action.primary.customize,
.bundle-options-container .block-bundle-summary .box-tocart .action.primary,
.box-tocart .action.tocart,
.cart-summary .checkout-methods-items .action.primary.checkout,
.block-minicart .block-content > .actions > .primary .action.primary,
.methods-shipping .actions-toolbar .action.primary,
.block-authentication .action.action-register,
.block-authentication .action.action-login,
.checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary,
.form-address-edit .actions-toolbar .action.primary {
    line-height: 2.2rem;
    padding: 14px 17px;
    font-size: 1.2rem;
}
.multicheckout .item-options dt,
.cart.table-wrapper .item-options dt,
.block-giftregistry-shared-items .product .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0;
}
.multicheckout .item-options dt:after,
.cart.table-wrapper .item-options dt:after,
.block-giftregistry-shared-items .product .item-options dt:after {
    content: ': ';
}
.multicheckout .item-options dd,
.cart.table-wrapper .item-options dd,
.block-giftregistry-shared-items .product .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px;
}






.page-product-giftcard .product-info-main .price-box .price-container.price-final {
    font-size: 21px;
}
.page-product-giftcard .product-info-main .price-box .price-container.price-final .price {
    font-size: 36px;
    line-height: 36px;
}
.page-product-giftcard .product-info-main .product-info-stock-sku {
    padding-bottom: 0;
}
.page-product-giftcard .product-info-main .product-reviews-summary {
    float: none;
}
.page-product-giftcard .product-add-form {
    margin-top: -20px;
}
.page-product-giftcard .product-add-form .field:not(.text) {
    margin: 0 0 20px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    vertical-align: top;
}
.page-product-giftcard .product-add-form .field:not(.text) > .label {
    margin: 0 0 8px;
    display: inline-block;
}
.page-product-giftcard .product-add-form .field:not(.text):last-child {
    margin-bottom: 0;
}
.page-product-giftcard .product-add-form .field:not(.text) + .fieldset {
    clear: both;
}
.page-product-giftcard .product-add-form .field:not(.text) > .label {
    font-weight: 600;
}
.page-product-giftcard .product-add-form .field:not(.text) > .label + br {
    display: none;
}
.page-product-giftcard .product-add-form .field:not(.text) .choice input {
    vertical-align: top;
}
.page-product-giftcard .product-add-form .field:not(.text) .fields.group:before,
.page-product-giftcard .product-add-form .field:not(.text) .fields.group:after {
    content: '';
    display: table;
}
.page-product-giftcard .product-add-form .field:not(.text) .fields.group:after {
    clear: both;
}
.page-product-giftcard .product-add-form .field:not(.text) .fields.group .field {
    box-sizing: border-box;
    float: left;
}
.page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-2 .field {
    width: 50% !important;
}
.page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-3 .field {
    width: 33.3% !important;
}
.page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-4 .field {
    width: 25% !important;
}
.page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-5 .field {
    width: 20% !important;
}
.page-product-giftcard .product-add-form .field:not(.text) .addon {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 0;
    width: 100%;
}
.page-product-giftcard .product-add-form .field:not(.text) .addon textarea,
.page-product-giftcard .product-add-form .field:not(.text) .addon select,
.page-product-giftcard .product-add-form .field:not(.text) .addon input {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    display: inline-block;
    margin: 0;
    width: auto;
}
.page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore,
.page-product-giftcard .product-add-form .field:not(.text) .addon .addafter {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}
.page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore:disabled,
.page-product-giftcard .product-add-form .field:not(.text) .addon .addafter:disabled {
    opacity: 0.5;
}
.page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore::-moz-placeholder,
.page-product-giftcard .product-add-form .field:not(.text) .addon .addafter::-moz-placeholder {
    color: #c2c2c2;
}
.page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore::-webkit-input-placeholder,
.page-product-giftcard .product-add-form .field:not(.text) .addon .addafter::-webkit-input-placeholder {
    color: #c2c2c2;
}
.page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore:-ms-input-placeholder,
.page-product-giftcard .product-add-form .field:not(.text) .addon .addafter:-ms-input-placeholder {
    color: #c2c2c2;
}
.page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore {
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
}
.page-product-giftcard .product-add-form .field:not(.text) .additional {
    margin-top: 10px;
}
.page-product-giftcard .product-add-form .field:not(.text).required > .label:after,
.page-product-giftcard .product-add-form .field:not(.text)._required > .label:after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem;
    margin: 0 0 0 5px;
}
.page-product-giftcard .product-add-form .field:not(.text) .note {
    font-size: 1.2rem;
    margin: 3px 0 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
}
.page-product-giftcard .product-add-form .field:not(.text) .note:before {
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    line-height: 12px;
    /*font-family: 'luma-icons';*/
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.page-product-giftcard .product-add-form .field:not(.text):nth-child(odd) {
    padding-right: 25px;
}
.page-product-giftcard .product-add-form .field:not(.text):nth-child(even) {
    padding-right: 0;
}
.page-product-giftcard .product-add-form .product-options-wrapper .field:not(.date) > .control {
    width: auto;
}
.gift-message .field {
    margin: 0 0 20px;
    box-sizing: border-box;
}
.gift-message .field > .label {
    margin: 0;
}
.gift-message .field:before,
.gift-message .field:after {
    content: '';
    display: table;
}
.gift-message .field:after {
    clear: both;
}
.gift-message .field.choice:before,
.gift-message .field.no-label:before {
    padding: 6px 15px 0 0;
    width: 25.8%;
    box-sizing: border-box;
    content: ' ';
    float: left;
    height: 1px;
}
.gift-message .field .description {
    padding: 6px 15px 0 0;
    text-align: left;
    width: 25.8%;
    box-sizing: border-box;
    float: left;
}
.gift-message .field:not(.choice) > .label {
    padding: 6px 15px 0 0;
    text-align: left;
    width: 25.8%;
    box-sizing: border-box;
    float: left;
}
.gift-message .field:not(.choice) > .control {
    width: 74.2%;
    float: left;
}
.gift-options {
    position: relative;
    z-index: 1;
}
.gift-options .actions-toolbar {
    clear: both;
    padding: 0;
    position: static;
}
.gift-options .actions-toolbar .secondary {
    float: right;
}
.gift-options .actions-toolbar .secondary .action {
    float: right;
    margin-left: 20px;
    margin-right: 0;
}
.gift-options .actions-toolbar .secondary .action-cancel {
    display: block;
    float: left;
    margin-top: 6px;
}
.gift-options .actions-toolbar:nth-child(3):before {
    border-left: 1px solid #c1c1c1;
    bottom: 5rem;
    content: '';
    display: block;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 0;
}
.gift-options-title {
    font-weight: 300;
    font-size: 1.2rem;
}
.gift-item-block .title {
    padding: 20px 0;
}
.item-gift td {
    padding-left: 0;
    padding-right: 0;
}
.cart.table-wrapper .action-gift {
    float: left;
    display: inline-block;
    text-decoration: none;
}
.cart.table-wrapper .action-gift:after {
    -webkit-font-smoothing: antialiased;
    font-size: 1.2rem;
    line-height: 20px;
    color: #8f8f8f;
    content: '\25B2';
    /*font-family: 'luma-icons';*/
    margin: 0 0 0 10px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.cart.table-wrapper .action-gift:hover:after {
    color: #333333;
}
.cart.table-wrapper .action-gift:active:after {
    color: #8f8f8f;
}
.cart.table-wrapper .action-gift._active:after {
    content: '\25bc';
}
.cart.table-wrapper .gift-options .actions-toolbar {
    clear: both;
    padding: 0;
    position: static;
}
.cart-container .cart-gift-item {
    margin-bottom: 20px;
}
.cart-container .cart-gift-item .gift-options,
.cart-container .cart-gift-item .gift-summary {
    padding-bottom: 20px;
}




.bundle-options-container .block-bundle-summary .bundle.items,
.product-items,
.prices-tier,
.search-terms,
.cart-summary .checkout-methods-items,
.opc-wrapper .opc,
.opc-progress-bar,
.checkout-agreements-items,
.order-details-items .items-qty,
.footer.content ul,
.block-banners .banner-items,
.block-banners-inline .banner-items,
.block-event .slider-panel .slider {
    margin: 0;
    padding: 0;
    list-style: none none;
}



#shopping-cart-table .table-caption {
    display: none;
}
#shopping-cart-table .col {
    text-align: right;
}
#shopping-cart-table .col.item {
    text-align: left;
}

.cart.main.actions {
    .action.continue {
        margin-right: 10px;
    }
    .update_cart_action {
        margin-right: 8px;
    }
}


.cart.table-wrapper .col.qty .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}




.abs-shopping-cart-items-desktop,
.block-cart-failed,
.cart-container .form-cart,
.cart-container .cart-gift-item {
    width: 75%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding-right: 4%;
    position: relative;
}
.abs-discount-block-desktop .block > .title,
.paypal-review-discount .block > .title,
.cart-discount .block > .title {
    border: 0;
    padding: 0 0 10px;
}
.abs-discount-block-desktop .block > .title strong,
.paypal-review-discount .block > .title strong,
.cart-discount .block > .title strong {
    font-size: 1rem;
}
.abs-discount-block-desktop .block .content,
.paypal-review-discount .block .content,
.cart-discount .block .content {
    padding: 0 0 20px;
}
.abs-discount-block-desktop .actions-toolbar .secondary,
.paypal-review-discount .actions-toolbar .secondary,
.cart-discount .actions-toolbar .secondary {
    bottom: -30px;
    left: 0;
    position: absolute;
}





.abs-cart-block > .title,
.minicart-items .product .toggle,
.abs-discount-block .block > .title,
.cart-summary .block > .title,
.paypal-review-discount .block > .title,
.cart-discount .block > .title {
    border-top: 1px solid #cccccc;
    cursor: pointer;
    margin-bottom: 0;
    position: relative;
    padding: 10px 40px 10px 15px;
    display: block;
    text-decoration: none;
}
.abs-cart-block > .title:after,
.minicart-items .product .toggle:after,
.abs-discount-block .block > .title:after,
.cart-summary .block > .title:after,
.paypal-review-discount .block > .title:after,
.cart-discount .block > .title:after {
    position: absolute;
    right: 20px;
    top: 10px;
}
.abs-toggling-title > span,
.abs-cart-block > .title > span,
.minicart-items .product .toggle > span,
.abs-discount-block .block > .title > span,
.cart-summary .block > .title > span,
.paypal-review-discount .block > .title > span,
.cart-discount .block > .title > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-toggling-title:after,
.abs-cart-block > .title:after,
.minicart-items .product .toggle:after,
.abs-discount-block .block > .title:after,
.cart-summary .block > .title:after,
.paypal-review-discount .block > .title:after,
.cart-discount .block > .title:after {
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    line-height: 12px;
    color: inherit;
    content: '\25BC';
    /*font-family: 'luma-icons';*/
    margin: 7px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-discount-block .block,
.cart-summary .block,
.paypal-review-discount .block,
.cart-discount .block {
    margin: 0;
}
.abs-discount-block .block > .title:after,
.cart-summary .block > .title:after,
.paypal-review-discount .block > .title:after,
.cart-discount .block > .title:after {
    color: #858585;
}
.abs-discount-block .block .content,
.cart-summary .block .content,
.paypal-review-discount .block .content,
.cart-discount .block .content {
    display: none;
    padding: 5px 15px 23px;
    position: relative;
}
.abs-cart-block.active > .title:after,
.abs-discount-block .block.active > .title:after,
.cart-summary .block.active > .title:after,
.paypal-review-discount .block.active > .title:after,
.cart-discount .block.active > .title:after {
    content: '\25B2';
}
.abs-discount-block .block.active .content,
.cart-summary .block.active .content,
.paypal-review-discount .block.active .content,
.cart-discount .block.active .content {
    display: block;
}
.multicheckout .table-wrapper .cart-price {
    color: #7d7d7d;
    font-size: 1rem;
    font-weight: 700;
}
.multicheckout .table-wrapper .product-item-name {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0;
}
.abs-account-blocks .block-title,
.multicheckout .block-title,
.multicheckout .block-content .title,
.account .legend,
.form-giftregistry-search .legend,
.block-giftregistry-results .block-title,
.block-giftregistry-shared-items .block-title,
.block-wishlist-search-form .block-title,
.block-wishlist-search-results .block-title,
.customer-review .review-details .title,
.multicheckout .block-title,
.paypal-review .block .block-title,
.account .column.main .block:not(.widget) .block-title,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title,
.sales-guest-view .column.main .block:not(.widget) .block-title {
    border-bottom: 1px solid #c6c6c6;
    margin-bottom: 25px;
    padding-bottom: 10px;
}
.abs-account-blocks .block-title > strong,
.abs-account-blocks .block-title > span,
.multicheckout .block-title > strong,
.multicheckout .block-title > span,
.multicheckout .block-content .title > strong,
.multicheckout .block-content .title > span,
.account .legend > strong,
.account .legend > span,
.form-giftregistry-search .legend > strong,
.form-giftregistry-search .legend > span,
.block-giftregistry-results .block-title > strong,
.block-giftregistry-results .block-title > span,
.block-giftregistry-shared-items .block-title > strong,
.block-giftregistry-shared-items .block-title > span,
.block-wishlist-search-form .block-title > strong,
.block-wishlist-search-form .block-title > span,
.block-wishlist-search-results .block-title > strong,
.block-wishlist-search-results .block-title > span,
.customer-review .review-details .title > strong,
.customer-review .review-details .title > span,
.multicheckout .block-title > strong,
.multicheckout .block-title > span,
.paypal-review .block .block-title > strong,
.paypal-review .block .block-title > span,
.account .column.main .block:not(.widget) .block-title > strong,
.account .column.main .block:not(.widget) .block-title > span,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title > strong,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title > span,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > span,
.sales-guest-view .column.main .block:not(.widget) .block-title > strong,
.sales-guest-view .column.main .block:not(.widget) .block-title > span {
    font-size: 2.2rem;
    font-weight: 300;
}
.block-addresses-list address,
.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-information .box-content,
.box-newsletter .box-content {
    line-height: 24px;
}
.order-details-items tfoot td {
    background: #f5f5f5;
}
.paypal-review .table-paypal-review-items .col.price .price-including-tax,
.paypal-review .table-paypal-review-items .col.price .price-excluding-tax,
.paypal-review .table-paypal-review-items .col.subtotal .price-including-tax,
.paypal-review .table-paypal-review-items .col.subtotal .price-excluding-tax,
.cart.table-wrapper .col.price .price-including-tax,
.cart.table-wrapper .col.price .price-excluding-tax,
.cart.table-wrapper .col.subtotal .price-including-tax,
.cart.table-wrapper .col.subtotal .price-excluding-tax,
.cart.table-wrapper .col.msrp .price-including-tax,
.cart.table-wrapper .col.msrp .price-excluding-tax,
.order-details-items .col.price .price-including-tax,
.order-details-items .col.price .price-excluding-tax,
.order-details-items .col.subtotal .price-including-tax,
.order-details-items .col.subtotal .price-excluding-tax {
    color: #666666;
    font-size: 1.2rem;
    display: block;
    line-height: 1;
    white-space: nowrap;
}
span.cart-price {
    font-size: 1rem;
}
.paypal-review .table-paypal-review-items .col.price .price-including-tax .price,
.paypal-review .table-paypal-review-items .col.price .price-excluding-tax .price,
.paypal-review .table-paypal-review-items .col.subtotal .price-including-tax .price,
.paypal-review .table-paypal-review-items .col.subtotal .price-excluding-tax .price,
.cart.table-wrapper .col.price .price-including-tax .price,
.cart.table-wrapper .col.price .price-excluding-tax .price,
.cart.table-wrapper .col.subtotal .price-including-tax .price,
.cart.table-wrapper .col.subtotal .price-excluding-tax .price,
.cart.table-wrapper .col.msrp .price-including-tax .price,
.cart.table-wrapper .col.msrp .price-excluding-tax .price,
.order-details-items .col.price .price-including-tax .price,
.order-details-items .col.price .price-excluding-tax .price,
.order-details-items .col.subtotal .price-including-tax .price,
.order-details-items .col.subtotal .price-excluding-tax .price {
    font-weight: 700;
}
.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-including-tax,
.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-excluding-tax,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-including-tax,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-excluding-tax,
.cart-summary .price-including-tax,
.cart-summary .price-excluding-tax,
.gift-summary .price-including-tax,
.gift-summary .price-excluding-tax,
.gift-options .price-including-tax,
.gift-options .price-excluding-tax {
    font-size: 1.4rem;
    display: inline-block;
}
.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-including-tax + .price-excluding-tax,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-including-tax + .price-excluding-tax,
.cart-summary .price-including-tax + .price-excluding-tax,
.gift-summary .price-including-tax + .price-excluding-tax,
.gift-options .price-including-tax + .price-excluding-tax {
    font-size: 1.1rem;
    display: inline-block;
}
.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-including-tax + .price-excluding-tax:before,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-including-tax + .price-excluding-tax:before,
.cart-summary .price-including-tax + .price-excluding-tax:before,
.gift-summary .price-including-tax + .price-excluding-tax:before,
.gift-options .price-including-tax + .price-excluding-tax:before {
    content: '(' attr(data-label) ': ';
}
.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-including-tax + .price-excluding-tax:after,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-including-tax + .price-excluding-tax:after,
.cart-summary .price-including-tax + .price-excluding-tax:after,
.gift-summary .price-including-tax + .price-excluding-tax:after,
.gift-options .price-including-tax + .price-excluding-tax:after {
    content: ')';
}
.price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total,
.paypal-review .table-paypal-review-items .cart-tax-total,
.cart.table-wrapper .cart-tax-total,
.order-details-items .cart-tax-total {
    cursor: pointer;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    text-decoration: none;
}
.abs-tax-total:after,
.price-including-tax .cart-tax-total:after,
.price-excluding-tax .cart-tax-total:after,
.paypal-review .table-paypal-review-items .cart-tax-total:after,
.cart.table-wrapper .cart-tax-total:after,
.order-details-items .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    font-size: 8px;
    line-height: 8px;
    color: inherit;
    content: '\25B2';
    /*font-family: 'luma-icons';*/
    margin: 3px 0 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.price-including-tax .cart-tax-total:after,
.price-excluding-tax .cart-tax-total:after,
.paypal-review .table-paypal-review-items .cart-tax-total:after,
.cart.table-wrapper .cart-tax-total:after,
.order-details-items .cart-tax-total:after {
    position: absolute;
    right: -2px;
    top: 4px;
}
.abs-tax-total-expanded:after,
.price-including-tax .cart-tax-total-expanded:after,
.price-excluding-tax .cart-tax-total-expanded:after,
.paypal-review .table-paypal-review-items .cart-tax-total-expanded:after,
.cart.table-wrapper .cart-tax-total-expanded:after,
.order-details-items .cart-tax-total-expanded:after {
    content: '\25B2';
}
.giftcard-account .form-giftcard-redeem .fieldset,
.form-giftregistry-create .fieldset {
    margin-bottom: 20px;
}



#block-shipping .title {
    border-top: 0;
}

.checkout-cart-index .page-main {
    padding-left: 0;
    padding-right: 0;
}
.checkout-cart-index .page-title-wrapper {
    padding-left: 0;
    padding-right: 0;
}
.cart-summary {
    background: #f5f5f5;
    margin-bottom: 25px;
    padding: 1px 10px 25px;
}
.cart-summary > .title {
    font-size: 2.4rem;
    display: none;
    font-weight: 300;
    margin: 12px 0;
}
.cart-summary .block form:not(:last-of-type) .fieldset {
    margin: 0 0 25px;
}
.cart-summary .block .price {
    font-weight: normal;
}
.cart-summary .block .field {
    margin: 0 0 16px;
}
.cart-summary .block .field.note {
    display: none;
}
.cart-summary .block .actions-toolbar > .primary {
    text-align: left;
}
.cart-summary .block .actions-toolbar > .primary .action.primary {
    width: auto;
}
.cart-summary .block .title strong {
    font-size: 1rem;
    font-weight: 600;
}
.cart-summary .block .item-options {
    margin: 0 0 16px;
}
.cart-summary .block .item-options .field .radio {
    float: left;
}
.cart-summary .block .item-options .field .radio + .label {
    display: block;
    margin: 0;
    overflow: hidden;
}
.page-main .cart-summary .block {
    margin-bottom: 0;
}
.cart-summary .checkout-methods-items {
    margin: 20px 0 0;
    padding: 0 15px;
    text-align: center;
}
.cart-summary .checkout-methods-items .action.primary.checkout {
    width: 100%;
    background:$black;
    color:$white;
    border:2px $black solid;
    outline:none;
    transition:all $ms ease-in-out;
    font-family:$font_body;
    font-size:em(16,$fs);
    text-transform:uppercase;
    font-weight:600;
    padding:em(12,16) em(15,16);

    &:hover{
        background:transparent;
        color:$black;
    }
}
.cart-summary .checkout-methods-items .item {
    margin-bottom: 25px;
}
.cart-summary .checkout-methods-items .item:last-child {
    margin-bottom: 0;
}
.cart-summary .message {
    padding-left: 20px;
}
.cart-summary .message > *:first-child:before {
    display: none;
}
#cart-totals {
    width: 90%;
    margin: 0 auto;
}
.cart-totals tbody .mark,
.cart-totals tfoot .mark {
    text-align: left;
}
.cart.table-wrapper .cart thead tr th.col {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 15px;
    padding-top: 24px;
}
.cart.table-wrapper .cart tbody td {
    border: 0;
}
.cart.table-wrapper .cart > .item {
    border-bottom: 1px solid #cccccc;
    position: relative;
}
.cart.table-wrapper .col {
    padding-top: 15px;
}
.cart.table-wrapper .col.price,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp {
    padding: 10px 11px 10px;
    text-align: center;
    vertical-align: top;
}
.cart.table-wrapper .col.qty {
    padding: 15px 11px 10px;
    text-align: center;
    vertical-align: top;
}
.cart.table-wrapper .col.qty .input-text {
    height: 36px;
    margin-top: -7px;
    text-align: center;
    width: 45px;
}
.cart.table-wrapper .col > .price {
    color: #7d7d7d;
    font-size: 1.2rem;
    font-weight: 700;
}
.cart.table-wrapper .item-actions td {
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    white-space: normal;
}
.cart.table-wrapper .item .col.item {
    display: block;
    min-height: 75px;
    padding: 15px 15px 10px 90px;
    position: relative;
}
.cart.table-wrapper .actions-toolbar {
    min-height: 32px;
    padding-bottom: 15px;
    position: relative;
}
.cart.table-wrapper .actions-toolbar > .action-edit,
.cart.table-wrapper .actions-toolbar > .action-delete {
    position: absolute;
    right: 16px;
    top: 0;
    display: inline-block;
    text-decoration: none;
}
.cart.table-wrapper .actions-toolbar > .action-edit > span,
.cart.table-wrapper .actions-toolbar > .action-delete > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.cart.table-wrapper .actions-toolbar > .action-edit:before,
.cart.table-wrapper .actions-toolbar > .action-delete:before {
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    line-height: 20px;
    color: #8f8f8f;
    content: '';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.cart.table-wrapper .actions-toolbar > .action-edit:hover:before,
.cart.table-wrapper .actions-toolbar > .action-delete:hover:before {
    color: #333333;
}
.cart.table-wrapper .actions-toolbar > .action-edit:active:before,
.cart.table-wrapper .actions-toolbar > .action-delete:active:before {
    color: #8f8f8f;
}
.cart.table-wrapper .actions-toolbar > .action-delete {
    right: 0;
}
.cart.table-wrapper .actions-toolbar > .action-delete:before {
    content: '';
}
.cart.table-wrapper .action {
    margin-right: 25px;
}
.cart.table-wrapper .action:last-child {
    margin-right: 0;
}
.cart.table-wrapper .action.help.map {
    font-weight: 400;
}
.cart.table-wrapper .product-item-photo {
    display: block;
    left: 15px;
    max-width: 65px;
    padding: 0;
    position: absolute;
    top: 15px;
    width: 100%;
}
.cart.table-wrapper .product-item-name {
    font-size: 1.2rem;
    display: block;
    margin: -3px 0 5px;
}
.cart.table-wrapper .gift-registry-name-label:after {
    content: ':';
}
.cart.table-wrapper .item-options {
    margin-bottom: 0;
}
.cart.table-wrapper .product-item-name + .item-options {
    margin-top: 20px;
}
.cart.table-wrapper .action.configure {
    display: inline-block;
    margin: 10px 0 0;
}
.cart.table-wrapper .item .message {
    margin-top: 20px;
}
.cart-discount {
    border-bottom: 1px solid #cccccc;
    clear: left;
}
.cart-empty {
    padding-left: 15px;
    padding-right: 15px;
}
.cart-tax-info + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
    display: block;
}
.cart.table-wrapper .col.price,
.order-items.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.order-items.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.msrp {
    text-align: right;
}
.block.crosssell {
    padding: 0 15px;
    margin-top: 70px;
}
.block.crosssell .product-item-info {
    width: 200px;
}

#shipping-zip-form {
    legend {
        font-size: 1rem;
    }
    select, input[type=text] {
        padding: 5px 3px;
    }
}


.items.methods,
.items.methods dt,
.items.methods dd {
    font-size: 0.9rem;
}
.data.table.totals {
    width: 100%;

    .grand.totals {
        .mark, .amount {
            font-size: 1.2rem;
        }
    }

    .table-caption {
        display: none;
    }

    tr td {
        text-align: right;
    }
}