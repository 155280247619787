.product-top{
    @include afterclear;
}

.product.media{
    margin:0 0 em(30,$fs);

    .loading-mask{
        position: relative;
    }

    .loader{
        position: absolute;
        top:50%;
        left:50%;
        width:64px;
        height:64px;
        transform:translateX(-50%) translateY(-50%);
    }

    .gallery-placeholder{
        border:1px $grey solid;
    }
}

.fotorama__active .fotorama__dot{
    background-color:$black !important;
    border-color:$black !important;
}

.fotorama,
.fotorama__nav{
    overflow:visible !important;
}

.fotorama__nav-wrap{
    position: absolute;
    top:2px !important;
    left:2px !important;
}

.fotorama__stage{
    left:0 !important;


    &:before,
    &:after{
        display:none !important;
    }
}

.fotorama__stage{
    max-height:700px !important;
}

.fotorama__stage__shaft{
    width:100% !important;
    max-width:100% !important;
}

.fotorama__nav__frame--thumb{
    padding:0 !important;
    border:1px $white solid;
    transition:all $ms ease-in-out;

    + .fotorama__nav__frame--thumb{
        margin:5px 0 0;
    }

    &:hover{
        cursor:pointer;
    }

    &:before{
        content:"";
        width:2px;
        height:90%;
        display:block;
        position: absolute;
        top:50%;
        right:0;
        background:$black;
        z-index:10;
        transition:all $ms ease-in-out;
        opacity:0;
        transform:translateX(calc(100% + 3px)) translateY(-50%);
    }

    &.fotorama__active{
        border:1px $grey solid;

        &:before{
            height:calc(100% + 2px);
            opacity:1;
        }
    }
}

.fotorama__thumb-border{
    margin:0 !important;
}

.fotorama__thumb-border{
    display:none;
}

.fotorama__caption{
    display:none;
}

.product-info-main{
    .page-title-wrapper{
        margin:0;
        .product-name {
            font-weight: 600;
        }
        .product-sku {
            font-weight: 600;
        }
    }

    .page-title{
        font-size:em(30,$fs);
    }
}

.catalog-product-view{
    .columns{
        margin-top:em(15,$fs) !important;
    }
}

.product-title-badge{
    display:block;
    color:$greydark;
    font-family:$font_body;
    font-weight:700;
    font-size:em(14,30);
}

.product-info-price{
    font-size:em(12,$fs);
    font-weight:700;

    .price-label{
        display:none;
    }
}

.product-social-links{
    @include afterclear;
    margin:em(25,$fs) 0 0;

    .towishlist{
        height:32px;
        line-height:32px;
    }
}

.product-attributes-options {
    display: inline-block;
    width: 45%;
    float: none;
    margin: 20px 0 0;
    @media(max-width: $laptop) {
        display: block;
        width: 100%;
        margin: 10px 0;
    }

    &.lightkit-options {
        @media(max-width: $laptop) {
            margin-left: 0;
        }
    }
}

.product-custom-actions{
    @include afterclear;
    width:100%;
    list-style:none;
    margin:0;
    padding:0;
    font-size:em(14,$fs);

    > li{
        float:left;
        width:49.5%;

        + li{
            margin-left:1%;
        }
    }

    a{
        width:100%;
        text-align:center;
    }
}

.towishlist{
    display:inline-block;
    font-size:em(12,$fs);
    padding-right:em(25,12);
    position: relative;

    &:before,
    &:after{
        content:"\e802";
        font-size:16.8px;
        line-height:16.8px;
        font-family:$icon;
        display:block;
        position: absolute;
        top:50%;
        right:0;
        font-weight:normal;
        transform:translateY(-50%);
    }

    &:before{
        content:"\e802";
    }

    &:after{
        content:"\e803";
        opacity:0;
        transition:all $ms ease-in-out;
    }

    &:hover{
        color:$black;

        &:before,
        &:after{
            color:$black;
            opacity:1;
        }
    }
}

.product.info{
    margin:em(25,$fs) 0 0;
    padding:em(25,$fs) 0 0;
    border-top:1px $greydark solid;
}

.product.attribute.description{
    @include headings{
        font-family:$font_body;
        font-weight:700;
        font-size:1em;
    }
}

.additional-attributes-wrapper{
    margin:em(25,$fs) 0 0;
}

.attributes-list{
    list-style:none;
    margin:0;
    padding:0;

    strong{
        font-size:em(15,$fs);
        font-weight:600;
        letter-spacing:1px;
        text-transform:uppercase;
    }

    li{
        + li{
            margin:em(3,$fs) 0 0;
        }
    }
}

.product-attributes-footer{
    @include afterclear;
    margin:em(50,$fs) 0 0;

    .product-list-item-finishes{
        li{
            width:35px;
            height:35px;
            margin:0 .5em .5em 0;
        }
    }

    .product-list-item-badges{
        li{
            width:35px;
            height:35px;

            + li{
                margin:0 0 0 em(10,$fs);
            }
        }
    }

    .product-list-item-lightkit {
        list-style: none;
        padding: 0;

        li {
            display: inline-block;

            a {
                font-weight: normal;
            }
        }
    }
}

.product-attributes-options{
    &.blade-options{
        display: block;
        width: 100%;
    }

    @include headings{
        font-family:$font_body;
        font-size:em(15,$fs);
        font-weight:600;
        letter-spacing:1px;
        text-transform:uppercase;
        margin:0 0 em(10,15);
    }
}

.product{
    .share-this{
        float:right;
        margin:em(10,$fs) 0 0;
    }
}

.product.tabs{
    margin:em(45,$fs) 0;
    border-top:1px $greydark solid;
    position: relative;
}

.tabs-container{
    position: relative;

    > input{
        position: absolute;
        top:0;
        left:0;
        width:1px;
        height:1px;
        overflow:hidden;
        pointer-events:none;
        opacity:.01;
    }
    .tabs-nav{
        @include afterclear;
        list-style:none;
        margin:0;
        padding:0;
        position: absolute;
        top:0;
        left:0;
        transform:translateY(-100%);

        li{
            float:left;

            + li{
                margin:0 0 0 em(35,$fs);
            }
        }

        label{
            display:block;
            font-weight:600;
            font-size:em(14,$fs);
            line-height:em(25,14);
            position: relative;

            &:before{
                content:"";
                width:90%;
                height:2px;
                background:$black;
                position: absolute;
                bottom:0;
                left:50%;
                opacity:0;
                transform:translateX(-50%);
                transition:all $ms ease-in-out;
            }

            &:hover{
                cursor:pointer;
            }
        }
    }

    .tab{
        @include afterclear;
        display:none;
        padding:em(20,$fs) 0 0;
    }

    .warning-information {
        clear: both;
        padding: 40px 20px;

        .title {
            text-align: center;
        }
        .info {
            .warning-icon {
                display: inline-block;
                height: 26px;
                width: 30px;
                background-image: url(../images/warning.svg);
                background-size: cover;
            }
        }
    }
}

.attribute-content-area{
    strong{
        font-size:em(15,$fs);
        font-weight:600;
        letter-spacing:1px;
        text-transform:uppercase;
    }
    ul{
        list-style:none;
        margin:0;
        padding:0;
        font-size:em(14,$fs);

        li{
            margin:em(10,14) 0 0;
            position: relative;
            padding-left:em(10,14);

            &:before{
                content:"";
                width:3px;
                height:3px;
                border-radius:50%;
                display:block;
                background:$black;
                position: absolute;
                left:0;
                top:em(12,14);
                transform:translateY(-50%);
            }
        }
    }
}

.tab[data-tab="tab-overview"]{
    .attributes-list{
        + .attributes-list{
            margin:percentage(85/$inside_width_num) 0 0;
        }
    }

    .attribute-content-area{
        margin:percentage(85/$inside_width_num) 0 0;
    }
}

#tab-overview:checked{
    ~ .tabs-nav label[for="tab-overview"]{
        &:before{
            width:100%;
            opacity:1;
        }
    }
    ~ .tab[data-tab="tab-overview"]{
        display:block;
    }
}

#tab-details:checked{
    ~ .tabs-nav label[for="tab-details"]{
        &:before{
            width:100%;
            opacity:1;
        }
    }
    ~ .tab[data-tab="tab-details"]{
        display:block;
    }
}

#tab-resources:checked{
    ~ .tabs-nav label[for="tab-resources"]{
        &:before{
            width:100%;
            opacity:1;
        }
    }
    ~ .tab[data-tab="tab-resources"]{
        display:block;
    }
}

ul.link-list{
    list-style:none;
    margin:0;
    padding:0;

    li{
        + li{
            margin:em(10,$fs) 0 0;
        }
    }

    a{
        display:inline-block;
        font-weight:normal;
        position: relative;
        padding-left:em(25,$fs);

        &:before{
            content:"\e80c";
            font-family:$icon;
            position: absolute;
            top:50%;
            left:0;
            transform:translateY(-50%);
        }

        &[href$=".pdf"]{
            &:before{
                // Previous icon for not generated pdf files
                //content:"\f1c1";
            }
        }
    }
}

.block.related{
    margin:em(30,$fs) 0 0;
    padding:em(30,$fs) 0 0;
    border-top:1px $greydark solid;

    .block-title{
        font-size:em(24,$fs);
        font-family:$font_body;
        text-transform:uppercase;

        strong{
            font-weight:normal;
        }
    }
}

.product.tabs{
    + .related-families{
        margin:em(30,$fs) 0 0;
        padding:em(30,$fs) 0 0;
        border-top:1px $greydark solid;
    }
}

.block.upsell {
    margin: 2rem 0;
}

.block.related.custom {
    margin: 0;
    padding: 0;
    border-top: 0px #aaabac solid;
}


// Fan products only
.catalog-product-view {
    &.fan-product-page {
        .tab {
            .attrs-columns {
                display: flex;
                flex-direction: row;
                @media(max-width: $tablet) {
                    flex-direction: column;
                }

                ul.attributes-list {
                    width: calc(100% / 4);
                    @media(max-width: $tablet) {
                        width: 100%;
                    }
                }

                div.attributes-list {
                    width: calc(100% / 2);

                    ul {
                        font-size: 14px;
                    }
                }

                .attributes-list {
                    margin: 0 20px;
                    @media(max-width: $laptop) {
                        margin: 5px 0;
                    }
                    &:first-child {
                        margin: 0;
                    }

                    li {
                        strong, span {
                            font-size: 15px;
                            @media(max-width: $laptop) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        table.performance-specification {
            font-size: 14px;
            font-weight: normal;
            width: 100%;
            color: #111;

            th {
                font-family: "HL-Raleway", sans-serif;
                text-transform: uppercase;
                padding: 5px;
                vertical-align: middle;
                border: 1px solid #444;

                &.headline {
                    font-family: "Oswald", sans-serif;
                    font-size: 18px;
                    font-weight: bold;
                }

                &.th-speed {
                    font-weight: normal;
                }
            }

            td {
                font-family: "HL-Raleway", sans-serif;
                padding: 5px 5px 5px 8px;
                border: 1px solid #444;

                &:first-child {
                    font-weight: bold;
                }
            }
        }
    }
}
