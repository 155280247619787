body.page-layout-showroom-page .main-wrap{
    .columns{
        margin:em(5,$fs) 0 0;
    }
}

.showroom-info{
    @include afterclear;
    padding-bottom:em(23,$fs);
    //border-bottom:1px $greydark solid;

    .showroom-logo{
        border:1px $grey solid;
        margin:0 0 em(15,$fs);

        img{
            display:none;
        }

        h6{
            display:block;
            font-family:$font_body;
            font-size:em(14,$fs);
            padding:em(8,14);
            margin:0;
            position: relative;

            &[class*="badge-"]{
                padding-left:em(45,14);

                &:before{
                    content:"";
                    width:em(32,14);
                    height:100%;
                    background-repeat:no-repeat;
                    background-position:center center;
                    background-size:cover;
                    position: absolute;
                    top:0;
                    left:0;
                }
            }

            &.badge-premier-partner:before{
                background-image:url(../images/badge-premier-partner.jpg);
            }
        }
    }
}

.showroom-map{
    height:210px;
    background:$gray;
    margin:em(-30,$fs) 0 0;
}

.info-right {
    display: inline-block;
    @media (max-width: 1050px) { display: block; margin-top: 20px; }
}

.showroom-content{
    clear:both;
    padding:em(30,$fs) 0;
    border-bottom:1px $greydark solid;
    font-size:em(14,$fs);

    .showroom-logo {
        display: inline-block;
        width: 185px;
        background-color: #f1f0ee;
        font-size: 13px;
        font-weight: 600;
        margin-right: 10px;
    }

    .showroom-icon {
        display: inline-block !important;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 5px !important;
    }

    @include headings{
        font-family:$font_body;
        font-weight:600;
        font-size:em(18,14);
        text-transform:uppercase;
        margin:0 0 em(15,18);

        &.has-hint{
            float:left;
            width:49%;
        }
    }

    .hint{
        float:right;
        width:49%;
        text-align:right;
        font-size:em(12,14);

        &:before{
            margin-right:em(5,12);
        }
    }

    .attributes-list{
        float:left;
        width:49%;
        font-size:em(16,14);

        + .attributes-list{
            margin-left:2%;
        }
    }

    .btn-list li {
        @media (max-width: 550px) {
            display: block;
            clear: both;
            margin: 5px 0 0 !important;
        }
    }
}

.details-list{
    @include afterclear;
    clear:both;
    list-style:none;
    margin:0;
    padding:0;
    font-size:em(12,14);
    font-weight:bold;

    li{
        float:left;
        width:49%;
        position: relative;

        &:nth-child(even){
            margin-left:2%;
        }

        &:nth-child(odd){
            clear:both;
        }

        &:nth-child(n+3){
            margin-top:2%;
        }
    }

    a{
        display:block;
        position: relative;
        background:$greylight;
        border:1px $grey solid;
        padding:em(7,12) em(15,12);

        &:before{
            content:"";
            width:100%;
            height:2px;
            display:block;
            background:$black;
            position: absolute;
            top:0;
            left:0;
            opacity:0;
            transition:all $ms ease-in-out;
        }

        &:hover{
            color:$black;

            &:before{
                opacity:1;
            }
        }
    }

    .tooltip{
        left:5%;
    }
}

.amlocator_left {
    .limited-showrooms-msg {
        padding: 0px 0 5px !important;
        font-size: 12px !important;
        font-weight: bold;
    }
}

// Icon tooltip
.ui-tooltip, .arrow:after {
    background: #eee;
    border: 1px solid #999;
}
.ui-tooltip {
    padding: 10px 20px 7px;
    color: #111;
    border-radius: 20px;
    font: 12px "Helvetica Neue", Sans-Serif;
    /*text-transform: uppercase;*/
    line-height: 14px;
    box-shadow: 0 0 7px rgba(0,0,0,0.5);
    width: 210px;
    text-align: center;
}
.arrow {
    width: 70px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    bottom: -16px;
}
.arrow.top {
    top: -16px;
    bottom: auto;
}
.arrow.left {
    left: 20%;
}
.arrow:after {
    content: "";
    position: absolute;
    left: 20px;
    top: -20px;
    width: 25px;
    height: 25px;
    box-shadow: 6px 5px 9px -9px black;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.arrow.top:after {
    bottom: -20px;
    top: auto;
}