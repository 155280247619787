.products-grid{
    &.subcategories{
        .block-title{
            font-family:$font_body;
            text-align:center;
            text-transform:uppercase;
            font-size:em(24,$fs);
            letter-spacing:em(.80,24);
            margin:0 0 em(7,24);

            span{
                font-family:$icon;
                font-size:em(20,24);
                position: relative;
                top:em(-2,20);
                display:inline-block;
                margin:0 em(2,20);
            }

            *{
                font-weight:normal;
            }
        }
        .product-item-details{
            padding:em(20,$fs);
            border-top:1px $grey solid;
            background:$greylight;
        }
        .product-list-item-footer{
            margin:em(14,14) 0 0;

            .product-list-item-finishes{
                float:left;
                margin:em(6,14) 0 0;
            }
            > a{
                float:right;
            }
        }
    }
    .order-fixer{
        position: absolute;
        top:0;
        left:0;
        z-index:-999;
        pointer-events:none;
        opacity:0;
    }
}

.category-description{
    @include afterclear;
    font-size:em(14,$fs);
    line-height:1.75em;
    padding-bottom:em(30,14);
    margin-bottom:em(55,14);
    position: relative;

    &:before{
        content:"";
        width:100%;
        height:1px;
        background:$greydark;
        display:block;
        position: absolute;
        bottom:0;
        left:0;
    }

    .youtube-link{
        float:right;
        display:block;
        width:287px;
        margin:em(-39,14) 0 em(-30,14) 0;
        border-left:em(33,14) $white solid;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:110% 110%;
        color:$white;
        padding:em(128,14) em(15,14) em(15,14) em(65,14);
        position: relative;
        z-index:1;

        &:before{
            content:"\f144";
            font-family:$icon;
            color:$white;
            font-size:em(45,14);
            line-height:1em;
            margin:0;
            position: absolute;
            bottom:em(15,45);
            left:em(15,45);
            transition:color $ms ease-in-out;
        }

        &:after{
            content:"";
            width:100%;
            height:50%;
            display:block;
            position: absolute;
            bottom:0;
            left:0;
            z-index:-1;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.25+100 */
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#26000000',GradientType=0 ); /* IE6-9 */
        }

        &:hover{
            &:before{
                color:$greydark;
            }
        }
    }
}
.toolbar.top {
    .pages {
        display: none;
    }
}
.toolbar.bottom {
    .modes, .amount, .sorter {
        display: none;
    }
}

.toolbar{
    @include afterclear;
    margin:0 0 em(20,$fs);
}

.toolbar-sorter{
    @include afterclear;
    float:left;

    + .toolbar-sorter{
        margin:0 0 0 em(25,$fs);
    }
}

.sorter{
    @include afterclear;

    .sorter-select{
        position: relative;

        label{
            b{
                left:em(11,14);
            }
        }

        &.open{
            label{
                b{
                    @include fancyArrowClose;
                }
            }
        }
    }

    label{
        font-size:em(14,$fs);
        height:16px;
        line-height:16px;
        font-weight:600;
        font-family:$font_body;
        padding-left:em(20,14);
        display:block;

        &:hover{
            cursor:pointer;
        }

        &:not(.empty){
            position: absolute;
            top:0;
            left:0;
            pointer-events:none;
        }

        b{
            @include fancyArrow;
            pointer-events:none;
            position: absolute;
            top:55%;
            left:0;
            transform:translateY(-50%);
            width:em(15,$fs);
            height:100%;
        }
    }

    select{
        float:left;
        font-size:em(12,$fs);
        font-family:$font_body;
        background:transparent;
        outline:none;
        border:none;
        padding:0;
        margin:0;
        appearance:none;
        padding:0 0 0 em(50,12);

        &:hover{
            cursor:pointer;
        }

        &:focus{
            outline:none;
        }

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
        }
    }

    &.limiter{
        select{
            padding:0 0 0 em(60,12);
        }
    }
}

#product-filters {
    .loading-filters-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 124px;
        font-size: 15px;
        @media(max-width: $tablet) { height: 62px; }

        .loading-img {
            display: inline-block;
            width: 16px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
}

.products-view-all{
    position: relative;
    text-align:center;
    display:block;
    font-size:em(24,$fs);
    font-family:$font_script;
    line-height:1;
    font-style:italic;
    color:$black;
    margin:em(40,24) 0 0;
    padding-bottom:em(25,24);
    transition:all $ms ease-in-out;
    font-weight:normal;

    &:before,
    &:after{
        content:"";
        width:100%;
        height:1px;
        display:block;
        background:$greydark;
        position: absolute;
        bottom:0;
        left:0;
        z-index:1;
    }

    &:after{
        background:$black;
        z-index:2;
        width:0;
        transition:all $ms ease-in-out;
    }

    &:hover{
        color:$black !important;
        text-indent:10px;

        &:after{
            width:100%;
        }
    }
}

.ias-spinner {
    @extend .product-item;
    width: 100% !important;
    margin-top: 40px !important;
    padding-bottom: 25px !important;
    border: 0 !important;
}

.ias-trigger-next,
.ias-trigger-prev {
    @extend .product-item;
    width: 100% !important;
    margin-top: 0 !important;
    border: 0 !important;
    a {
        font-weight: 400;
        text-transform: capitalize;
        &:before {
            display: none;
        }
        &:hover {
            &:after {
                display: none;
            }
        }
    }
}

.loading-products {
    font-size: 1.5em;
    font-family: "Chronicle Disp Cond",sans-serif;
    font-style: italic;
    color: #000;
    line-height: 24px;
}

.products-grid.related-families{
    margin:em(35,$fs) 0 0;
}

.filter-sort-actions{
    @include afterclear;

    &.right{
        .toolbar{
            display:inline-block;

            + .toolbar{
                margin-left:em(30,$fs);
            }
        }
    }
}

.filter-options{
    @include afterclear;
    background:$greylight;
    border:1px $grey solid;
    margin:0 0 em(20,$fs);
}

.filter-segment{
    @include afterclear;
    padding:em(15,$fs);

    @include headings{
        font-weight:600;
        font-size:em(12,$fs);
        font-family:$font_body;
        margin:0 0 em(10,12);
        text-transform: uppercase;
    }

    &.category{
        .filter-group{
            + .filter-group{
                margin:0 0 0 percentage(7.3/822);
            }
        }
        .filter-groups{
            float:none;
            width:100%;
        }
    }

    &.product{
        border:none;
        border-top:1px $grey solid;
    }

    .filter-groups{
        @include afterclear;
        float:left;
        width:100%;
    }

    // .filter-actions{
    //     margin-top:percentage(7.3/230);
    //     float:right;
    //     width:100%;
    // }

    .filter-group{
        width:percentage(407.35/822);
        float:left;
        position: relative;

        &:before{
            content:"";
            width:100%;
            height:2px;
            display:none;
            background:$black;
            position: absolute;
            top:0;
            left:0;
            z-index:1;
        }

        &:nth-child(2n+1){
            clear:both;
        }

        &:not(:nth-child(2n+1)){
            margin-left:percentage(7.3/822);
        }

        &:nth-child(n+3){
            margin-top:percentage(7.3/822);
        }
    }
}

.filter-actions{
    .btn{
        width:100%;
        font-size:em(14,$fs);
        padding-top:em(8.5,14);
        padding-bottom:em(8.5,14);
    }
    a{
        font-size:em(12,$fs);
        font-weight:normal;
        text-align:center;
        display:block;
        margin-top:percentage(7.3/153);
        height:em(35,12);
        line-height:em(35,12);
    }
}

.filter-options-title{
    //display:none;
    width:100%;
    border-radius:0;
    border:1px $grey solid;
    outline:none;
    background:$white;
    font-size:em(14,$fs);
    height:em(35,14);
    line-height:em(35,14);
    padding:0 em(36,14) 0 em(11,14);
    box-shadow:none;
    font-family:$font_body;
    appearance:none;
    font-weight:600;
    color:$black;
    overflow:hidden;
    position: relative;
    cursor:pointer;
    @include fancyArrow;
}

.filter-group.active{
    &:before{
        display:block;
    }
    .filter-options-title{
        @include fancyArrowClose;
    }
}

.filter-options-content{
    position: absolute;
    bottom:1px;
    left:0;
    transform:translateY(100%);
    background:$white;
    border:1px $grey solid;
    z-index:100;
    width:100%;

    .items{
        list-style:none;
        padding:0;
        margin:0;

        a{
            display:block;
            position: relative;
            font-weight:normal;
            font-size:em(14,$fs);
            transition:all $ms ease-in-out;

            &:hover{
                color:$black;
                background:$greylight;
            }

            input{
                position: absolute;
                top:0;
                left:0;
                pointer-events:none;
                width:1px;
                height:1px;
                opacity:0;
                overflow:hidden;

                &:checked + label{
                    background:$gray;
                }
            }

            label{
                display:block;
                cursor:pointer;
                padding:em(5,14) em(10,14);
            }
        }
    }

    .smile-es-range-slider{
        @include afterclear;
        padding:em(5,14) em(10,14);

        > div{
            &:nth-child(1){
                float:left;
            }
            &:nth-child(2){
                float:right;
            }
        }
        .actions-toolbar{
            margin-top:em(15,$fs);

            > div:first-child{
                font-size:em(14,$fs);
            }

            .action{
                @extend %btn;
                font-size:em(14,$fs);
                width:100%;
                text-align:center;
                margin:em(5,14) 0 0;
                cursor:pointer;
            }
        }
        .actions-toolbar,
        .ui-slider{
            float:left;
            clear:both;
            width:100%;
        }
    }

    .field{
        .control{
            padding:em(5,$fs) em(10,$fs);

            input{
                width:100%;
            }
        }
    }

    .actions{
        a{
            display:block;
            font-size:em(14,$fs);
            padding:em(5,14) em(10,14);
            background:$greymedium;
            cursor:pointer;
            transition:all $ms ease-in-out;

            &:hover{
                background:$greylight;
                color:$black;
            }
        }
    }
}

.ui-slider-horizontal{
    height:15px;
    background:$gray;
    margin:em(5,$fs) 0 0;

    .ui-slider-handle{
        width:15px;
        height:15px;
        background:$greydarkest;
        margin-left:0;

        + .ui-slider-handle{
            transform:translateX(-100%);
        }
    }
}

.select2-container{
    width:100%;

    .select2-choice{
        border-radius:0;
        border-color:$grey;
        background:$white;
        font-size:em(14,$fs);
        height:em(35,14);
        line-height:em(35,14);
        padding:0 0 0 em(15,14);
        box-shadow:none;
        color:$black;

        .select2-chosen{
            margin-right:em(55,$fs);
        }

        .select2-arrow{
            background:$white;
            border-radius:0;
            border:none;
            width:em(40,$fs);

            b{
                @include fancyArrow;
                background:none;
                position: relative;
            }
        }
    }

    &.select2-dropdown-open{
        .select2-arrow{
            b{
                @include fancyArrowClose;
            }
        }
    }
}

.select2-results.select2-results{
    overflow:hidden;
    animation:closeDrop $ms forwards;

    #select2-drop.select2-drop-active &{
        max-height:200px;
        animation:openDrop $ms forwards;
    }
}

@keyframes openDrop{
    0%      {max-height:0;}
    100%    {max-height:200px;}
}

@keyframes closeDrop{
    0%      {max-height:200px;}
    100%    {max-height:0;}
}

.select2-drop-active.select2-drop-active{
    border-color:$grey;
    border-radius:0;
    box-shadow:none;
    font-size:em(14,$fs);

    .select2-results .select2-highlighted{
        background:$grey;
        color:$black;
    }

    .select2-results .select2-result-label{
        padding:em(5,14) em(11,14);
    }
}

.filter-current{
    ul{
        list-style:none;
        margin:0 0 em(20,$fs);
        padding:0;
        font-size:em(12,$fs);

        li{
            display:inline-block;
            line-height:1em;

            + li{
                margin-left:em(10,$fs);
            }

            &:nth-child(2){
                padding-left:em(7,$fs);
                margin-left:em(7,$fs);
                border-left:1px $greydark solid;
            }
        }
    }

    a{
        &.action{
            background:$black;
            color:$white;
            padding:em(5,$fs) em(10,$fs);
            text-transform:uppercase;
            transition:all $ms ease-in-out;

            &:hover{
                background:$greydarkest;
            }

            i{
                text-transform:none;
                margin-right:em(10,$fs);
                font-style:normal;
            }

            .filter-label{
                font-weight:bold;
            }
        }
    }
}

#layered-filter-block .filter-content{
    transition:all $ms ease-in-out;
    max-height:0;
    overflow:hidden;

    &.overflowing{
        overflow:visible;
    }

    &.open{
        max-height:300px;
        width: 100%;
    }
}

// Fan categories
.fan-category-page {
    .filter-cat {
        display: none;
    }
}