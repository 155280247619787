.webforms{
    form{
        button{
            background:$black;
            color:$white;
            border:2px $black solid;
            outline:none;
            transition:all $ms ease-in-out;
            font-family:$font_body;
            font-size:em(16,$fs);
            text-transform:uppercase;
            font-weight:600;
            padding:em(12,16) em(15,16);

            &:hover{
                background:transparent;
                color:$black;
            }
        }
        .message.info{
            background:$grey;
            padding:em(15,$fs);
            margin:em(30,$fs) 0 0;
        }
        .mage-error{
            color:$red;
            font-size:em(14,$fs);
        }
        br{
            display:none;
        }
        fieldset{
            border:1px $grey solid;
            padding:em(30,$fs);

            + fieldset{
                margin:em(30,$fs) 0 0;
            }
        }
        legend{
            padding:0 em(5,$fs);
            background:$white;
            margin-left:em(-5,$fs);
        }
        label{
            padding:em(9,$fs) 0;
        }
        @include textfields{
            width:100%;
            font-size:1em;
            height:48px;
            line-height:48px;
            padding:0 em(15,$fs);
            color:$black;
            font-family:$font_body;
            border:1px $grey solid;
            max-width:100%;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                opacity:1;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                opacity:1;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                opacity:1;
            }
            &:-moz-placeholder { /* Firefox 18- */
                opacity:1;
            }
        }
        select{
            width:100%;
            font-size:1em;
            height:48px;
            line-height:48px;
            padding:0 em(15,$fs);
            color:$black;
            font-family:$font_body;
            border:1px $grey solid;
            max-width:100%;
        }
        textarea{
            width:100%;
            font-size:1em;
            height:48px;
            line-height:48px;
            padding:0 em(15,$fs);
            color:$black;
            font-family:$font_body;
            border:1px $grey solid;
            max-width:100%;
            height:150px;
        }
        .field{
            @include afterclear;
            position: relative;;

            + .field{
                margin:em(15,$fs) 0 0;
            }

            &.choice{
                //padding-left:25%;

                input {
                    position:absolute;
                    top:17px;
                    left:11px;
                    z-index:-100;
                    width:1px;
                    height:1px;
                    overflow:hidden;
                    border:none;
                    outline:none;
                    background:transparent;
                    pointer-events:none;
                    margin:0;
                    opacity:.01;
                }

                input[type=checkbox] {
                    &:checked{
                        + label {
                            &:after {
                                content:"✓";
                                color:$black;
                                font-style:normal;
                                font-size:24px;
                                line-height:10px;
                                text-indent:0px;
                            }
                        }
                    }
                }

                input[type=radio] {
                    &:checked {
                        + label {
                            &:after {
                                border-color:darken($secondary,10%);
                                background:$black;
                            }
                        }
                    }

                    + label {

                        &:after {
                            border-radius:11px;
                        }
                    }
                }

                label {
                    float:none;
                    width:auto;
                    position:relative;
                    padding-left:35px;
                    margin:0;

                    &:hover,
                    &:focus {
                        cursor:pointer;
                    }

                    &:after {
                        content:"";
                        width:16px;
                        height:16px;
                        display:block;
                        background:darken(#FFFFFF,10%);
                        border:1px darken(#FFFFFF,15%) solid;
                        position:absolute;
                        top:50%;
                        left:5px;
                        transform:translateY(-50%);
                    }
                }
            }

            &.required{
                label{
                    span{
                        position: relative;

                        &:after{
                            content:"*";
                            color:$red;
                            font-size:2em;
                            line-height:24px;
                            position: absolute;
                            top:0;
                            right:-3px;
                            transform:translateX(100%);
                        }
                    }
                }
            }

            label{
                float:left;
                width:23%;
            }

            .control{
                float:right;
                width:75%;
            }

            .nested{
                margin:em(15,$fs) 0 0;

                label,.control{
                    width:100%;
                }

                label{
                    background:$grey;
                    font-size:em(14,$fs);
                    padding:em(5,14) em(15,14);
                }
            }
        }
    }
}

.sidebar-form.sidebar-form{
    border-left:1px $greydarkest solid;
    padding:em(20,$fs) 0;
    margin:0 0 em(15,$fs) 0;
    padding:em(20,$fs) em(34,$fs);

    .webforms-description-text{
        margin:0;
    }

    fieldset{
        border:none;
        padding:0;
    }

    label{
        display:none;
    }

    .field .control{
        width:100%;
    }

    .block-title{
        font-size:em(36,$fs);
        font-weight:normal;
        font-family:$font_heading;
        margin:0 0 em(3,36);
        text-transform:uppercase;

        strong{
            font-weight:normal;
        }
    }

    h6{
        text-transform:none;
        font-size:em(14,$fs);
        margin:0 0 em(20,14);
    }

    @include textfields{
        background:$grey;
    }

    input + input{
        margin:em(15,$fs) 0 0;
    }

    input[type=submit]{
        font-size:1em;
    }
}

.webforms-success-text.webforms-success-text{
    background:$grey;
    border-color:$greydark;
}