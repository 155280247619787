//
// Store Locator
// ---------------------------------------------------------------------------------------------------------------------

// Header
.locator-header {
    .top-content {
        display: block;
        width: 100%;
        clear: both;

        .top-left {
            float: left;
            clear: left;
            width: 75%;
            padding-right: 15px;
            @media (max-width: 1279px) { width: 58%; }
            @media (max-width: 767px) { width: 100%; float: none; clear: both; }

            h2 {
                font-size: 22px;
                font-weight: bold;
                color: #333;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin: 0;
            }

            p {
                font-size: 14px;
                color: #222;
                line-height: 28px;
            }
        }

        .top-right {
            float: right;
            clear: right;
            width: 25%;
            @media (max-width: 1279px) { width: 42%; }
            @media (max-width: 767px) { width: 100%; float: none; clear: both; }

            .right-pict {
                float: left;
                width: 100px;
            }

            .right-text {
                float: left;
                text-align: left;
                padding: 5px 0 0 5px;
                font-size: 14px;
                line-height: 28px;
                color: #222;
            }
        }
    }

    .bottom-content {
        display: inline-block;
        clear: both;
        width: 100%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        padding-bottom: 5px;

        .bottom-left, .bottom-right {
            float: left;
            width: 50%;
            display: inline-block;
            @media (max-width: 767px) { width: 100%; float: none; clear: both; }

            .bottom-inner {
                padding: 5px 10px 5px 0;

                p {
                    font-size: 14px;
                    color: #222;
                    line-height: 28px;

                    span {
                        font-size: 16px;
                        font-style: italic;
                    }
                }
            }
        }
    }
}

.amlocator_form_buttons {
    display: inline;
    white-space: nowrap;
}

.amlocator_mapblock {
    float: right;
    width: 70%;
    @media(max-width: $laptop) { float: left !important; width: 100% !important; }
    @media(max-width: 480px) { display: none !important; }
}
.amlocator_store_list {
    display: none;
    float: left;
    width: 28%;
    margin-bottom: 20px;
    @media(max-width: $laptop) { width: 100% !important; }
    @media(max-width: 480px) { height: auto; }
}


// Map
.hl_mapblock, #amlocator-map-canvas {
    height: 800px !important;
}

.amlocator_center {
    position: relative;
    clear: both;
}

.hl_mapblock {
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    z-index: 1; }

// Filters box
.hl_locator_box {
    position: absolute;
    top: em(50,$fs);
    left: em(40,$fs);
    width: 575px;
    max-width:calc(100% - 2.5em - 2.5em);
    height: auto;
    background: rgba(255, 255, 255, 0.85);
    padding: 1.5rem;
    margin: 0;
    z-index: 2; }

.hl_locator_search, .hl_locator_filters {
    display: block;
    width: 100%;
}

.location_icon {
    float: left;
    clear: left;
    width: 32px;

    img {
        width: 100%;
        height: auto;
    }
}

#amasty_locator_filter {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;

    .block-title {
        margin: 0;
        font-size: 24px !important;
        text-transform: uppercase;
        color: #333;
    }
}

.showroom-content {
    .store-image {
        width: 185px;
        height: auto;
        margin: 0 !important;
        border: 1px solid #f1f0ee;
        border-top: 0;
    }
}

.location_data {
    display: inline-block;
    padding-left: 8px;
    width: 100%;
    @media(max-width: $laptop) { width: 100%; }

    .store-heading {
        display: block;
        width: 100%;
        margin-bottom: 5px;

        .store-heading-icon {
            padding: 2px 7px 0 0;
            vertical-align: middle;

            img {
                min-width: 32px;
                min-height: 32px;
            }
        }

        .store-heading-name {
            vertical-align: middle;
            line-height: 18px;
        }
    }

    address {
        width: 100%;
    }

    .location-buttons {
        float: left !important;
        margin: 0.5em 0 1.5em;

        a {
            width: 140px;
            display: inline-block;
            text-align: center;
            margin: 0 0 5px;
            padding: 8px 4px !important;
            border: 1px solid #333;
            font-size: 13px;
            line-height: 12px;
        }

        .showroom-button, .showroom-details-button {
            border: 1px solid #333;
            padding: 5px 8px;
            font-size: 13px;
            background: $white;
            color: $primary;
            transition:all 350ms ease-in-out;

            &:hover {
                background: $primary;
                color: $white;
            }
        }
    }
}

.today_schedule {
    margin: 0 !important;

    &:before {
        background-image: none !important;
    }
}

#amlocator_left .schedule_container {
    padding: 1px 0 0 20px !important;

    span {
        padding: 0 !important;
    }
}

.hl_locator_search .block-title,
.hl_locator_filters .block-title,
.use-my-location .block-title {
    font-size: 22px;
    font-weight: normal;
    color: #333;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 0; }

.use-my-location {
    border-top: 1px solid #ccc;
    margin-top: 5px;
    padding-top: 10px;
}

.hl_locator_filters {
    .filter-box {
        display: none;
    }
}

.filter-columns {
    display: block;
    width: 100%;
}

.filter-columns {
    .filter-column {
        float: left;
        width: 200px;

        &:last-child {
            width: 300px;
            @media(max-width: 550px) { margin: -5px 0 0; }
            @media(min-width: 600px) and (max-width: 1160px) { margin: -5px 0 0; }
        }
    }
}

.locator-icon {
    display: inline-block;
    vertical-align: middle;
}

// "Filter" button
button.filter-attribute {
    width: 100%;
    background: #eee;
    border: 1px solid #eee;
    color: #444;
    padding: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin: 0 !important;

    &:hover {
        background-color: #ddd;
        transition: background-color 250ms linear;
    }
}





.hl_store_list {
    display: none;
    margin-bottom: 20px;
    width: 100%;
    max-height: 615px;
}

.amlocator_left {
    display: block;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    max-height: 600px;

    span {
        @include afterclear;
        font-size: 14px !important;
        clear: both !important;
        border: none !important;
        padding: 1px em(15,$fs) !important;
        /*padding: em(15,$fs) em(15,$fs) em(15,$fs) em(30,$fs) !important;*/
    }

    .all_schedule {
        span {
            padding: 0 !important;
        }
    }
}

.amasty-popup {
    h2 {
        font-size: 1.5rem;
    }
}

// Showroom types at the top of the page
.showroom-types {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    clear: both;
    @media(max-width: $laptop) { margin: 0 0 20px; }

    .showroom-types-column {
        float: left;
        width: 33.33%;
        padding: 10px;
        height: 100px;
        text-align: center;
        margin-top: 20px;
        @media(max-width: $laptop) { width: 100%; height: auto; margin-top: 0; }

        .type-icon {
            float: left;
            vertical-align: middle;
            text-align: right;
            width: 20%;
            display: inline-block;
            padding: 2px 10px 0 0;
            @media(max-width: $laptop) { width: 10%; }

            img {
                float: right;
            }
        }

        .type-description {
            float: right;
            vertical-align: middle;
            font-size: 12px;
            text-align: left;
            display: inline-block;
            width: 80%;
            font-weight: bold;
            color: #333;
            @media(max-width: $laptop) { width: 90%; padding-top: 8px; }
            @media(max-width: 550px) { padding-top: 0; }
        }
    }

    &:after { /* Clear floats after the columns */
        content: "";
        display: table;
        clear: both;
    }
}

#current-address {
    display: inline-block;
}

.input-box,
.attribute-label,
.amlocator_input {
    display: inline-block;
    font-size: 13px;
    margin: 5px 0;
}

.amlocator_input {
    margin-top: 0 !important;
}

.amasty_distance {
    font-size: 14px;
}

.amlocator-search {
    background-image: url(../images/search.jpg);
    background-repeat: no-repeat;
    background-position: 12px 8px;
    font-size: 17px;
    font-weight: normal;
    color: #000;
    height: 44px;
    padding: 6px 8px 6px 50px;
    border: 1px solid #eee;
}

.amlocator-radius {
    background: #fff;
    font-size: 17px;
    font-weight: normal;
    color: #444;
    height: 44px;
    padding: 6px 8px;
    border: 1px solid #eee;
}

.sortByFilter, .locateNearBy {
    background: #eee;
    font-size: 15px;
    font-weight: normal;
    color: #444;
    height: 44px;
    padding: 6px 12px;
    border: 1px solid #eee;
    margin: 0 !important;

    &:hover {
        background-color: #ddd;
        transition: background-color 250ms linear;
    }
}

.show-filters {
    &:hover {
        color: #333;
    }

    .sign {
        display: inline-block;
    }
}

.productsOnDisplay {
    display: inline-block;
    width: 100%;
    clear: both;
}

.return-to-search-container {
    display: none;
    width: 100%;
}

.return-to-search {
    display: block;
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    font-weight: bold;
    margin: 0;
    padding: 14px 18px;
    border-radius: 0;
    width: 100%;
    text-align: left;

    /*&:hover {
        background-color: #222;
        transition: background-color 250ms linear;
    }*/
}




/* Custom checkboxes (filters) */
.checkbox-container {
    display: block;
    position: relative;
    padding: 0 0 0 25px !important;
    margin: 5px 0 10px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
