@font-face {
  font-family: 'hinkley';
  src: url('../fonts/hinkley.eot?74579530');
  src: url('../fonts/hinkley.eot?74579530#iefix') format('embedded-opentype'),
       url('../fonts/hinkley.woff2?74579530') format('woff2'),
       url('../fonts/hinkley.woff?74579530') format('woff'),
       url('../fonts/hinkley.ttf?74579530') format('truetype'),
       url('../fonts/hinkley.svg?74579530#hinkley') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'hinkley';
    src: url('../fonts/hinkley.svg?74579530#hinkley') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "hinkley";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-chat:before { content: '\e800'; } /* '' */
.icon-location:before { content: '\e801'; } /* '' */
.icon-heart-empty:before { content: '\e802'; } /* '' */
.icon-heart:before { content: '\e803'; } /* '' */
.icon-search:before { content: '\e804'; } /* '' */
.icon-down-open-big:before { content: '\e805'; } /* '' */
.icon-left-open-big:before { content: '\e806'; } /* '' */
.icon-right-open-big:before { content: '\e807'; } /* '' */
.icon-up-open-big:before { content: '\e808'; } /* '' */
.icon-menu:before { content: '\e809'; } /* '' */
.icon-cancel:before { content: '\e80a'; } /* '' */
.icon-email:before { content: '\e80b'; } /* '' */
.icon-link:before { content: '\e80c'; } /* '' */
.icon-tag:before { content: '\e80d'; } /* '' */
.icon-info:before { content: '\e811'; } /* '' */
.icon-pinterest-circled:before { content: '\f0d2'; } /* '' */
.icon-play-circled:before { content: '\f144'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-houzz:before { content: '\f27c'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
.icon-facebook:before { content: '\f30c'; } /* '' */

/* This stylesheet generated by Transfonter (https://transfonter.org) on February 23, 2018 3:58 PM */

@font-face {
    font-family: 'Chalet';
    src: url('../fonts/Chalet-ParisNineteenSixty.woff2') format('woff2'),
        url('../fonts/Chalet-ParisNineteenSixty.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

/*
@font-face {
    font-family: 'Chalet';
    src: url('../fonts/Chalet-NewYorkNineteenEighty.woff2') format('woff2'),
        url('../fonts/Chalet-NewYorkNineteenEighty.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Chronicle Disp Cond';
    src: url('../fonts/ChronicleDispCond-Italic.woff2') format('woff2'),
        url('../fonts/ChronicleDispCond-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Chalet';
    src: url('../fonts/Chalet-LondonNineteenEighty.woff2') format('woff2'),
        url('../fonts/Chalet-LondonNineteenEighty.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Chalet';
    src: url('../fonts/Chalet-NewYorkNineteenSixty.woff2') format('woff2'),
        url('../fonts/Chalet-NewYorkNineteenSixty.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Chalet';
    src: url('../fonts/Chalet-LondonNineteenSixty.woff2') format('woff2'),
        url('../fonts/Chalet-LondonNineteenSixty.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
*/

@font-face {
    font-family: 'Chronicle Disp Cond';
    src: url('../fonts/ChronicleDispCond-Roman.woff2') format('woff2'),
        url('../fonts/ChronicleDispCond-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HL-Raleway';
    src: url('../fonts/Raleway_Alt_Regular.woff') format('woff'),
         url('../fonts/Raleway_Alt_Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HL-Raleway';
    src: url('../fonts/Raleway_Alt_SemiBold.woff') format('woff'),
         url('../fonts/Raleway_Alt_SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'HL-Raleway';
    src: url('../fonts/Raleway_Alt_ExtraBold.woff') format('woff'),
         url('../fonts/Raleway_Alt_ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}