// Checkout
.checkout-index-index {
    padding: 0;

    // Form
    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"] {
        background: #ffffff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;
        height: 32px;
        line-height: 1.42857143;
        padding: 0 9px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
    }
    input[type="text"]:disabled,
    input[type="password"]:disabled,
    input[type="url"]:disabled,
    input[type="tel"]:disabled,
    input[type="search"]:disabled,
    input[type="number"]:disabled,
    input[type="datetime"]:disabled,
    input[type="email"]:disabled {
        opacity: 0.5;
    }
    input[type="text"]::-moz-placeholder,
    input[type="password"]::-moz-placeholder,
    input[type="url"]::-moz-placeholder,
    input[type="tel"]::-moz-placeholder,
    input[type="search"]::-moz-placeholder,
    input[type="number"]::-moz-placeholder,
    input[type="datetime"]::-moz-placeholder,
    input[type="email"]::-moz-placeholder {
        color: #c2c2c2;
    }
    input[type="text"]::-webkit-input-placeholder,
    input[type="password"]::-webkit-input-placeholder,
    input[type="url"]::-webkit-input-placeholder,
    input[type="tel"]::-webkit-input-placeholder,
    input[type="search"]::-webkit-input-placeholder,
    input[type="number"]::-webkit-input-placeholder,
    input[type="datetime"]::-webkit-input-placeholder,
    input[type="email"]::-webkit-input-placeholder {
        color: #c2c2c2;
    }
    input[type="text"]:-ms-input-placeholder,
    input[type="password"]:-ms-input-placeholder,
    input[type="url"]:-ms-input-placeholder,
    input[type="tel"]:-ms-input-placeholder,
    input[type="search"]:-ms-input-placeholder,
    input[type="number"]:-ms-input-placeholder,
    input[type="datetime"]:-ms-input-placeholder,
    input[type="email"]:-ms-input-placeholder {
        color: #c2c2c2;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="search"] {
        -webkit-appearance: none;
    }
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
    select {
        background: #ffffff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;
        height: 32px;
        line-height: 1.42857143;
        padding: 5px 10px 4px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
    }
    select:disabled {
        opacity: 0.5;
    }
    select[multiple="multiple"] {
        height: auto;
        background-image: none;
    }
    textarea {
        background: #ffffff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;
        height: auto;
        line-height: 1.42857143;
        margin: 0;
        padding: 10px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        resize: vertical;
    }
    textarea:disabled {
        opacity: 0.5;
    }
    textarea::-moz-placeholder {
        color: #c2c2c2;
    }
    textarea::-webkit-input-placeholder {
        color: #c2c2c2;
    }
    textarea:-ms-input-placeholder {
        color: #c2c2c2;
    }
    input[type="checkbox"] {
        margin: 2px 5px 0 0;
    }
    input[type="checkbox"]:disabled {
        opacity: 0.5;
    }
    input[type="radio"] {
        margin: 2px 5px 0 0;
    }
    input[type="radio"]:disabled {
        opacity: 0.5;
    }
    input.mage-error,
    select.mage-error,
    textarea.mage-error {
        border-color: #ed8380;
    }
    div.mage-error[generated] {
        color: #e02b27;
        font-size: 1.2rem;
    }
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        cursor: pointer;
        -webkit-appearance: button;
    }
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    form {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    address {
        font-style: normal;
    }
    *:focus {
        box-shadow: none;
        outline: 0;
    }
    ._keyfocus *:focus,
    input:not([disabled]):focus,
    textarea:not([disabled]):focus,
    select:not([disabled]):focus {
        box-shadow: 0 0 3px 1px #68a8e0;
    }

    // Fieldset
    .fieldset {
        border: 0;
        margin: 0 0 40px;
        padding: 0;
        letter-spacing: -.31em;
    }
    .fieldset > * {
        letter-spacing: normal;
    }
    .fieldset > .legend {
        margin: 0 0 20px;
        padding: 0 0 10px;
        width: 100%;
        box-sizing: border-box;
        float: left;
        font-weight: 300;
        line-height: 1.2;
        font-size: 1.2rem;
    }
    .fieldset > .legend + br {
        clear: both;
        display: block;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
    .fieldset:last-child {
        margin-bottom: 0;
    }
    .fieldset > .field,
    .fieldset > .fields > .field {
        margin: 0 0 20px;
    }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
        margin: 0 0 8px;
        display: inline-block;
    }
    .fieldset > .field:last-child,
    .fieldset > .fields > .field:last-child {
        margin-bottom: 0;
    }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
        font-weight: 600;
    }
    .fieldset > .field > .label + br,
    .fieldset > .fields > .field > .label + br {
        display: none;
    }
    .fieldset > .field .choice input,
    .fieldset > .fields > .field .choice input {
        vertical-align: top;
    }
    .fieldset > .field .fields.group:before,
    .fieldset > .fields > .field .fields.group:before,
    .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:after {
        content: '';
        display: table;
    }
    .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:after {
        clear: both;
    }
    .fieldset > .field .fields.group .field,
    .fieldset > .fields > .field .fields.group .field {
        box-sizing: border-box;
        float: left;
    }
    .fieldset > .field .fields.group.group-2 .field,
    .fieldset > .fields > .field .fields.group.group-2 .field {
        width: 50% !important;
    }
    .fieldset > .field .fields.group.group-3 .field,
    .fieldset > .fields > .field .fields.group.group-3 .field {
        width: 33.3% !important;
    }
    .fieldset > .field .fields.group.group-4 .field,
    .fieldset > .fields > .field .fields.group.group-4 .field {
        width: 25% !important;
    }
    .fieldset > .field .fields.group.group-5 .field,
    .fieldset > .fields > .field .fields.group.group-5 .field {
        width: 20% !important;
    }
    .fieldset > .field .addon,
    .fieldset > .fields > .field .addon {
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        padding: 0;
        width: 100%;
    }
    .fieldset > .field .addon textarea,
    .fieldset > .fields > .field .addon textarea,
    .fieldset > .field .addon select,
    .fieldset > .fields > .field .addon select,
    .fieldset > .field .addon input,
    .fieldset > .fields > .field .addon input {
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
        display: inline-block;
        margin: 0;
        width: auto;
    }
    .fieldset > .field .addon .addbefore,
    .fieldset > .fields > .field .addon .addbefore,
    .fieldset > .field .addon .addafter,
    .fieldset > .fields > .field .addon .addafter {
        background: #ffffff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;
        height: 32px;
        line-height: 1.42857143;
        padding: 0 9px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        -ms-flex-order: 3;
        -webkit-order: 3;
        order: 3;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        width: auto;
    }
    .fieldset > .field .addon .addbefore:disabled,
    .fieldset > .fields > .field .addon .addbefore:disabled,
    .fieldset > .field .addon .addafter:disabled,
    .fieldset > .fields > .field .addon .addafter:disabled {
        opacity: 0.5;
    }
    .fieldset > .field .addon .addbefore::-moz-placeholder,
    .fieldset > .fields > .field .addon .addbefore::-moz-placeholder,
    .fieldset > .field .addon .addafter::-moz-placeholder,
    .fieldset > .fields > .field .addon .addafter::-moz-placeholder {
        color: #c2c2c2;
    }
    .fieldset > .field .addon .addbefore::-webkit-input-placeholder,
    .fieldset > .fields > .field .addon .addbefore::-webkit-input-placeholder,
    .fieldset > .field .addon .addafter::-webkit-input-placeholder,
    .fieldset > .fields > .field .addon .addafter::-webkit-input-placeholder {
        color: #c2c2c2;
    }
    .fieldset > .field .addon .addbefore:-ms-input-placeholder,
    .fieldset > .fields > .field .addon .addbefore:-ms-input-placeholder,
    .fieldset > .field .addon .addafter:-ms-input-placeholder,
    .fieldset > .fields > .field .addon .addafter:-ms-input-placeholder {
        color: #c2c2c2;
    }
    .fieldset > .field .addon .addbefore,
    .fieldset > .fields > .field .addon .addbefore {
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }
    .fieldset > .field .additional,
    .fieldset > .fields > .field .additional {
        margin-top: 10px;
    }
    .fieldset > .field.required > .label:after,
    .fieldset > .fields > .field.required > .label:after,
    .fieldset > .field._required > .label:after,
    .fieldset > .fields > .field._required > .label:after {
        content: '*';
        color: #e02b27;
        font-size: 1.2rem;
        margin: 0 0 0 5px;
    }
    .fieldset > .field .note,
    .fieldset > .fields > .field .note {
        font-size: 1.2rem;
        margin: 3px 0 0;
        padding: 0;
        display: inline-block;
        text-decoration: none;
    }
    .fieldset > .field .note:before,
    .fieldset > .fields > .field .note:before {
        -webkit-font-smoothing: antialiased;
        font-size: 24px;
        line-height: 12px;
        font-family: 'luma-icons';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .fieldset > .field.no-label > .label,
    .fieldset > .fields > .field.no-label > .label {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .fieldset > .field.choice .label,
    .fieldset > .fields > .field.choice .label {
        display: inline;
        font-weight: normal;
        margin: 0;
    }
    .column:not(.main) .fieldset > .field .label,
    .column:not(.main) .fieldset > .fields > .field .label {
        font-weight: normal;
    }
    .fieldset > .field .field.choice,
    .fieldset > .fields > .field .field.choice {
        margin-bottom: 10px;
    }
    .fieldset > .field .field.choice:last-child,
    .fieldset > .fields > .field .field.choice:last-child {
        margin-bottom: 0;
    }
    .legend + .fieldset,
    .legend + div {
        clear: both;
    }
    .legend > span {
        margin-right: 5px;
    }
    fieldset.field {
        border: 0;
        padding: 0;
    }
    .field.date .time-picker {
        display: inline-block;
        margin-top: 10px;
        white-space: nowrap;
    }
    .field .message.warning {
        margin-top: 10px;
    }

    .table-checkout-shipping-method {
        min-width: 500px;
    }

    h4, .sidebar .block-title, legend {
        font-size: initial;
    }

    // Messages
    .message.info {
        margin: 0 0 10px;
        padding: 12px 20px 12px 25px;
        display: block;
        font-size: 1rem;
        background: #fdf0d5;
        color: #6f4400;
        position: relative;
    }
    .message.info a {
        color: #1979c3;
    }
    .message.info a:hover {
        color: #006bb4;
    }
    .message.info a:active {
        color: #006bb4;
    }
    .message.error {
        margin: 0 0 10px;
        padding: 12px 20px 12px 25px;
        display: block;
        font-size: 1rem;
        background: #fae5e5;
        color: #e02b27;
        position: relative;
    }
    .message.error a {
        color: #1979c3;
    }
    .message.error a:hover {
        color: #006bb4;
    }
    .message.error a:active {
        color: #006bb4;
    }
    .message.warning {
        margin: 0 0 10px;
        padding: 12px 20px 12px 25px;
        display: block;
        font-size: 1rem;
        background: #fdf0d5;
        color: #6f4400;
        position: relative;
    }
    .message.warning a {
        color: #1979c3;
    }
    .message.warning a:hover {
        color: #006bb4;
    }
    .message.warning a:active {
        color: #006bb4;
    }
    .message.notice {
        margin: 0 0 10px;
        padding: 12px 20px 12px 25px;
        display: block;
        font-size: 1rem;
        background: #fdf0d5;
        color: #6f4400;
        position: relative;
    }
    .message.notice a {
        color: #1979c3;
    }
    .message.notice a:hover {
        color: #006bb4;
    }
    .message.notice a:active {
        color: #006bb4;
    }
    .message.success {
        margin: 0 0 10px;
        padding: 12px 20px 12px 25px;
        display: block;
        font-size: 1rem;
        background: #e5efe5;
        color: #006400;
        position: relative;
    }
    .message.success a {
        color: #1979c3;
    }
    .message.success a:hover {
        color: #006bb4;
    }
    .message.success a:active {
        color: #006bb4;
    }

    input.action.primary {
        width: 100%;
        background: $black;
        color: $white;
        border: 2px $black solid;
        outline: none;
        transition: all $ms ease-in-out;
        font-family: $font_body;
        font-size: em(16, $fs);
        text-transform: uppercase;
        font-weight: 600;
        padding: em(12, 16) em(15, 16);

        &:hover {
            background: transparent;
            color: $black;
        }
    }
}

.opc-wrapper {
    width: 66.66666667%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding-right: 30px;
}
.opc-estimated-wrapper {
    display: none;
}

/* Progress bar (steps) */
.opc-progress-bar {
    margin: 0 0 20px;
    counter-reset: i;
    display: block;
    font-size: 0;
}
.opc-progress-bar-item {
    margin: 0 0 10px;
    width: 185px;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
}
.opc-progress-bar-item:before {
    background: #e4e4e4;
    top: 19px;
    transition: background 0.3s;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%;
}
.opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px;
}
.opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0;
}
.opc-progress-bar-item > span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: #e4e4e4;
    font-weight: 300;
    font-size: 1.2rem;
}
.opc-progress-bar-item > span:before,
.opc-progress-bar-item > span:after {
    background: #e4e4e4;
    height: 38px;
    margin-left: -19px;
    transition: background 0.3s;
    width: 38px;
    border-radius: 50%;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
}
.opc-progress-bar-item > span:after {
    background: #ffffff;
    height: 26px;
    margin-left: -13px;
    top: 6px;
    width: 26px;
    content: counter(i);
    counter-increment: i;
    color: #333333;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
}
.opc-progress-bar-item._complete {
    cursor: pointer;
}
.opc-progress-bar-item._complete:hover:before {
    background: #d7d7d7;
}
.opc-progress-bar-item._complete:hover > span:before {
    background: #d7d7d7;
}
.opc-progress-bar-item._complete > span {
    color: #1979c3;
}
.opc-progress-bar-item._complete > span:after {
    /*font-family: 'luma-icons';*/
    content: '\2713';
}
.opc-progress-bar-item._active:before {
    background: #222222;
}
.opc-progress-bar-item._active > span {
    color: #333333;
}
.opc-progress-bar-item._active > span:before {
    background: #222222;
}
.opc-progress-bar-item._active > span:after {
    font-family: 'luma-icons';
    /*content: '\25B2';*/
}



.checkout-index-index .modal-popup .form-shipping-address {
    max-width: 500px;
}
.checkout-index-index .modal-popup .modal-footer .action-save-address {
    float: right;
    margin: 0 0 0 20px;
}
.checkout-shipping-method .actions-toolbar > .primary {
    float: right;
}
.checkout-shipping-method .actions-toolbar .action.primary {
    margin: 0;
}
.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
    max-width: 500px;
}
.opc-wrapper .form-login {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 20px;
}
.table-checkout-shipping-method {
    width: auto;
}
.opc-sidebar {
    margin: 46px 0 20px;
    width: 33.33333333%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
}
.opc-summary-wrapper .modal-header .action-close {
    display: none;
}
.authentication-dropdown {
    background-color: #ffffff;
    border: 1px solid #aeaeae;
    -webkit-transform: scale(1, 0);
    -webkit-transform-origin: 0 0;
    -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0.1s;
    position: absolute;
    text-align: left;
    top: 100%;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear .1s, visibility 0s linear .1s;
    visibility: hidden;
    width: 100%;
}
.authentication-dropdown._show {
    z-index: 100;
    -webkit-transform: scale(1, 1);
    -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0s;
    transform: scale(1, 1);
    transition: transform linear .1s, visibility 0s linear 0s;
    visibility: visible;
}
.authentication-wrapper {
    width: 33.33333333%;
    text-align: right;
}
.block-authentication .block-title {
    font-size: 1.2rem;
    border-bottom: 0;
    margin-bottom: 25px;
}
.block-authentication .actions-toolbar > .primary {
    display: inline;
    float: right;
    margin-right: 0;
}
.block-authentication .actions-toolbar > .primary .action {
    margin-right: 0;
}
.block-authentication .actions-toolbar > .secondary {
    float: left;
    margin-right: 2rem;
    padding-top: 1rem;
}
.popup-authentication .modal-inner-wrap {
    min-width: 768px;
    width: 60%;
}
.popup-authentication .block-authentication {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border-top: 1px solid #c1c1c1;
}
.popup-authentication .block[class],
.popup-authentication .form-login,
.popup-authentication .fieldset,
.popup-authentication .block-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-grow: 1;
    flex-grow: 1;
}
.popup-authentication .block[class] {
    box-sizing: border-box;
    float: left;
    padding: 10px 30px 0 0;
    width: 50%;
}
.popup-authentication .block[class] + .block {
    border-left: 1px solid #c1c1c1;
    border-top: 0;
    margin: 0;
    padding: 10px 0 0 40px;
}
.popup-authentication .block[class] + .block::before {
    left: 0;
    top: 50%;
}
.popup-authentication .actions-toolbar {
    margin-bottom: 0;
    margin-top: auto;
}
.checkout-payment-method .payment-methods .actions-toolbar .primary {
    float: right;
    margin: 0;
}
.checkout-payment-method .fieldset > .field-select-billing > .control {
    float: none;
    width: 100%;
}
.payment-method-billing-address .action-update {
    float: right;
}
.payment-method-billing-address .actions-toolbar .action-cancel {
    margin: 6px 20px 0 0;
}
.checkout-payment-method .payment-option-title {
    padding-left: 22px;
}
.checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
    margin-left: 0;
}






.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content,
.shipping-policy-block.field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #666666;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px;
}
.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.opc-block-summary > .title,
.checkout-agreements-items .checkout-agreements-item-title {
    padding-bottom: 10px;
    color: #333333;
    font-weight: 300;
    font-size: 1.4rem;
}
.cart-totals,
.opc-block-summary .table-totals {
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    width: 100%;
}
.opc-block-summary .table-totals .table-caption {
    display: none;
}
.opc-block-summary .table-totals th.mark {
    text-align: left;
}
.cart-totals tbody .mark,
.cart-totals tfoot .mark,
.opc-block-summary .table-totals tbody .mark,
.opc-block-summary .table-totals tfoot .mark {
    border: 0;
    font-weight: 400;
    padding: 6px 0;
}
.cart-totals tbody .amount,
.cart-totals tfoot .amount,
.opc-block-summary .table-totals tbody .amount,
.opc-block-summary .table-totals tfoot .amount {
    border: 0;
    font-weight: 400;
    padding: 6px 0 6px 14px;
    text-align: right;
    white-space: nowrap;
}
.cart-totals .grand th,
.cart-totals .grand td,
.opc-block-summary .table-totals .grand th,
.opc-block-summary .table-totals .grand td {
    padding: 11px 0;
}
.cart-totals .grand strong,
.opc-block-summary .table-totals .grand strong {
    display: inline-block;
    font-weight: 600;
    padding: 3px 0 0;
}
.cart-totals .grand .mark,
.opc-block-summary .table-totals .grand .mark {
    font-size: 1.2rem;
    border-top: 1px solid #cccccc;
    padding-right: 10px;
}
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
    font-size: 1.2rem;
    border-top: 1px solid #cccccc;
}
.cart-totals .msrp,
.opc-block-summary .table-totals .msrp {
    margin-bottom: 10px;
}
.cart-totals tbody tr:last-child td,
.opc-block-summary .table-totals tbody tr:last-child td {
    padding-bottom: 19px;
}
.cart-totals .totals-tax-summary .mark,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .mark,
.opc-block-summary .table-totals .totals-tax-summary .amount {
    border-bottom: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    cursor: pointer;
}
.cart-totals .totals-tax-summary .amount .price,
.opc-block-summary .table-totals .totals-tax-summary .amount .price {
    padding-right: 20px;
    position: relative;
    display: inline-block;
    text-decoration: none;
}
.abs-sidebar-totals .totals-tax-summary .amount .price > span,
.cart-totals .totals-tax-summary .amount .price > span,
.opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-sidebar-totals .totals-tax-summary .amount .price:after,
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    line-height: 12px;
    color: inherit;
    content: '\25BC';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: 3px;
    top: 3px;
}
.cart-totals .totals-tax-summary.expanded .mark,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
    border-bottom: 0;
}
.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after,
.cart-totals .totals-tax-summary.expanded .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
    content: '\25B2';
}
.cart-totals .totals-tax-details,
.opc-block-summary .table-totals .totals-tax-details {
    border-bottom: 1px solid #cccccc;
    display: none;
}
.cart-totals .totals-tax-details.shown,
.opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row;
}
.cart-totals .table-wrapper,
.opc-block-summary .table-totals .table-wrapper {
    margin-bottom: 0;
}
.paypal-review-discount .block > .title strong,
.cart-discount .block > .title strong {
    color: #1979c3;
    font-weight: 400;
}
.paypal-review-discount .actions-toolbar,
.cart-discount .actions-toolbar {
    display: table-cell;
    vertical-align: top;
    width: 1%;
}
.paypal-review-discount .actions-toolbar .action.primary,
.paypal-review-discount .actions-toolbar .action.cancel,
.cart-discount .actions-toolbar .action.primary,
.cart-discount .actions-toolbar .action.cancel {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin: 0 0 0 -1px;
    white-space: nowrap;
    width: auto;
}
.paypal-review-discount .actions-toolbar .secondary,
.cart-discount .actions-toolbar .secondary {
    bottom: 5px;
    left: 15px;
    position: absolute;
}
.paypal-review-discount .action.check,
.cart-discount .action.check {
    font-weight: 400;
}
.paypal-review-discount .fieldset,
.cart-discount .fieldset {
    display: table;
    width: 100%;
}
.paypal-review-discount .fieldset > .field > .label,
.cart-discount .fieldset > .field > .label {
    display: none;
}
.paypal-review-discount .field,
.cart-discount .field {
    display: table-cell;
}





.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
    margin-top: 28px;
    margin-bottom: 28px;
}
.opc-wrapper .form-login .fieldset .field .label,
.opc-wrapper .form-shipping-address .fieldset .field .label {
    font-weight: 400;
}
.opc-wrapper .form-login .fieldset .note,
.opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px;
}
.opc-wrapper .shipping-address-items {
    font-size: 0;
}
.opc-wrapper .shipping-address-item {
    border: 2px solid transparent;
    font-size: 14px;
    line-height: 30px;
    margin: 0 0 20px;
    padding: 20px 35px 20px 20px;
    transition: 0.3s border-color;
    width: 50%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    word-wrap: break-word;
}
.opc-wrapper .shipping-address-item.selected-item {
    border-color: #222222;
}
.opc-wrapper .shipping-address-item.selected-item:after {
    background: #222222;
    color: #ffffff;
    content: '\25B2';
    /*font-family: 'luma-icons';*/
    height: 27px;
    width: 29px;
    font-size: 19px;
    line-height: 21px;
    padding-top: 2px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
}
.opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    visibility: hidden;
}
.opc-wrapper .action-show-popup {
    margin: 0 0 20px;
}
.opc-wrapper .action-show-popup > span:before {
    content: '+';
    padding-right: 5px;
}
.opc-wrapper .action-select-shipping-item {
    float: right;
    margin: 20px 0 0;
}
.opc-wrapper .edit-address-link {
    display: block;
    float: left;
    margin: 26px 5px 0 0;
}
.checkout-shipping-method .step-title {
    margin-bottom: 0;
}
.checkout-shipping-method .no-quotes-block {
    margin: 20px 0;
}
.methods-shipping .actions-toolbar .action.primary {
    margin: 20px 0 0;
}
.table-checkout-shipping-method thead th {
    display: none;
}
.table-checkout-shipping-method tbody td {
    border-top: 1px solid #cccccc;
    padding-bottom: 20px;
    padding-top: 20px;
}
.table-checkout-shipping-method tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 20px;
}
.table-checkout-shipping-method tbody tr:first-child td {
    border-top: none;
}
.table-checkout-shipping-method tbody .col-price {
    font-weight: 600;
}
.table-checkout-shipping-method tbody .row-error td {
    border-top: none;
    padding-bottom: 10px;
    padding-top: 0;
}
.checkout-shipping-method {
    position: relative;
}
.shipping-policy-block.field-tooltip {
    top: 12px;
}
.shipping-policy-block.field-tooltip .field-tooltip-action {
    color: #1979c3;
    cursor: pointer;
}
.shipping-policy-block.field-tooltip .field-tooltip-action:before {
    display: none;
}
.shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px;
}
.opc-block-shipping-information {
    padding: 0 30px;
}
.opc-block-shipping-information .shipping-information-title {
    border-bottom: 1px solid #cccccc;
    margin: 0 0 20px;
    position: relative;
}
.opc-block-shipping-information .shipping-information-title .action-edit {
    top: 10px;
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: absolute;
    right: 0;
    background: none;
    border: none;
}
.opc-block-shipping-information .shipping-information-title .action-edit > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.opc-block-shipping-information .shipping-information-title .action-edit:before {
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    line-height: 20px;
    color: #8f8f8f;
    content: '';
    /*font-family: 'luma-icons';*/
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
    color: #333333;
}
.opc-block-shipping-information .shipping-information-title .action-edit:active:before {
    color: #8f8f8f;
}
.opc-block-shipping-information .shipping-information-content {
    line-height: 27px;
}
.opc-block-shipping-information .shipping-information-content .actions-toolbar {
    margin-left: 0;
}
.opc-block-shipping-information .ship-to,
.opc-block-shipping-information .ship-via {
    margin: 0 0 20px;
}
.opc-block-summary {
    background: #f5f5f5;
    margin: 0 0 20px;
    padding: 22px 30px;
}
.opc-block-summary > .title {
    display: block;
}
.opc-block-summary .mark .value {
    color: #999999;
    display: block;
}
.opc-block-summary .grand.incl + .grand.excl .mark,
.opc-block-summary .grand.incl + .grand.excl .amount {
    border-top: 0;
    font-size: 1.4rem;
    padding-top: 0;
}
.opc-block-summary .grand.incl + .grand.excl .mark strong,
.opc-block-summary .grand.incl + .grand.excl .amount strong {
    font-weight: 400;
}
.opc-block-summary .not-calculated {
    font-style: italic;
}
.opc-block-summary .items-in-cart > .title {
    border-bottom: 1px solid #cccccc;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative;
}
.opc-block-summary .items-in-cart > .title > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.opc-block-summary .items-in-cart > .title:after {
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    line-height: 12px;
    color: inherit;
    content: '\25bc';
    /*font-family: 'luma-icons';*/
    margin: 3px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.opc-block-summary .items-in-cart > .title:after {
    position: absolute;
    right: 0;
    top: 10px;
}
.opc-block-summary .items-in-cart > .title strong {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0;
}
.opc-block-summary .items-in-cart.active > .title:after {
    content: '\25B2';
}
.opc-block-summary .items-in-cart .product {
    position: relative;
}
.opc-block-summary .minicart-items-wrapper {
    margin: 0 -15px 0 0;
    max-height: 370px;
    padding: 15px 15px 0 0;
    border: 0;
}
.column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0;
}
.opc-block-summary .product-item .product-item-inner {
    display: table;
    margin: 0 0 10px;
    width: 100%;
}
.opc-block-summary .product-item .product-item-name-block {
    display: table-cell;
    padding-right: 5px;
    text-align: left;
}
.opc-block-summary .product-item .subtotal {
    display: table-cell;
    text-align: right;
}
.opc-block-summary .product-item .price {
    font-size: 1.2rem;
    font-weight: 400;
}
.opc-block-summary .product-item .price-including-tax + .price-excluding-tax .price {
    font-size: 1rem;
}
.authentication-dropdown {
    box-sizing: border-box;
}
.authentication-dropdown .modal-inner-wrap {
    padding: 25px;
}
.authentication-wrapper {
    float: right;
    margin-top: -60px;
    max-width: 50%;
    position: relative;
    z-index: 1;
}
._has-auth-shown .authentication-wrapper {
    z-index: 900;
}
.block-authentication .block-title {
    font-size: 1.2rem;
    border-bottom: 0;
    margin-bottom: 25px;
}
.block-authentication .block-title strong {
    font-weight: 300;
}
.block-authentication .field .label {
    font-weight: 400;
}
.block-authentication .actions-toolbar {
    margin-bottom: 5px;
}
.block-authentication .actions-toolbar > .secondary {
    padding-top: 25px;
    text-align: left;
}
.block-authentication .block[class] {
    margin: 0;
}
.block-authentication .block[class] ul {
    list-style: none;
    padding-left: 10px;
}
.block-authentication .block[class] .field .control,
.block-authentication .block[class] .field .label {
    float: none;
    width: auto;
}
.block-authentication .block[class] + .block {
    border-top: 1px solid #c1c1c1;
    margin-top: 40px;
    padding-top: 40px;
    position: relative;
}
.block-authentication .block[class] + .block::before {
    height: 36px;
    line-height: 34px;
    margin: -19px 0 0 -18px;
    min-width: 36px;
    background: #ffffff;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    box-sizing: border-box;
    color: #c1c1c1;
    content: attr(data-label);
    display: inline-block;
    left: 50%;
    letter-spacing: normal;
    padding: 0 .2rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
}
.checkout-payment-method .step-title {
    margin-bottom: 0;
}
.checkout-payment-method .payment-method:first-child .payment-method-title {
    border-top: 0;
}
.checkout-payment-method .payment-method._active .payment-method-content {
    display: block;
}
.checkout-payment-method .payment-method-title {
    border-top: 1px solid #cccccc;
    padding: 20px 0;
    margin: 0;
}
.checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}
.checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px;
}
.checkout-payment-method .payment-method-content {
    display: none;
    padding: 0 0 20px 22px;
}
.checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px;
}
.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
    max-width: 500px;
}
.checkout-payment-method .billing-address-same-as-shipping-block {
    margin: 0 0 10px;
}
.checkout-payment-method .payment-method-billing-address {
    margin: 0 0 20px;
}
.checkout-payment-method .payment-method-billing-address .primary .action-update {
    margin-right: 0;
}
.checkout-payment-method .payment-method-billing-address .billing-address-details {
    line-height: 27px;
    padding: 0 0 0 23px;
}
.checkout-payment-method .payment-method-note + .payment-method-billing-address {
    margin-top: 20px;
}
.checkout-payment-method .payment-method-iframe {
    background-color: transparent;
    display: none;
    width: 100%;
}
.checkout-payment-method .no-payments-block {
    margin: 20px 0;
}
.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
    content: '\25B2';
}
.checkout-payment-method .payment-option._collapsible .payment-option-title {
    cursor: pointer;
}
.checkout-payment-method .payment-option._collapsible .payment-option-content {
    display: none;
}
.checkout-payment-method .payment-option-title {
    border-top: 1px solid #cccccc;
    padding: 20px 0;
}
.checkout-payment-method .payment-option-title .action-toggle {
    color: #1979c3;
    display: inline-block;
    text-decoration: none;
}
.checkout-payment-method .payment-option-title .action-toggle:after {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 16px;
    color: #8f8f8f;
    content: '\25BC';
    /*font-family: 'luma-icons';*/
    margin: 0 0 0 10px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.checkout-payment-method .payment-option-title .action-toggle:hover:after {
    color: #333333;
}
.checkout-payment-method .payment-option-title .action-toggle:active:after {
    color: #8f8f8f;
}
.checkout-payment-method .payment-option-content {
    padding: 0 0 20px 22px;
}
.checkout-payment-method .payment-option-inner {
    margin: 0 0 20px;
}
.checkout-payment-method .credit-card-types {
    padding: 0;
}
.checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top;
}
.checkout-payment-method .credit-card-types .item._active {
    font-weight: 700;
}
.checkout-payment-method .credit-card-types .item._active img {
    -webkit-filter: grayscale(0%);
}
.checkout-payment-method .credit-card-types .item._inactive {
    opacity: .4;
}
.checkout-payment-method .credit-card-types .item span {
    display: inline-block;
    padding-top: 6px;
    vertical-align: top;
}
.checkout-payment-method .credit-card-types img {
    -webkit-filter: grayscale(100%);
    -webkit-transition: all .6s ease;
}
.checkout-payment-method .ccard .fields > .year {
    padding-left: 5px;
}
.checkout-payment-method .ccard .fields .select {
    padding-left: 5px;
    padding-right: 5px;
}
.checkout-payment-method .ccard .month .select {
    width: 140px;
}
.checkout-payment-method .ccard .year .select {
    width: 80px;
}
.checkout-payment-method .ccard .number .input-text {
    width: 225px;
}
.checkout-payment-method .ccard > .field.cvv > .control {
    display: inline-block;
    padding-right: 20px;
}
.checkout-payment-method .ccard .cvv .label {
    display: block;
}
.checkout-payment-method .ccard .cvv .input-text {
    width: 55px;
}
.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
    width: auto !important;
}
.checkout-agreements-block {
    margin-bottom: 20px;
}
.checkout-agreements-block .action-show {
    vertical-align: baseline;
}
.checkout-agreements-items {
    padding-bottom: 30px;
}
.checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px;
}
.checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0;
}
.checkout-agreement-item-content {
    overflow: auto;
}


