/*!
Theme Name: Weidenhammer Creative Base Theme
Author: Weidenhammer Creative
Author URI: http://weidenhammercreative.com
*/

// Bourbon
@import "bourbon";

// Reset
@import "base/reset";

// Variables must be loaded before the grid to override grid settings
@import "mixins/variables";

// Mixins
@import "mixins/custom-mixins";

// General Styling
@import "partials/magnific";
@import "partials/fonts";
@import "partials/globals";
@import "partials/header";
@import "partials/navigation";
@import "partials/callouts";
@import "partials/front-page";
@import "partials/page";
@import "partials/blog";
@import "partials/forms";
@import "partials/footer";
@import "partials/minicart";
@import "partials/cart";
@import "partials/checkout";
@import "partials/product_grid";
@import "partials/account";
@import "partials/category";
@import "partials/product";
@import "partials/carousel";
@import "partials/showroom";
@import "partials/amlocator";
@import "partials/product_registration";
@import "partials/layout-style";

@import "partials/browser-fixes";

// Page / Post / Ect - Specific
// @import "partials/page-home";

// 3rd Party
@import "vendor/owl.carousel";
@import "vendor/hamburgers";
@import "vendor/animate";
@import "vendor/select2";

// Responsive
@import "partials/responsive";
