.home-carousel{
    margin:em(75,$fs) auto 0;
    max-width:$width;
    text-align:center;

    h3{
        font-size:em(24,$fs);
        text-transform:uppercase;
        font-weight:600;
        margin:0 0 em(1,24);
    }
    h4{
        font-size:em(24,$fs);
        font-family:$font_script;
        font-style:italic;
    }

    .owl-carousel{
        margin:em(23,$fs) 0 0;
    }

    .owl-nav{
        width:100%;
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);

        > div{
            position: absolute;
            top:50%;
            transform:translateY(-50%);
            font-family:$icon;
            color:$white;
            font-size:em(35,$fs);
            padding:em(30,35);
            line-height:1;
            transition:all $ms ease-in-out;

            &:hover{
                background:rgba(0,0,0,.10);
                cursor:pointer;
            }
        }
    }

    .owl-prev{
        left:0;
    }

    .owl-next{
        right:0;
    }
}

.style-carousel.style-carousel{
    text-align:center;
    position: relative;

    @include headings{
        font-size:em(24,$fs);
        font-family:$font_script;
        font-style:italic;
        display:inline-block;
        background:$white;
        padding:0 em(20,24);
        position: relative;
        z-index:2;
        margin:0;
        text-transform:none;
        font-weight:normal;
    }

    &:before{
        content:"";
        width:100%;
        height:1px;
        background:$greydark;
        position: absolute;
        top:em(14,$fs);
        left:50%;
        transform:translateX(-50%);
        z-index:1;
    }

    &.no-heading{
        &:before{
            display:none;
        }
    }

    .owl-carousel{
        margin:em(16,$fs) 0 0;
    }

    .owl-nav{
        width:100%;
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);

        > div{
            position: absolute;
            top:50%;
            font-family:$icon;
            color:$greydark;
            font-size:em(35,$fs);
            padding:em(30,35);
            line-height:1;
            transition:all $ms ease-in-out;

            &:hover{
                color:$black;
                cursor:pointer;
            }
        }
    }

    .owl-prev{
        left:0;
        transform:translateX(-100%) translateY(-50%);
    }

    .owl-next{
        right:0;
        transform:translateX(100%) translateY(-50%);
    }
}