$hero_em: em(30,$fs);

.blog-hero-block-wrap{
    width:$hwidth;
    max-width:calc(100vw - #{$hero_em});
    padding:em(200,$fs) 0 0;
    margin-top:em(-30,$fs);
    position: relative;
    left:50%;
    transform:translateX(-50%);
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
    z-index:100;
}

.blog-hero-nav-wrap{
    width:100%;
    background:$black;
    padding:em(15,$fs);
}

.blog-hero-nav{
    @include afterclear();
    margin:0 auto;
    max-width:$width;
    color:$white;
    position: relative;

    > ul{
        padding:0;
        margin:0;
        transform:translateY(.9375em);
        float:left;
        clear:both;
        width:100%;
        text-align:left;

        > li{
            display:block;
            position: relative;

            &:hover{
                a.has-children + div{
                    max-height:500px;
                    opacity:1;
                }
            }

            a{
                display:block;
                text-transform:uppercase;
                font-weight:normal;
                transition:all $ms ease-in-out;
                padding-bottom:em(15,$fs);

                &:hover{
                    color:$greydarkest;
                }

                &.has-children{
                    font-weight:600;
                    padding-right:em(25,$fs);

                    &:after{
                        content:"\e805";
                        font-family:$icon;
                        color:$white;
                        position: absolute;
                        top:em(13,$fs);
                        right:0;
                        transform:translateY(-50%);
                    }
                }
            }
        }
    }

    a{
        color:$white;
    }
}

.blog-hero-nav-left.blog-hero-nav-left{
    display:block;

    h1{
        color:$white;
        font-family:$font_script;
        font-weight:normal;
        text-transform:uppercase;
        font-size:em(60,$fs);
        line-height:1em;
        margin:0;
        background:$black;
        display:inline-block;
        position: relative;
        z-index:2;
        padding-right:em(15,60);
    }
    h6{
        color:$white;
        text-transform:uppercase;
        margin:0;
        font-family:$font_body;
        font-size:em(14,$fs);
        font-weight:normal;
    }
}

.block-blog-tag-cloud{
    position: absolute;
    bottom:0;
    right:0;
    text-align:left;
    transform:translateY(100%);
    background:$black;
    min-width:100%;
    border-top:1px $greydarkest solid;
    max-height:0;
    overflow:hidden;
    opacity:0;
    transition:all $ms ease-in-out;

    .block-title{
        display:none;
    }

    a{
        font-size:em(14,$fs) !important;
        padding:em(7,14) em(15,14) !important;
    }
}

.blog-posts{
    .blog-post-header{
        @include afterclear;
        position: relative;
        padding:0;
    }
    .blog-post-title{
        line-height:1.25em;
        font-size:em(36,$fs);
        margin:0 0 em(5,36);
        font-family:$font_script;
        font-style:italic;
        font-weight:normal;
    }
}


ul.blog-post-info{
    float:left;
    list-style:none;
    margin:0;
    padding:0;
    text-align:left;
    width:100%;

    > li{
        line-height:1em;

        &:nth-child(-n+1){
            display:inline-block;
            font-weight:600;
            font-size:em(14,$fs);

            &:not(:first-child){
                padding-left:em(10,$fs);
                margin-left:em(10,$fs);
                border-left:1px $greydark solid;
            }
        }
        &:not(:last-child){
            text-transform:uppercase;
        }
    }
}

.blog-post-featured-image-link{
    display:block;
    margin:0 0 em(10,$fs);
}

.blog-posts{
    @include afterclear;
    margin-top:em(50,$fs);
    margin-bottom:em(50,$fs);
}

.blog-posts{
    .blog-post{
        + .blog-post{
            margin-top:percentage(43/$inside_width_num);
        }
    }
}

.social-icons{
    margin-top:em(10,$fs);
}


$post_right_em: em(430,$fs);

.blog-featured-block{
    @include afterclear;
    position: relative;
    margin:em(30,$fs) 0 0;

    &.top{
        padding-bottom:em(50,$fs);
        border-bottom:1px $greydark solid;
    }

    &.bottom{
        padding-top:em(50,$fs);
        border-top:1px $greydark solid;
    }

    @include headings{
        line-height:1.25em;
        font-size:em(36,$fs);
        margin:0 0 em(5,36);
        font-family:$font_script;
        font-style:italic;
        font-weight:normal;
    }

    .lookbook-container{
        clear:both;
        display:block;
        width:$inside_width;
        max-width:calc(100vw - #{$hero_em});
        margin:0;

        .price-label{
            display:none;
        }

        form{
            display:none;
        }
    }

    ul.blog-post-info{
        margin-top:em(15,$fs);
    }
}

.blog-post-header-featured{
    margin:0 0 em(15,$fs);
}

// share
.blog-posts{
    .share-this{
        margin:em(15,$fs) 0;
    }
}
.share-toggle {
    display:inline-block;
    font-size:em(18,$fs);
    border:0;
    background:none;
    margin:0;
    padding:0;
    color:$primary;
    font-family:$font_script;
    font-style:italic;
    margin-right:em(15,18);
}

.ssk-group {
    width:100%;
    z-index:10;
    list-style:none;
    display:inline;
    padding:0;

    > li{
        display:inline-block;

        + li{
            margin-left:em(7,$fs);
        }
    }

    .ssk {
        border:0;
        width:50%;
        padding:0;
        color:$black;
        height:40px;
        font-size:em(20,$fs);
        font-weight:normal;
        position:relative;
        text-decoration: none;
        cursor: pointer;
        font-family:$icon;
        padding:0 em(5,20);
        transition:all $ms ease-in-out;

        &:hover{
            cursor:pointer;
            color:$greydark;
        }
    }
}

.blog-pagination{
    text-align:right;
}

.blog-pagination-item{
    @extend %btn;
    margin:em(60,$fs) 0 0;

    &:not(.blog-pagination-page-current){
        border:1px $grey solid;
        background:$white;
        color:$black;

        &:hover{
            background:$black;
            border-color:$black;
            color:$white;
        }
    }
}

.blog-pinterest-block{
    $pin_em: em(30,$fs);
    width:$width;
    max-width:calc(100vw - #{$pin_em});
    margin:em(50,$fs) 0;
    padding:em(50,$fs) 0 0;
    text-align:center;
    position: relative;
    left:50%;
    transform:translateX(-50%);

    &:before{
        content:"";
        width:100%;
        max-width:$inside_width;
        height:1px;
        background:$greydark;
        position: absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
    }

    h6{
        font-family:$font_body;
        font-size:em(24,$fs);
        margin:0 0 em(25,24);

        span{
            font-family:$icon;
            color:$white;
            background:$black;
            display:inline-block;
            border-radius:50%;
            width:em(32,24);
            height:em(32,24);
            text-align:center;
            line-height:em(35,24);
        }
    }

    ul{
        @include afterclear;
        list-style:none;
        margin:0;
        padding:0;

        > li{
            + li{
                margin:percentage(40.5/$width_num) 0 0 0;
            }
        }
    }

    a{
        display:block;
        font-size:em(14,$fs);
    }

    img{
        margin:0 0 em(15,$fs);
    }
}

.lookbook-container{
    .pin__type{
        background:$white !important;
        color:$black !important;
        box-shadow: 0 3px 5px 0 rgba(0,0,0,.25);
        width:em(30,$fs) !important;
        height:em(30,$fs) !important;

        .pin-label{
            position: relative;
            color:$white;

            &:before{
                content:"\e80d";
                font-family:$icon;
                color:$black;
                font-size:em(20,$fs);
                display:block;
                position: absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
            }
        }
    }

    .pin__popup .popup__content--product h3{
        font-size:em(22,$fs);
    }
}

.aw-blog-post-view{
    ol{
        padding-left:em(15,$fs);

        li{
            padding-left:em(5,$fs);

            + li{
                margin-top:em(15,$fs);
            }

            span:first-child{
                display:block;
                margin:0 0 em(5,$fs);
            }
        }
    }
    .blog-hero-block-wrap{
        background:none !important;
        padding-top:0;
        padding-bottom:0;
        margin-bottom: em(30,$fs);
    }
    .blog-hero-nav-wrap{
        position: relative;
        bottom:auto;
        left:auto;
    }
    .blog-post-header{
        @include headings{
            line-height:1.25em;
            font-size:em(36,$fs);
            margin:0 0 em(5,36);
            font-family:$font_script;
            font-style:italic;
            font-weight:normal;
        }
    }
    .blog-posts-post{
        max-width:$skinny;
        margin-left:auto;
        margin-right:auto;
    }
    .blog-post-title{
        clear:both;
        margin:0;
        line-height:1.25em;
    }
    .blog-post-header-top{
        .blog-post-info{
            float:left;
            width:auto;
        }
    }
    .blog-post-share-title{
        display:none;
    }
    .blog-share{
        clear:both;
        margin:em(15,$fs) 0 em(30,$fs);
    }
    .share-this{
        margin:0;
    }
    .blog-share-and-tags{
        @include afterclear;
        margin:em(30,$fs) 0 0;

        .blog-share{
            margin:0;
        }
    }
    .blog-tags-plain{
        float:left;

        p{
            display:inline-block;
            text-transform:uppercase;
            font-size:em(14,$fs);
            font-weight:600;
            margin:0 em(3,14) 0 0;
        }

        a{
            font-family:$font_script;
            font-style:italic;
            font-weight:normal;
            font-size:em(18,$fs);
        }
    }
    .lookbook-container,
    .breakout{
        margin:0 0 em(15,$fs);
        width:$inside_width;
        max-width:calc(100vw - .9375em - .9375em);
        position: relative;
        left:50%;
        transform:translateX(-50%);
    }
}

.aw_blog-post-view{
    ol{
        padding-left:em(15,$fs);

        li{
            padding-left:em(5,$fs);

            + li{
                margin-top:em(15,$fs);
            }

            span:first-child{
                display:block;
                margin:0 0 em(5,$fs);
            }
        }
    }
    .blog-hero-block-wrap{
        background:none !important;
        padding-top:0;
        padding-bottom:0;
        margin-bottom: em(30,$fs);
    }
    .blog-hero-nav-wrap{
        position: relative;
        bottom:auto;
        left:auto;
    }
    .blog-post-header{
        @include headings{
            line-height:1.25em;
            font-size:em(36,$fs);
            margin:0 0 em(5,36);
            font-family:$font_script;
            font-style:italic;
            font-weight:normal;
        }
    }
    .blog-posts-post{
        max-width:$skinny;
        margin-left:auto;
        margin-right:auto;
    }
    .blog-post-title{
        clear:both;
        margin:0;
        line-height:1.25em;
    }
    .blog-post-header-top{
        .blog-post-info{
            float:left;
            width:auto;
        }
    }
    .blog-post-share-title{
        display:none;
    }
    .blog-share{
        clear:both;
        margin:em(15,$fs) 0 em(30,$fs);
    }
    .share-this{
        margin:0;
    }
    .blog-share-and-tags{
        @include afterclear;
        margin:em(30,$fs) 0 0;

        .blog-share{
            margin:0;
        }
    }
    .blog-tags-plain{
        float:left;

        p{
            display:inline-block;
            text-transform:uppercase;
            font-size:em(14,$fs);
            font-weight:600;
            margin:0 em(3,14) 0 0;
        }

        a{
            font-family:$font_script;
            font-style:italic;
            font-weight:normal;
            font-size:em(18,$fs);
        }
    }
    .lookbook-container,
    .breakout{
        margin:0 0 em(15,$fs);
        width:$inside_width;
        max-width:calc(100vw - .9375em - .9375em);
        position: relative;
        left:50%;
        transform:translateX(-50%);
    }
}

.next-post-link{
    @include afterclear;
    position: relative;

    > a{
        position: absolute;
        top:calc(50% - ((.625em + 45px)/2));
        left:0;
        transform:translateY(-50%);
        display:block;
        font-family:$font_script;
        font-size:em(30,$fs);
        font-style:italic;
        font-weight:normal;
    }

    .blog-post{
        float:right;
        width:601px;
    }

    .blog-post-header{
        @include headings{
            margin:0;
        }
    }
}
