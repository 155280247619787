@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 40px !default;
$hamburger-layer-height                    : 4px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : #000 !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spin,
  spin-r,
  spring,
  spring-r,
  stand,
  stand-r,
  squeeze,
  vortex,
  vortex-r
) !default;

// Base Hamburger (We need this)
// ==================================================
// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

// Hamburger types
// ==================================================
@if index($hamburger-types, 3dx-r) {
  /*
   * 3DX Reverse
   */
  .hamburger--3dx-r {
    .hamburger-box {
      perspective: $hamburger-layer-width * 2;
    }

    .hamburger-inner {
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
                  background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::before,
      &::after {
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: transparent;
        transform: rotateY(-180deg);

        &::before {
          transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
        }

        &::after {
          transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
        }
      }
    }
  }
}

@if index($hamburger-types, 3dx) {
  /*
   * 3DX
   */
  .hamburger--3dx {
    .hamburger-box {
      perspective: $hamburger-layer-width * 2;
    }

    .hamburger-inner {
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
                  background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::before,
      &::after {
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: transparent;
        transform: rotateY(180deg);

        &::before {
          transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
        }

        &::after {
          transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
        }
      }
    }
  }
}

@if index($hamburger-types, 3dxy-r) {
  /*
   * 3DXY Reverse
   */
  .hamburger--3dxy-r {
    .hamburger-box {
      perspective: $hamburger-layer-width * 2;
    }

    .hamburger-inner {
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
                  background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::before,
      &::after {
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: transparent;
        transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);

        &::before {
          transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
        }

        &::after {
          transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
        }
      }
    }
  }
}

@if index($hamburger-types, 3dxy) {
  /*
   * 3DXY
   */
  .hamburger--3dxy {
    .hamburger-box {
      perspective: $hamburger-layer-width * 2;
    }

    .hamburger-inner {
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
                  background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::before,
      &::after {
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: transparent;
        transform: rotateX(180deg) rotateY(180deg);

        &::before {
          transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
        }

        &::after {
          transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
        }
      }
    }
  }
}

@if index($hamburger-types, 3dy-r) {
  /*
   * 3DY Reverse
   */
  .hamburger--3dy-r {
    .hamburger-box {
      perspective: $hamburger-layer-width * 2;
    }

    .hamburger-inner {
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
                  background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::before,
      &::after {
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: transparent;
        transform: rotateX(180deg);

        &::before {
          transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
        }

        &::after {
          transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
        }
      }
    }
  }
}

@if index($hamburger-types, 3dy) {
  /*
   * 3DY
   */
  .hamburger--3dy {
    .hamburger-box {
      perspective: $hamburger-layer-width * 2;
    }

    .hamburger-inner {
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
                  background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::before,
      &::after {
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: transparent;
        transform: rotateX(-180deg);

        &::before {
          transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
        }

        &::after {
          transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
        }
      }
    }
  }
}

@if index($hamburger-types, arrow-r) {
  /*
   * Arrow Right
   */
  .hamburger--arrow-r.is-active {
    .hamburger-inner {
      &::before {
        transform: translate3d($hamburger-layer-width * 0.2, 0, 0) rotate(45deg) scale(0.7, 1);
      }

      &::after {
        transform: translate3d($hamburger-layer-width * 0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
      }
    }
  }
}

@if index($hamburger-types, arrow) {
  /*
   * Arrow
   */
  .hamburger--arrow.is-active {
    .hamburger-inner {
      &::before {
        transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
      }

      &::after {
        transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(45deg) scale(0.7, 1);
      }
    }
  }
}

@if index($hamburger-types, arrowalt-r) {
  /*
   * Arrow Alt Right
   */
  .hamburger--arrowalt-r {
    .hamburger-inner {
      &::before {
        transition: top 0.1s 0.1s ease,
                    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      &::after {
        transition: bottom 0.1s 0.1s ease,
                    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        &::before {
          top: 0;
          transform: translate3d($hamburger-layer-width * 0.2, $hamburger-layer-width * -0.25, 0) rotate(45deg) scale(0.7, 1);
          transition: top 0.1s ease,
                      transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
        }

        &::after {
          bottom: 0;
          transform: translate3d($hamburger-layer-width * 0.2, $hamburger-layer-width * 0.25, 0) rotate(-45deg) scale(0.7, 1);
          transition: bottom 0.1s ease,
                      transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
        }
      }
    }
  }
}

@if index($hamburger-types, arrowalt) {
  /*
   * Arrow Alt
   */
  .hamburger--arrowalt {
    .hamburger-inner {
      &::before {
        transition: top 0.1s 0.1s ease,
                    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      &::after {
        transition: bottom 0.1s 0.1s ease,
                    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        &::before {
          top: 0;
          transform: translate3d($hamburger-layer-width * -0.2, $hamburger-layer-width * -0.25, 0) rotate(-45deg) scale(0.7, 1);
          transition: top 0.1s ease,
                      transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
        }

        &::after {
          bottom: 0;
          transform: translate3d($hamburger-layer-width * -0.2, $hamburger-layer-width * 0.25, 0) rotate(45deg) scale(0.7, 1);
          transition: bottom 0.1s ease,
                      transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
        }
      }
    }
  }
}

@if index($hamburger-types, arrowturn-r) {
  /*
   * Arrow Turn Right
   */
  .hamburger--arrowturn-r.is-active {
    .hamburger-inner {
      transform: rotate(-180deg);

      &::before {
        transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
      }

      &::after {
        transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
      }
    }
  }
}

@if index($hamburger-types, arrowturn) {
  /*
   * Arrow Turn
   */
  .hamburger--arrowturn.is-active {
    .hamburger-inner {
      transform: rotate(-180deg);

      &::before {
        transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
      }

      &::after {
        transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
      }
    }
  }
}

@if index($hamburger-types, boring) {
  /*
   * Boring
   */
  .hamburger--boring {
    .hamburger-inner {
      &,
      &::before,
      &::after {
        transition-property: none;
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);

        &::before {
          top: 0;
          opacity: 0;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
        }
      }
    }
  }
}

@if index($hamburger-types, collapse-r) {
  /*
   * Collapse Reverse
   */
  .hamburger--collapse-r {
    .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::after {
        top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    opacity 0.1s linear;
      }

      &::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                      opacity 0.1s 0.22s linear;
        }

        &::before {
          top: 0;
          transform: rotate(90deg);
          transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                      transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, collapse) {
  /*
   * Collapse
   */
  .hamburger--collapse {
    .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::after {
        top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    opacity 0.1s linear;
      }

      &::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                      opacity 0.1s 0.22s linear;
        }

        &::before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                      transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, elastic-r) {
  /*
   * Elastic Reverse
   */
  .hamburger--elastic-r {
    .hamburger-inner {
      top: $hamburger-layer-height / 2;
      transition-duration: 0.275s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition: opacity 0.125s 0.275s ease;
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }

    &.is-active {
      .hamburger-inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

        transform: translate3d(0, $y-offset, 0) rotate(-135deg);
        transition-delay: 0.075s;

        &::before {
          transition-delay: 0s;
          opacity: 0;
        }

        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(270deg);
          transition-delay: 0.075s;
        }
      }
    }
  }
}

@if index($hamburger-types, elastic) {
  /*
   * Elastic
   */
  .hamburger--elastic {
    .hamburger-inner {
      top: $hamburger-layer-height / 2;
      transition-duration: 0.275s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition: opacity 0.125s 0.275s ease;
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }

    &.is-active {
      .hamburger-inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

        transform: translate3d(0, $y-offset, 0) rotate(135deg);
        transition-delay: 0.075s;

        &::before {
          transition-delay: 0s;
          opacity: 0;
        }

        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(-270deg);
          transition-delay: 0.075s;
        }
      }
    }
  }
}

@if index($hamburger-types, emphatic-r) {
  /*
   * Emphatic Reverse
   */
  .hamburger--emphatic-r {
    overflow: hidden;

    .hamburger-inner {
      transition: background-color 0.125s 0.175s ease-in;

      &::before {
        left: 0;
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
                    top 0.05s 0.125s linear,
                    left 0.125s 0.175s ease-in;
      }

      &::after {
        top: ($hamburger-layer-height) + ($hamburger-layer-spacing);
        right: 0;
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
                    top 0.05s 0.125s linear,
                    right 0.125s 0.175s ease-in;
      }
    }

    &.is-active {
      .hamburger-inner {
        transition-delay: 0s;
        transition-timing-function: ease-out;
        background-color: transparent;

        &::before {
          left: $hamburger-layer-width * -2;
          top: $hamburger-layer-width * 2;
          transform: translate3d($hamburger-layer-width * 2, $hamburger-layer-width * -2, 0) rotate(-45deg);
          transition: left 0.125s ease-out,
                      top 0.05s 0.125s linear,
                      transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &::after {
          right: $hamburger-layer-width * -2;
          top: $hamburger-layer-width * 2;
          transform: translate3d($hamburger-layer-width * -2, $hamburger-layer-width * -2, 0) rotate(45deg);
          transition: right 0.125s ease-out,
                      top 0.05s 0.125s linear,
                      transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, emphatic) {
  /*
   * Emphatic
   */
  .hamburger--emphatic {
    overflow: hidden;

    .hamburger-inner {
      transition: background-color 0.125s 0.175s ease-in;

      &::before {
        left: 0;
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
                    top 0.05s 0.125s linear,
                    left 0.125s 0.175s ease-in;
      }

      &::after {
        top: ($hamburger-layer-height) + ($hamburger-layer-spacing);
        right: 0;
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
                    top 0.05s 0.125s linear,
                    right 0.125s 0.175s ease-in;
      }
    }

    &.is-active {
      .hamburger-inner {
        transition-delay: 0s;
        transition-timing-function: ease-out;
        background-color: transparent;

        &::before {
          left: $hamburger-layer-width * -2;
          top: $hamburger-layer-width * -2;
          transform: translate3d($hamburger-layer-width * 2, $hamburger-layer-width * 2, 0) rotate(45deg);
          transition: left 0.125s ease-out,
                      top 0.05s 0.125s linear,
                      transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &::after {
          right: $hamburger-layer-width * -2;
          top: $hamburger-layer-width * -2;
          transform: translate3d($hamburger-layer-width * -2, $hamburger-layer-width * 2, 0) rotate(-45deg);
          transition: right 0.125s ease-out,
                      top 0.05s 0.125s linear,
                      transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, minus) {
  /*
   * Minus
   */
  .hamburger--minus {
    .hamburger-inner {
      &::before,
      &::after {
        transition: bottom 0.08s 0s ease-out,
                    top 0.08s 0s ease-out,
                    opacity 0s linear;
      }
    }

    &.is-active {
      .hamburger-inner {
        &::before,
        &::after {
          opacity: 0;
          transition: bottom 0.08s ease-out,
                      top 0.08s ease-out,
                      opacity 0s 0.08s linear;
        }
        &::before {
          top: 0;
        }

        &::after {
          bottom: 0;
        }
      }
    }
  }
}

@if index($hamburger-types, slider-r) {
  /*
   * Slider Reverse
   */
  .hamburger--slider-r {
    .hamburger-inner {
      top: $hamburger-layer-height / 2;

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: 0.15s;
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
      }
    }

    &.is-active {
      .hamburger-inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

        transform: translate3d(0, $y-offset, 0) rotate(-45deg);

        &::before {
          transform: rotate(45deg) translate3d($hamburger-layer-width / 7, $hamburger-layer-spacing * -1, 0);
          opacity: 0;
        }

        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(90deg);
        }
      }
    }
  }
}

@if index($hamburger-types, slider) {
  /*
   * Slider
   */
  .hamburger--slider {
    .hamburger-inner {
      top: $hamburger-layer-height / 2;

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: 0.15s;
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
      }
    }

    &.is-active {
      .hamburger-inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

        transform: translate3d(0, $y-offset, 0) rotate(45deg);

        &::before {
          transform: rotate(-45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
          opacity: 0;
        }

        &::after {
          transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
        }
      }
    }
  }
}

@if index($hamburger-types, spin-r) {
  /*
   * Spin Reverse
   */
  .hamburger--spin-r {
    .hamburger-inner {
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.1s 0.25s ease-in,
                    opacity 0.1s ease-in;
      }

      &::after {
        transition: bottom 0.1s 0.25s ease-in,
                    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(-225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.1s ease-out,
                      opacity 0.1s 0.12s ease-out;
        }

        &::after {
          bottom: 0;
          transform: rotate(90deg);
          transition: bottom 0.1s ease-out,
                      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, spin) {
  /*
   * Spin
   */
  .hamburger--spin {
    .hamburger-inner {
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.1s 0.25s ease-in,
                    opacity 0.1s ease-in;
      }

      &::after {
        transition: bottom 0.1s 0.25s ease-in,
                    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.1s ease-out,
                      opacity 0.1s 0.12s ease-out;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease-out,
                      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, spring-r) {
  /*
   * Spring Reverse
   */
  .hamburger--spring-r {
    .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::after {
        top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    opacity 0s linear;
      }

      &::before {
        transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                      opacity 0s 0.22s linear;
        }

        &::before {
          top: 0;
          transform: rotate(90deg);
          transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                      transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, spring) {
  /*
   * Spring
   */
  .hamburger--spring {
    .hamburger-inner {
      top: $hamburger-layer-height / 2;
      transition: background-color 0s 0.13s linear;

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transition-delay: 0.22s;
        background-color: transparent;

        &::before {
          top: 0;
          transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                      transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
        }

        &::after {
          top: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                      transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
        }
      }
    }
  }
}

@if index($hamburger-types, squeeze) {
  /*
   * Squeeze
   */
  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease,
                    opacity 0.075s ease;
      }

      &::after {
        transition: bottom 0.075s 0.12s ease,
                    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease,
                      opacity 0.075s 0.12s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
                      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, stand-r) {
  /*
   * Stand Reverse
   */
  .hamburger--stand-r {
    .hamburger-inner {
      transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
                  background-color 0s 0.075s linear;

      &::before {
        transition: top 0.075s 0.075s ease-in,
                    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      &::after {
        transition: bottom 0.075s 0.075s ease-in,
                    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(-90deg);
        background-color: transparent;

        transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
                    background-color 0s 0.15s linear;

        &::before {
          top: 0;
          transform: rotate(-45deg);
          transition: top 0.075s 0.1s ease-out,
                      transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &::after {
          bottom: 0;
          transform: rotate(45deg);
          transition: bottom 0.075s 0.1s ease-out,
                      transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, stand) {
  /*
   * Stand
   */
  .hamburger--stand {
    .hamburger-inner {
      transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
                  background-color 0s 0.075s linear;

      &::before {
        transition: top 0.075s 0.075s ease-in,
                    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      &::after {
        transition: bottom 0.075s 0.075s ease-in,
                    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(90deg);
        background-color: transparent;

        transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
                    background-color 0s 0.15s linear;

        &::before {
          top: 0;
          transform: rotate(-45deg);
          transition: top 0.075s 0.1s ease-out,
                      transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &::after {
          bottom: 0;
          transform: rotate(45deg);
          transition: bottom 0.075s 0.1s ease-out,
                      transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

@if index($hamburger-types, vortex-r) {
  /*
   * Vortex Reverse
   */
  .hamburger--vortex-r {
    .hamburger-inner {
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

      &::before,
      &::after {
        transition-duration: 0s;
        transition-delay: 0.1s;
        transition-timing-function: linear;
      }

      &::before {
        transition-property: top, opacity;
      }

      &::after {
        transition-property: bottom, transform;
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(-765deg);
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

        &::before,
        &::after {
          transition-delay: 0s;
        }

        &::before {
          top: 0;
          opacity: 0;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
        }
      }
    }
  }
}

@if index($hamburger-types, vortex) {
  /*
   * Vortex
   */
  .hamburger--vortex {
    .hamburger-inner {
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

      &::before,
      &::after {
        transition-duration: 0s;
        transition-delay: 0.1s;
        transition-timing-function: linear;
      }

      &::before {
        transition-property: top, opacity;
      }

      &::after {
        transition-property: bottom, transform;
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(765deg);
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

        &::before,
        &::after {
          transition-delay: 0s;
        }

        &::before {
          top: 0;
          opacity: 0;
        }

        &::after {
          bottom: 0;
          transform: rotate(90deg);
        }
      }
    }
  }
}


// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
