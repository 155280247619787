body{
    padding-top:em(148,$fs);
}

.header-wrap{
    position:fixed;
    top:0;
    left:0;
    z-index:999;

    header {
        display:block;
        width:100%;
        padding:0;
        z-index:2017;
        border-bottom:1px $greydark solid;
        background:$white;
    }
}

.hamburger {
    position:absolute;
    right:10px;
    top:10px;
}

$hamburger-padding-x           : 0;
$hamburger-padding-y           : 0;
$hamburger-layer-width         : 40px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-border-radius : 0px;
$hamburger-layer-color         : $primary;
$hamburger-hover-opacity       : 1;

.eyebrow-links-wrap{
    position: absolute;
    top:0;
    left:0;
    background:$black;
    font-size:em(12,$fs);
    line-height:1em;
}

.eyebrow-links{
    max-width:$width !important;

    ul{
        list-style:none;
        margin:0;
        padding:0;

        &:first-child{
            float:left;
        }

        &:last-child{
            float:right;
        }
    }

    li{
        display:inline-block;
        position: relative;

        &:not(:first-child){
            margin-left:11px;
            padding-left:12px;

            &:before{
                content:"";
                width:1px;
                height:50%;
                background:$white;
                display:block;
                position: absolute;
                left:0;
                top:50%;
                transform:translateY(-50%);
            }
        }

    }

    a{
        display:block;
        color:$white;
        font-weight:bold;
        letter-spacing:em(.75,12);
        padding:em(9,12) 0;
        transition:all $ms ease-in-out;

        &:hover{
            color:$greydark;
        }
    }

    .icon-chat{
        position: relative;
        padding-left:27px;

        &:before{
            font-size:1.5em;
            position: absolute;
            top:50%;
            left:0;
            transform:translateY(-50%);
        }
    }

    .icon-location{
        position: relative;
        padding-left:20px;
        font-weight:900;

        &:before{
            font-size:1.25em;
            position: absolute;
            top:50%;
            left:0;
            transform:translateY(-50%);
        }
    }
}

.page-header{
    .header.content{
        @include afterclear;
        max-width:$width;
        margin:0 auto;
        padding-top:em(22,$fs);
        position: relative;
        transition:padding $ms ease-in-out;

        .block-search{
            position: absolute;
            top:em(41,$fs);
            left:0;
            transition:top $ms ease-in-out;

            .block-title{
                display:none;
            }

            .field.search{
                position: relative;
                padding-left:em(36,$fs);

                label{
                    position: absolute;
                    top:calc(50% - .1875em);
                    left:0;
                    transform:translateY(-50%);
                    text-indent:-99999px;

                    &:before{
                        content:"\e804";
                        font-family:$icon;
                        text-indent:0;
                        display:block;
                        position: absolute;
                        top:50%;
                        left:0;
                        font-size:1.15em;
                        transform:translateY(-50%);
                        cursor:pointer;
                    }
                }
                #search{
                    width:100%;
                    max-width:270px;
                    height:100%;
                    display:block;
                    border:none;
                    font-size:em(16,$fs);
                    font-family:$font_body;
                    padding:0 0 em(3,16);
                    border-bottom:2px $white solid;
                    transition:all $ms ease-in-out;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        opacity:1;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        opacity:1;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        opacity:1;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        opacity:1;
                    }

                    &:focus{
                        border-bottom-color:$greydark;
                    }
                }
            }

            .actions{
                display:none;
            }
        }

        .logo{
            display:block;
            margin:0 auto;
            width:220px;
            transition:width $ms ease-in-out;

            img{
                width:100%;
                height:auto;
            }
        }

        .header.links{
            position: absolute;
            top:em(36,$fs);
            right:0;
            list-style:none;
            margin:0;
            transition:top $ms ease-in-out;

            li{
                display:inline-block;
                position: relative;

                &:not(:first-child){
                    margin-left:11px;
                    padding-left:12px;

                    &:before{
                        content:"";
                        width:1px;
                        height:50%;
                        background:$black;
                        display:block;
                        position: absolute;
                        left:0;
                        top:50%;
                        transform:translateY(-50%);
                    }
                }
            }

            a{
                font-size:em(12,$fs);
                letter-spacing:em(.75,12);
                line-height:1em;
                font-weight:600;
                color:$black;
                transition:all $ms ease-in-out;

                &:hover{
                    color:$greydark;
                }
            }

            .link.wishlist{
                a{
                    display:block;
                    padding-left:em(40,$fs);

                    &:before,
                    &:after{
                        content:"\e802";
                        font-size:1.4em;
                        font-family:$icon;
                        display:block;
                        position: absolute;
                        top:50%;
                        left:0;
                        font-weight:normal;
                        transform:translateY(-50%);
                    }

                    &:before{
                        content:"\e802";
                    }

                    &:after{
                        content:"\e803";
                        opacity:0;
                        transition:all $ms ease-in-out;
                    }

                    &:hover{
                        color:$black;

                        &:before,
                        &:after{
                            color:$black;
                            opacity:1;
                        }
                    }
                }
            }
        }
    }
}
