@include mqmax( $phone ) {
    // picture list
    .picture-list-item {
        @include afterclear;
        margin:em(45,$fs) 0 0;
        img {
            float:none;
            margin: 0 auto;
        }
        p {
            float:none;
            width: 100%;
            margin-top: 15px;
            text-align: center;
            padding: 0 15px;
        }
    }
    // webforms
    .webforms {
        form {
            .field {
                label{
                    float:none;
                    width:100%;
                    text-align: left;
                }
                .control{
                    float:none;
                    width:100%;
                }
            }
        }
    }
    .content-columns {
        .col {
            > a{
                > img{
                    width: 100%;
                }
            }
        }
    }
    .next-post-link {
        .blog-post {
            float: right;
            width: 100%;
        }
    }
}
@include mqmin( $phone ) {
    .callout-row{
        &.cols-1{
            .callout{
                h2{
                    font-size:em(60,$fs);
                }
            }
        }
    }
    .callout-row{
        .callout{
            .mobile-background{
                display:none;
            }
        }
        &.cols-1{
            .callout{
                padding:em(425,$fs) em(30,$fs) em(30,$fs);
            }
        }
    }
    .home-feature-block{
        h2{
            font-size:em(60,$fs);
        }
    }
    .product-title-badge{
        display:inline-block;
    }
    .product-custom-actions{
        width:auto;
        float:left;
        > li{
            width:auto;
            + li{
                margin-left:em(10,14);
            }
        }
        a{
            width:auto;
        }
    }
    .towishlist{
        display:block;
        .product-social-links &{
            float:right;
        }
    }
    .blog-posts{
        .blog-post{
            float:left;
            width:percentage(601/$inside_width_num);
            + .blog-post{
                margin-top:0;
            }
            &:nth-child(even){
                margin-left:percentage(43/$inside_width_num);
            }
            &:nth-child(odd){
                clear:both;
            }
            &:nth-child(n+3){
                margin-top:percentage(43/$inside_width_num);
            }
        }
    }
    .social-icons{
        float:right;
        margin-top:0;
    }
    .share-this{
        margin:em(4,$fs) 0 0;
    }
    .blog-pinterest-block{
        ul{
            > li{
                float:left;
                width:percentage(433/$width_num);
                + li{
                    margin:0 0 0 percentage(40.5/$width_num);
                }
            }
        }
    }
    .content-columns{
        @include afterclear;
        clear:both;
        .col{
            float:left;
            clear:none;
            + .col{
                margin-top:0;
            }
        }
        &.cols-2{
            + .cols-2{
                margin: 0;
            }
            .col{
                width:percentage(600/$inside_width_num);
                + .col{
                    margin-left:percentage(45/$inside_width_num);
                }
            }
        }
        &.cols-3{
            + .cols-3{
                margin:percentage(22.5/$inside_width_num) 0 0;
            }
            .col{
                width:percentage(400/$inside_width_num);
                + .col{
                    margin-left:percentage(22.5/$inside_width_num);
                }
            }
        }
        &.cols-4{
            + .cols-4{
                margin:percentage(36.33/$inside_width_num) 0 0;
            }
            .col{
                padding:0;
                background:none;
                width:percentage(284/$inside_width_num);
                text-align: left;
                + .col{
                    margin-left:percentage(36.33/$inside_width_num);
                }
            }
        }
    }
    .helpful-links.helpful-links{
        width:400px;
        max-width:50%;
        float:right;
        margin:0 0 em(30,$fs) em(30,$fs);
    }
    .sidebar-form.sidebar-form{
        width:400px;
        max-width:50%;
        float:right;
        margin:0 0 em(30,$fs) em(30,$fs);
    }
    .page-quote-block.page-quote-block{
        &.right{
            text-align:left;
            width:400px;
            max-width:50%;
            float:right;
            margin:0 0 em(30,$fs) em(30,$fs);
            padding:em(50,$fs) em(34,$fs);
            border-left:1px $greydarkest solid;
            h5{
                font-size:em(36,$fs);
                margin:0 0 em(15,36);
            }
            h6{
                font-size:em(14,$fs);
            }
        }
    }
    .filter-segment{
        .filter-group.filter-group{
            width:percentage(269/822);
            clear:none;
            margin:0;
            &:nth-child(3n+1){
                clear:both;
            }
            &:not(:nth-child(3n+1)){
                margin-left:percentage(7.3/822);
            }
            &:nth-child(n+4){
                margin-top:percentage(7.3/822);
            }
        }
    }
    .aw-blog-post-view{
        .blog-share{
            margin:0 0 em(20,$fs);
            float:right;
            clear:none;
        }
        .blog-post-header-top{
            .blog-post-info{
                margin:0 0 em(20,$fs);
            }
        }
    }
    .aw_blog-post-view{
        .blog-share{
            margin:0 0 em(20,$fs);
            float:right;
            clear:none;
        }
        .blog-post-header-top{
            .blog-post-info{
                margin:0 0 em(20,$fs);
            }
        }
    }
}
@include mqmin( $tablet ) {
    body{
        font:normal #{$fs}px/1.65 $font_body;
    }
    .callout-row{
        .callout{
            padding:0;
        }
        &.break-out{
            &.cols-1{
                .callout{
                    padding-top:em(30,$fs);
                    padding-bottom:em(30,$fs);
                }
            }
        }
        &.cols-1{
            .callout{
                padding:0 0 percentage(795 / $hwidth_num);
                .callout-box{
                    &.top{
                        top:percentage(50/795);
                    }
                    &.bottom{
                        bottom:percentage(50/795);
                    }
                    &.right{
                        right:percentage(70 / $hwidth_num);
                    }
                    &.left{
                        left:percentage(70 / $hwidth_num);
                    }
                }
            }
        }
        &.cols-2{
            .callout{
                padding-bottom:percentage(577 / $hwidth_num);
                float:left;
                width:percentage(830 / $hwidth_num);
                &:nth-child(even){
                    margin:0 0 0 percentage(20 / $hwidth_num);
                }
                .callout-box{
                    &.top{
                        top:percentage(50/577);
                    }
                    &.bottom{
                        bottom:percentage(50/577);
                    }
                    &.right{
                        right:percentage(70/830);
                    }
                    &.left{
                        left:percentage(70/830);
                    }
                }
            }
        }
        &.cols-3{
            .callout{
                padding-bottom:percentage(433 / $width_num);
                float:left;
                width:percentage(433 / $width_num);
                + .callout{
                    margin:0 0 0 percentage(40.5 / $width_num);
                }
                .callout-box{
                    width:calc(100% - 6.92841% - 6.92841%);
                    &.top{
                        top:percentage(30/433);
                    }
                    &.bottom{
                        bottom:percentage(30/433);
                    }
                    &.right{
                        right:percentage(30/433);
                    }
                    &.left{
                        left:percentage(30/433);
                    }
                }
            }
        }
        &.category-callout{
            float:left;
            margin-top:percentage(22.5/$inside_width_num);
            margin-left:percentage(22.5/$inside_width_num);
            width:percentage(822.5/$inside_width_num);
            .callout{
                padding-bottom:percentage(527.417 / 822.5);
            }
        }
    }
    .callout-box{
        position: absolute;
        &.top{
            top:percentage(70 / $hwidth_num);
        }
        &.bottom{
            bottom:percentage(70 / $hwidth_num);
        }
        &.right{
            right:percentage(70 / $hwidth_num);
        }
        &.left{
            left:percentage(70 / $hwidth_num);
        }
        &.center{
            top:50%;
            left:50%;
            transform:translateX(-50%) translateY(-50%);
            &:hover{
                transform:translateX(-50%) translateY(calc(-50% - 10px));
            }
        }
    }
    .filter-sort-actions{
        &.right{
            text-align:right;
            margin-top:em(-37,$fs);
        }
    }
    .filter-segment{
        float:left;
        &.category{
            width:percentage(262/$inside_width_num);
            .filter-group.filter-group.filter-group{
                float:none;
                width:100%;
                + .filter-group{
                    margin:percentage(7.3/230) 0 0 0;
                }
            }
        }
        &.product{
            width:percentage(983/$inside_width_num);
            border:none;
            border-left:1px $grey solid;
        }
        .filter-groups{
            //width:percentage(822/982);
        }
        .filter-actions{
            margin-top:0;
            width:percentage(153.3/982);
        }
        .filter-group.filter-group.filter-group{
            width:percentage(200/822);
            clear:none;
            margin:0;
            &:nth-child(4n+1){
                clear:both;
            }
            &:not(:nth-child(4n+1)){
                margin-left:percentage(7.3/822);
            }
            &:nth-child(n+5){
                margin-top:percentage(7.3/822);
            }
        }
    }
    .products-grid.products-grid{
        .product-item{
            float:left;
            clear:none;
            margin:0;
            width:percentage(605/$inside_width_num);
            &:nth-child(2n+1){
                clear:both;
            }
            &:not(:nth-child(2n+1)){
                margin-left:percentage(35/$inside_width_num);
            }
            &:nth-child(n+3){
                margin-top:percentage(35/$inside_width_num);
            }
        }
        .page-layout-home &{
            .product-item{
                float:left;
                clear:none;
                margin:0;
                width:percentage(650/$width_num);
                &:nth-child(2n+1){
                    clear:both;
                }
                &:not(:nth-child(2n+1)){
                    margin-left:percentage(80/$width_num);
                }
                &:nth-child(n+3){
                    margin-top:percentage(80/$width_num);
                }
            }
        }
        &.subcategories{
            .product-item{
                clear:none;
                margin:0;
                width:percentage(400/$inside_width_num);
                &:nth-child(3n+1){
                    clear:both;
                }
                &:not(:nth-child(3n+1)){
                    margin-left:percentage(22.5/$inside_width_num);
                }
                &:nth-child(n+4){
                    margin-top:percentage(22.5/$inside_width_num);
                }
            }
        }
    }
    .home-feature-block{
        .callout-box{
            position:relative;
            top:auto;
            left:auto;
            right:auto;
            bottom:auto;
            transform:none;
            &:hover{
                transform:translateY(-10px);
            }
        }
    }
    .product-info-main{
        float:right;
        width:percentage(600/$inside_width_num);
    }
    .product.media{
        margin:0;
        float:left;
        width:percentage(602/$inside_width_num);
    }
    .tab[data-tab="tab-overview"]{
        .attributes-list{
            float:left;
            width:percentage(220/$inside_width_num);
            + .attributes-list{
                margin:0 0 0 percentage(85/$inside_width_num);
            }
        }
        .attribute-content-area{
            margin:0;
            float:right;
            width:percentage(600/$inside_width_num);
        }
    }
    .social-icons{
        float:none;
    }
    .page-layout-blog-landing{
        .blog-featured-block{
            ul.blog-post-info{
                text-align:right;
                float:right;
                width:percentage(400/$inside_width_num);
                margin-top:0;
            }
            > *:not(.blog-post-info){
                float:left;
                clear:left;
                width:percentage(830/$inside_width_num);
            }
        }
        .share-this{
            text-align:right;
            margin:em(80,$fs) 0 0;
        }
    }
    .aw-blog-post-view{
        .next-post-link{
            .blog-post-header{
                ul.blog-post-info{
                    text-align:right;
                    float:right;
                    width:percentage(256/601);
                }
                @include headings{
                    float:left;
                    clear:left;
                    width:percentage(338/601);
                }
            }
        }
    }
    .aw_blog-post-view{
            .next-post-link{
                .blog-post-header{
                    ul.blog-post-info{
                        text-align:right;
                        float:right;
                        width:percentage(256/601);
                    }
                    @include headings{
                        float:left;
                        clear:left;
                        width:percentage(338/601);
                    }
                }
            }
        }
    .blog-post-header-featured{
        margin:0;
    }
    .page-layout-flexible-inside-page-hero-alt.page-layout-flexible-inside-page-hero-alt{
        .page-title-wrapper{
            float:left;
            width:percentage(600/$inside_width_num);
        }
        .page-mini-hero-block{
            float:right;
            width:percentage(605/$inside_width_num);
            margin:em(40,$fs);
            margin-right:0;
            margin-top:em(-115,$fs);
        }
    }
    body.page-layout-showroom-page .main-wrap{
        .page-title-wrapper{
            padding-left:203px;
        }
    }
    .showroom-info{
        .showroom-logo{
            float:left;
            margin:em(-30,$fs) 20px 0 0;
            img{
                display:block;
            }
            h6{
                background:$grey;
            }
        }
    }
    #speedbump{
        .speedbump-content{
            width:48%;
            .webforms .field {
                padding-left: 0;
            }
            .actions-toolbar {
                padding-left: 0;
            }
        }
        .speedbump-image{
            display:block;
            width:48%;
            background:$gray;
            background-size:cover;
            background-repeat:no-repeat;
            background-position:center center;
            height: 340px;
            position: absolute;
            top: 5%;
            right: 0;
        }
    }
    .toolbar-products{
        float:right;
        &.toolbar-result {
            float: none;
            .amount {
                float: left;
            }
            .toolbar-sorter {
                float: right;
            }
            .limiter {
                padding-right: 15px;
            }
        }
    }
    [class*=" categorypath-family-representatives-"],
    [class*=" categorypath-products-"] {
        .toolbar-products{
            margin:em(-35,$fs) 0 0;
        }
    }
    .blog-hero-block-wrap{
        padding:0 0 percentage(605/$inside_width_num);
    }
    .blog-hero-nav-wrap{
        position: absolute;
        bottom:0;
        left:0;
    }
    .blog-hero-nav{
        > ul{
            > li{
                display:inline-block;
                + li{
                    margin-left:em(40,$fs);
                }
            }
        }
    }
    .blog-hero-nav-left{
        width:385px;
        float:left;
    }
    .location_data {
        address{
            float:left;
        }
        .location-buttons {
            float: right;
        }
    }
}
@include mqmin( $laptop ) {
    .header-wrap{
        header{
            padding:em(30,$fs) 0 0;
        }
        &.shrink{
            .header.content{
                padding-top:em(15,$fs);
                .block-search{
                    top:em(25,$fs);
                }
                .logo{
                    width:190px;
                }
                .header.links{
                    top:em(25,$fs);
                }
            }
            .nav-sections{
                nav{
                    margin:em(20,$fs) auto 0;
                }
            }
        }
    }
    .callout-image{
        width:percentage(1057/$hwidth_num);
        height:568px;
        top:em(-53,$fs);
    }
    .callout-inside{
        min-height:568px;
    }
    .callout-row{
        &.break-out{
            margin-bottom:em(-53,$fs);
            &.cols-1{
                .callout{
                    padding-top:0;
                    padding-bottom:0;
                }
            }
        }
        &.cols-1{
            .callout{
                .callout-box{
                    &.top{
                        top:percentage(170/795);
                    }
                    &.bottom{
                        bottom:percentage(170/795);
                    }
                }
            }
        }
    }
    .products-grid.products-grid.products-grid{
        .product-item{
            clear:none;
            margin:0;
            width:percentage(285/$inside_width_num);
            &:nth-child(4n+1){
                clear:both;
            }
            &:not(:nth-child(4n+1)){
                margin-left:percentage(35/$inside_width_num);
            }
            &:nth-child(n+5){
                margin-top:percentage(35/$inside_width_num);
            }
        }
        .page-layout-home &{
            .product-item{
                clear:none;
                margin:0;
                /*width:percentage(280/$width_num);*/ // 20%
                width:percentage(280/$inside_width_num); // 22%
                &:nth-child(4n+1){
                    clear:both;
                }
                &:not(:nth-child(4n+1)){
                    /*margin-left:percentage(80/$width_num);*/
                    margin-left:percentage(35/$inside_width_num);
                }
                &:nth-child(n+5){
                    /*margin-top:percentage(80/$width_num);*/
                    margin-top:percentage(35/$inside_width_num);
                }
            }
        }
        &.wishlist{
            .product-item{
                clear:none;
                margin:0;
                width:percentage(406.66/$width_num);
                &:nth-child(3n+1){
                    clear:both;
                }
                &:not(:nth-child(3n+1)){
                    margin-left:percentage(80/$width_num);
                }
                &:nth-child(n+4){
                    margin-top:percentage(80/$width_num);
                }
            }
        }
        &.subcategories{
            .product-item{
                clear:none;
                margin:0;
                width:percentage(400/$inside_width_num);
                &:nth-child(3n+1){
                    clear:both;
                }
                &:not(:nth-child(3n+1)){
                    margin-left:percentage(22.5/$inside_width_num);
                }
                &:nth-child(n+4){
                    margin-top:percentage(22.5/$inside_width_num);
                }
            }
        }
    }
    .home-feature-block{
        height:521px;
        > div{
            height:100%;
            width:50%;
            float:left;
        }
        .left{
            height:100%;
        }
        .callout-box{
            position: absolute;
            &.top{
                top:percentage(70 / $hwidth_num);
            }
            &.bottom{
                bottom:percentage(70 / $hwidth_num);
            }
            &.right{
                right:percentage(70 / $hwidth_num);
            }
            &.left{
                left:percentage(70 / $hwidth_num);
            }
            &.center{
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
                &:hover{
                    transform:translateX(-50%) translateY(calc(-50% - 10px));
                }
            }
        }
    }
    .home-triple-box-block{
        .triple-box{
            width:percentage(1069 / $hwidth_num);
            padding-bottom:percentage(578 / $hwidth_num);
        }
        .triple-box-content{
            width:percentage(442 / $hwidth_num);
            position: absolute;
            bottom:percentage(75 / 578);
            right:percentage(80 / $hwidth_num);
            a{
                margin:em(170,24) 0 0;
            }
        }
    }
    .home-instagram-block.home-instagram-block{
        padding:em(34,$fs) em(42,$fs) em(42,$fs);
        ul{
            li{
                display:block;
                clear:none;
                margin:0;
                width:percentage(245/1296);
                &:nth-child(5n+1){
                    clear:both;
                }
                &:not(:nth-child(5n+1)){
                    margin-left:percentage(17.75/1296);
                }
                &:nth-child(n+6){
                    margin-top:percentage(17.75/1296);
                }
            }
        }
    }
    .footer-wrap{
        footer{
            padding:em(42,$fs) em(67.5,$fs);
        }
    }
    .footer-links-block{
        width:percentage(580/1245);
        float:left;
    }
    .footer-follow-block{
        margin:0;
        width:percentage(621/1245);
        float:right;
    }
    .social-hashtags.social-hashtags{
        margin:0;
    }
    .footer-contact-block{
        .tollfree{
            float:right;
        }
        h6{
            + span{
                margin-right:em(30,14);
            }
        }
        span{
            display:inline-block;
            + span{
                margin-left:em(30,14);
            }
        }
    }
    .page-main{
        .sidebar{
            width:23%;
            margin:0;
            + .sidebar{
                margin:em(30,$fs) 0 0;
            }
        }
    }
    .page-layout-2columns-left,
    .page-layout-2columns-right{
        .column.main{
            width:73%;
        }
    }
    .page-layout-2columns-left{
        .column.main{
            float:right;
        }
        .sidebar{
            float:left;
        }
        .sidebar-additional{
            float:left;
            clear:left;
        }
    }
    .page-layout-2columns-right{
        .column.main{
            float:left;
        }
        .sidebar{
            float:right;
        }
        .sidebar-additional{
            float:right;
            clear:right;
        }
    }
    .blog-hero-nav{
        > ul{
            float:right;
            clear:none;
            width:calc(100% - 395px);
            text-align:right;
            margin-top:em(35,$fs);
        }
    }
    .blog-posts{
        .blog-post-header{
            > *:not(.blog-post-info){
                float:left;
                clear:left;
                width:percentage(700/$inside_width_num);
            }
        }
        ul.blog-post-info{
            text-align:right;
            float:right;
            width:percentage(530/$inside_width_num);
        }
    }
}
@include mqmin( $desktop ) {
    .product-attributes-footer{
        margin:em(150,$fs) 0 0;
    }
    .product-attributes-options{
        float:left;
        margin: 20px 0 0;
        &.blade-options {
            margin:20px 0 0 0;
            clear: both;
        }
    }
    .showroom-info{
        float:left;
        width:100%; //percentage(600/$inside_width_num);
    }
    .showroom-map{
        float:right;
        width:100%; //percentage(600/$inside_width_num);
        margin:em(-30,$fs) 0 0;
    }
}
@include mqmin( $large ) {
    .fotorama__nav-wrap{
        top:-1px !important;
        left:0 !important;
        transform:translateX(calc(-100% - 9px));
    }
    .page-layout-flexible-inside-page-hero-alt.page-layout-flexible-inside-page-hero-alt{
        .page-mini-hero-block{
            width:percentage(670/$inside_width_num);
            $minus_half: (($width_num - $inside_width_num) / 2) * -1;
            margin-right:#{$minus_half}px;
        }
    }
}
