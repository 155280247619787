// Hamburger?
.nav-sections-item-title{
    display:none;
    text-indent:-99999px;
    overflow:hidden;
    width:28px;
    height:28px;
    cursor:pointer;
    position: absolute;
    top:em(15,$fs);
    right:em(15,$fs);

    &:before{
        width:100%;
        height:100%;
        overflow:hidden;
        line-height:28px;
        opacity:1;
        content:"\e809";
        font-family:$icon;
        position: absolute;
        top:50%;
        left:50%;
        display:block;
        text-indent:0;
        font-size:40px;
        transform:translateX(-50%) translateY(-50%);
        will-change:opacity;
        backface-visibility: hidden;
        transition:all $ms ease-in-out;
    }

    &:after{
        width:0;
        height:100%;
        overflow:hidden;
        line-height:28px;
        text-align:center;
        opacity:0;
        content:"\e80a";
        font-family:$icon;
        position: absolute;
        top:52%;
        left:50%;
        display:block;
        text-indent:0;
        font-weight:bold;
        font-size:23px;
        transform:translateX(-50%) translateY(-50%);
        will-change:opacity;
        backface-visibility: hidden;
        transition:all $ms ease-in-out;
    }
}

.nav-sections-item-content{
    position: relative;
    transition:height $ms ease-in-out;
}

.nav-sections{

    nav{
        max-width:$width;
        margin:em(25,$fs) auto 0;
        transition:margin $ms ease-in-out;

        ul{
            list-style:none;
            margin:0;
            padding:0;
            text-align:center;
            line-height:1em;

            li.level-top{
                display:inline-block;

                &:not(:first-child){
                    margin-left:em(40,$fs);
                }

                > a{
                    font-size:em(18,$fs);
                    letter-spacing:em(.75,18);
                    display:block;
                    font-weight:normal;
                    color:$black;
                    padding:0 em(10,18) em(15,18);
                    border-bottom:2px rgba($white,0) solid;
                    transition:all $ms ease-in-out;
                }

                &:hover{
                    > a{
                        border-bottom-color:$black;
                    }
                }
            }
        }
    }
}

.top-container{
    padding:0 em(15,$fs);

    > *{
        max-width:$hwidth;
        margin:0 auto;
    }
}

.sneak-peak-block{
    background:$grey;
    text-align:center;
    font-size:em(16,$fs);

    a{
        display:inline-block;
        color:$black;
        padding:em(9.5,16) em(9.5,16) em(8.5,16);
        border-bottom:1px $grey solid;
        transition:all $ms ease-in-out;

        &:hover{
            border-bottom-color:$greydark;
        }
    }
}

.nav-sections nav ul{
    .submenu{
        @include afterclear;
        text-align:left;

        &.level0{
            padding:em(10,$fs) 0;
            width:250px;
            max-width:90vw;
            position: absolute;
            bottom:-1px;
            left:-99999px;
            transform:translate3d(-50%,103%,0);
            z-index:-999;
            opacity:0;
            transition:opacity $ms ease-in-out, transform $ms ease-in-out, left 1ms ease-in-out $ms;
            -webkit-backface-visibility: hidden;
            will-change:transform;
            -webkit-box-shadow: 0 5px 5px 0 rgba(0,0,0,.05);
            box-shadow: 0 5px 5px 0 rgba(0,0,0,.05);

            &:before{
                content:"";
                width:100%;
                height:100%;
                position: absolute;
                top:0;
                left:50%;
                transform:translateX(-50%);
                background:$white;
                display:block;
                z-index:-1;
            }

            > .level1{
                display:inline-block;
                width:100%;
            }
        }

        .column{
            float:left;
            width:percentage(247/$inside_width_num);

            + .column{
                margin-left:percentage(2.5/$inside_width_num);
            }
        }

        .submenu{
            font-size:1em;
            text-align:left;
            padding-left:em(15,$fs);

            a{
                font-weight:normal;
            }

            &.level2{
                display:none;
            }
        }

        img{
            display:block;

            + span{
                display:block;
                font-weight:normal;
                margin-top:em(3,14);
            }
        }

        a{
            width:100%;
            color:$black;
            font-size:em(14,$fs);
            padding:em(8,14) em(30,14);
            display:block;
            text-align:left;

            &:hover{
                color:$greydark;
            }
        }
    }

    .nav-1{
        .submenu{
            .level1{
                > a{
                    text-transform: uppercase;
                }
            }
        }
    }

    .nav-1,
    .nav-2{
        .submenu{
            padding:0;

            .submenu{
                //padding-left:em(15,$fs);
            }

            &.level0{
                width:100%;
                max-width:100%;
                padding:em(12,$fs) 0;
                max-width:$inside_width;

                &:before{
                    width:100vw;
                }
            }

            a{
                padding:em(8,14) 0;
            }

            .btn-style-view-all{
                position: relative;
                padding:0 0 percentage(100/234);
                display:block;
                margin-top:em(8,14);
                border:2px $gray solid;
                transition:all $ms ease-in-out;

                span{
                    position: absolute;
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                    font-weight:normal;
                    font-size:em(24,14);
                    font-family:$font_script;
                    font-style:italic;
                    color:$black;
                }

                &:hover{
                    border-color:$greydark;

                    span{
                        color:$black;
                    }
                }
            }
        }
    }

    .nav-1{
        .submenu{
            img + span{
                display:none;
            }
        }
    }

    .nav-2{
        .column{
            width:percentage(234/$inside_width_num);

            + .column{
                margin-left:percentage(18.75/$inside_width_num);
            }
        }
    }

    .level0{
        position: relative;

        &.nav-1,
        &.nav-2{
            position: static;
        }
    }

    .level1{
        &.nav-1-3{
            margin:em(32,$fs) 0 0;
            position: relative;

            &:before{
                content:"--";
                line-height:1px;
                position: absolute;
                top:em(-16,$fs);
                left:0;
                font-weight:bold;
                transform:translateY(-50%);
            }
        }
    }
}

.nav-sections{
    nav{
        ul{
            li.level-top{
                &:hover{
                    .submenu.level0{
                        left:50%;
                        z-index:999;
                        transition:opacity $ms ease-in-out, transform $ms ease-in-out, left 1ms ease-in-out;
                        opacity:1;
                        transform:translate3d(-50%,100%,0);
                    }
                }
            }
        }
    }
}

@include mqmax( $laptop - 1 ) {
    body{
        padding-top:em(59,$fs);
    }
    .header-wrap{
        padding-left:0;
        padding-right:0;

        header{
            padding-top:0;
            padding-left:em(15,$fs);
            padding-right:em(15,$fs);
        }
    }
    .top-container{
        padding:0;

    }
    .sneak-peak-block{
        padding:0 em(15,$fs);
    }
    .page-header{
        .header.content{
            padding:em(15,$fs) 0;

            .block-search{
                font-size:em(20,14);
                top:em(13,20);
                left:em(15,20);
                width:75%;

                .field.search{
                    height:38.3px;
                    line-height:38.3px;

                    label{
                        pointer-events:auto;
                    }

                    #search{
                        height:38.3px;
                        max-width:100%;
                        line-height:38.3px;
                        padding:0;
                        opacity:0;
                        position: absolute;
                        top:-9999px;
                        left:-9999px;
                        transition:opacity $ms ease-in-out;
                        pointer-events:auto;

                        &:focus{
                            opacity:1;
                            position: relative;
                            top:auto;
                            left:auto;
                            z-index: 99;
                        }
                    }
                }
            }
            .logo{
                float:none;
                width:160px;
                margin:0 auto;
                position: relative;
                z-index: 98;
            }
            .header.links{
                display:none;
            }
        }
    }
    .nav-sections{
        //Hamburger
        .nav-sections-item-title:first-child{
            display:block;
        }
        nav{
            margin:em(25,$fs) auto;

            ul{
                li.level-top{
                    display:block;

                    &:not(:first-child){
                        margin-left:0;
                    }

                    > a{
                        padding:em(15,18);
                    }

                    &:hover{
                        > a{
                            border-bottom-color:rgba($white,0);
                        }
                    }
                }
                .nav-1,
                .nav-2{
                    .submenu{
                        .btn-style-view-all{
                            padding:0;
                            margin:0;
                            border:none;

                            span{
                                position: relative;
                                top:auto;
                                left:auto;
                                transform:none;
                                font-size:1em;
                                font-family:$font_body;
                                font-style:normal;
                                color:$white;
                            }

                            &:hover{
                                span{
                                    color:$white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #store\.menu{
        height:0;
        overflow:hidden;
        position: absolute;
        top:100%;
        left:50%;
        background:$black;
        color:$white;
        width:100.1vw;
        transform:translateX(-50%);

        > nav{
            > ul{
                text-align:left;
                padding:0 em(25,14);
                height:calc(100vh - 68.3px - 1.5625em - 1.5625em);
                overflow:scroll;

                li.level-top{
                    &:hover{
                        .submenu.level0{
                            left:auto;
                            z-index:1;
                            transition:none;
                            transform:none;
                        }
                    }
                }
            }
        }

        .level0{
            > a{
                border-bottom:1px rgba(255,255,255,.15) solid;
            }
        }

        .submenu.level0{
            position: relative;
            bottom:auto;
            left:auto;
            transform:none;
            opacity:1;
            z-index:1;
            padding:0;
            width:100%;
            max-width:100%;
            max-height:0;
            overflow:hidden;
            padding-left:em(15,14);
            border-bottom:1px rgba(255,255,255,.15) solid;
            transition:max-height $ms ease-in-out !important;

            &.open{
                max-height:3000px;
            }

            &:before{
                display:none;
            }

            .column{
                float:none;
                width:100%;
                margin:0;
            }

            a{
                font-size:em(16,14);
                padding:em(15,16) em(10,16);
            }

            img{
                display:none;

                + span{
                    display:block;
                }
            }
        }

        a{
            color:$white;
            font-size:em(18,14);
            padding:em(15,18) em(10,18);
        }
    }
    .nav-open.nav-open{
        .nav-sections-item-title{
            &:before{
                opacity:0;
                width:0;
            }
            &:after{
                opacity:1;
                width:100%;
            }
        }
        #store\.menu{
            height:calc(100vh - 68.3px);
        }
    }
    .eyebrow-links{
        ul{
            &:first-child{
                display:none;
            }
            &:last-child{
                display:none;
            }
        }
    }
}

@include mqmax( $tablet - 1 ) {
    .nav-open.nav-open{
        #store\.menu{
            height:calc(100vh - 64.5px);
        }
    }
    #store\.menu{
        > nav{
            > ul{
                height:calc(100vh - 64.5px - 1.5625em - 1.5625em);
                padding:0 em(15,14);
            }
        }
    }
}
