// 295 x 369 family grid
// 240 x 300 product grid
// 511 x 640 product detail page
// 1022 x 1280 product detail page zoom

.products-grid{
    a{
        color:$black;
        transition:all $ms ease-in-out;

        &:hover{
            color:$greydark;
        }
    }
    .product-items{
        @include afterclear;
        list-style:none;
        margin:0;
        padding:0;
    }
    .product-item{
        // Making these inline-block breaks all responsive views.  They need to stay float.  Please don't change this without talking to Cory.
        float: none;
        border: 1px $grey solid;
        position: relative;
        clear: both;

        + .product-item{
            margin-top:percentage(80/$width_num);
        }
    }
    .product-item-info{
        /*border: 1px dotted #aaa;*/

        > strong:first-child{
            display:none;
        }
    }
    .product-item-photo{
        display: inline-table;
        width: 100%;
        min-height: 220px;
        vertical-align: middle;
        padding:em(15,$fs) 0;
        white-space: nowrap;
        text-align: center;

        .img-helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }

        .product-image-container {
            display: inline-block;
            width: 100%;
            height: auto;
            vertical-align: middle;

            .product-image-wrapper {
                vertical-align: middle;

                img{
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    display: block;
                    margin: 0 auto;
                    vertical-align: middle;
                }
            }
        }
    }
    .product-item-details{
        padding:0 em(20,$fs) em(20,$fs);
    }
    .product-item-link{
        color:$black;
        font-weight:600;
    }
    .product-list-item-details{
        @include afterclear;
        list-style:none;
        margin:0;
        padding:0;
        font-size:em(14,$fs);
        line-height:1em;

        li{
            float:left;

            + li{
                margin:0 0 0 em(10,14);
                padding:0 0 0 em(10,14);
                border-left:1px $greydark solid;
            }
        }
    }
    .product-list-item-badges{
        @include afterclear;
        position: absolute;
        top:em(20,$fs);
        left:em(20,$fs);
        z-index:10;
    }
    .action.towishlist{
        position: absolute;
        top:em(20,$fs);
        right:em(20,$fs);
        font-size:1em;
        z-index:10;
        text-indent:-99999px;
        width:25px;
        height:25px;
        font-weight:normal;
        padding:0;

        &:before,
        &:after{
            content:"\e802";
            font-family:$icon;
            font-size:24px;
            line-height:24px;
            display:block;
            position: absolute;
            top:0;
            left:0;
            text-indent:0;
            transform:none;
        }

        &:after{
            content:"\e803";
            opacity:0;
            transition:all $ms ease-in-out;
        }

        &:hover{
            color:$black;

            &:after{
                opacity:1;
            }
        }
    }
    .product-list-item-footer{
        @include afterclear;
        font-size:em(14,$fs);
        margin:em(20,14) 0 0;

        > a{
            font-weight:600;
            float:left;
        }

        .product-list-item-finishes{
            float:right;
        }
    }
}

.product-list-item-finishes{
    @include afterclear;
    list-style:none;
    margin:0;
    padding:0;

    li{
        display: inline-block;
        width:rem(24,$fs);
        height:rem(24,$fs);
        position: relative;
        vertical-align: top;

        + li{
            margin:0 0 0 em(8,14);
        }
    }

    a{
        width:100%;
        height:100%;
        border-radius:50%;
        text-indent:-99999px;
        background:$greydark;
        display:block;
        position: absolute;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);

        &:before{
            content:"";
            width:calc(100% + 6px);
            height:calc(100% + 6px);
            background:transparent;
            border:1px $greydark solid;
            border-radius:50%;
            display:block;
            position: absolute;
            top:50%;
            left:50%;
            transform:translateX(-50%) translateY(-50%);
            opacity:0;
            transition:all $ms ease-in-out;
        }

        &:hover{
            color:$black;

            &:before{
                opacity:1;
            }
        }

        &.selected{
            width:calc(100% - 8px);
            height:calc(100% - 8px);

            &:before{
                opacity:1;
            }
        }

        &.black{
            background-image:url('../images/product-finish-black.jpg');
        }

        &.brown{
            background-image:url('../images/product-finish-brown.jpg');
        }

        &.more{
            text-indent:0;
            background:$white;
            border:1px $greydark solid;
            text-align:center;
            line-height:rem(21,$fs);
            font-weight:normal;
        }
    }
}

.product-list-item-badges{
    @include afterclear;
    list-style:none;
    margin:0;
    padding:0;

    li{
        float:left;
        width:22px;
        height:22px;
        position: relative;

        + li{
            margin:0 0 0 em(5,$fs);
        }
    }

    a{
        width:100%;
        height:100%;
        border-radius:50%;
        text-indent:-99999px;
        background:$greydark;
        display:block;
        position: absolute;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);

        &.t24{
            background-image:url('../images/product-badge-t24.png');
        }

        &.dots{
            background-image:url('../images/product-badge-dots.png');
        }
    }
}

.block-title.featured-products {
    margin:em(35,$fs) 0 em(5,$fs);
    font-family:$font_body;
    text-align:center;
    text-transform:uppercase;
    font-size:em(24,$fs);
    letter-spacing:em(.80,24);

    .heart{
        font-family:$icon;
        font-size:em(20,24);
        position: relative;
        top:em(-2,20);
        display:inline-block;
        margin:0 em(2,20);
    }

    .favorites {
        white-space: nowrap;
    }

    *{
        font-weight:normal;
    }
}

// Hide pagination only from Collections
.page-layout-collections {
    .products-grid {
        .toolbar.bottom {
            display: none;
        }
    }
}