// GLOBALS
$container:1140px;
$containerwide:1400px;
$body_padding:68px;
$body_padding_desktop:72px;

// Grid
$hwidth_num: 1680;
$hwidth: #{$hwidth_num}px;
$width_num: 1380;
$width: #{$width_num}px;
$inside_width_num: 1245;
$inside_width: #{$inside_width_num}px;
$skinny_num: 838;
$skinny: #{$skinny_num}px;

// Colors
$black: #000000;
$white: #FFFFFF;
$gray: #eeeeee;
$grey: $gray;
$greydarkest: #808080;
$greydark: #aaabac;
$greymedium: #f2f1f0;
$greylight: #fcfcfc;
$primary: $black;
$secondary: $greydark;
$red: #660000;

// Fonts
$fs: 16;
$font_body: 'HL-Raleway', sans-serif;
$font_heading:  'Chalet', sans-serif;
$font_script:  'Chronicle Disp Cond', sans-serif;
$icon: 'hinkley';

// Animation
$ms: 500ms;

// Media Queries
$phone: 480px;
$tablet: 768px;
$laptop: 980px;
$desktop: 1366px;
$large: 1400px;

// Social Colors
$facebook: #3b5998;
$twitter: #00b6f1;
$youtube: #ff0000;
$instagrammagenta: #c32aa3;
$instagramblue: #4c5fd7;
$instagrampurple: #7232bd;
$instagramorange: #f46f30;
$instagramyellow: #ffdc7d;
$pinterest: #cb2027;
$googleplus: #df4a32;
$linkedin: #007bb6;
$vimeoblue: #45bbff;
$vimeogreen: #aad450;
$vimeoorange: #f7b42c;
$vimeopink: #f27490;
$tumblr: #2c4762;
$snapchat: #fffc00;
$whatsappgreen: #25d366;
$whatsappteal1: #075e54;
$whatsappteal2: #128c7e;
$foursquarepink: #fc4575;
$foursquareblue: #2d5be3;
$swarm: #ffb000;
$skypeblue: #00aff0;
$skypegreen: #7fba00;
$android: #a4c639;
$stumbleupon: #eb4924;
$flickrpink: #f40083;
$flickrblue: #006add;
$yahoo: #430297;
$soundcloud: #ff5500;
$spotifygreen: #1ed760;
$spotifydarkgreen: #1db954;
$dribbble: #ea4c89;
$reddit: #ff5700;
$deviantart: #4a5d4e;
$pocket: #ee4056;
$quora: #aa2200;
$quorablue: #2b6dad;
$slideshareorange: #f7941e;
$slideshareblue: #1191d0;
$fivehundredpx: #0099e5;
$listlyorange: #df6d46;
$listlyblue: #52b1b3;
$vine: #00b489;